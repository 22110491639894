import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import classes from "../Agreement/Agreement.module.css";
import InvoiceContainer from "../../CommonComponent/InvoiceContainer/InvoiceContainer";
import Select from "react-select";
import { InvoiceStyles } from "../../utils/style";
import { FaTrash } from "react-icons/fa";
import InvoiceButton from "../../CommonComponent/Button/InvoiceButton";
import dropdownIcon from "../../../../assets/downIcon.png";
import DatePicker from "react-datepicker";
import PurpleIcon from "../../../../assets/Calander.png";
import cancel from "../../../../assets/cancell.png";
import EditIcon from "../../../../assets/Icon material-edit_.png";
import pdf from "../../../../assets/docx-icon.svg"

import { useState } from "react";
import moment from "moment";
import CustomTooltip from "../../../PartnerManagement/customTooltip/CustomTooltip.js";

import { useMutation, useQuery } from "react-query";

import {
  getViewPartner,
  addPartnerAgreement,
  ResourceType,
  AgreementIDByData,
  AgreementIDByHistory,
  ViewAgreementID,
  EditPartnerAgreement,
  getPartnerResource,
  uploadFile,
  deleteFile,
} from "../../../../Utils/axios/PortalManagement";
import BreadCrum from "../../../PartnerManagement/breadcrums/BreadCrum";
import { Toastify, ToastifyError } from "../../../../App.js";
import { CgNotes } from "react-icons/cg";

const Milestones = ({
  setNextScreen,
  prevData,
  setPrevData,
  currentPage,
  setCurrentPage,
}) => {
  const [formData, setFormData] = useState({
    agreementDetails: [],
  });

  const [lineItemArray, setLineItemArray] = useState([1]);
  const [AggrementData, setAggrementData] = useState(false);
  const [EditAgreement, setEditAgreement] = useState(true);
  const [EditId, setEditId] = useState();
  const [HideData, setHideData] = useState(false);
  const [agreementId, setAgreementId] = useState(null);
  const [validate, setValidate] = useState(false);
  const [errors, setErrors] = useState(Array(formData?.agreementDetails?.length).fill({ resource: "", rateTypeId: "" }));

  const { data: PartnerDataById } = useQuery(
    ["getViewPartner"],
    () => getViewPartner && getViewPartner(prevData?.partnerId),
    {}
  );

  const {
    data: AgreementList,
    isLoading,
    isError,
    refetch,
  } = useQuery(
    ["AgreementIDByData"],
    () => AgreementIDByData && AgreementIDByData(prevData?.partnerId),
    {
      refetchOnWindowFocus: false,
    }
  );

  // useEffect(() => {
  //   if (AgreementList?.length > 0 && AgreementList[0]?.agreementId) {
  //     setAgreementId(AgreementList[0]?.agreementId);
  //   }
  // }, [AgreementList]);

  const { data: ViewAgreementIDData } = useQuery(
    ["ViewAgreementID", agreementId],
    () => agreementId && ViewAgreementID(agreementId),
    {}
  );

  const [formData1, setFormData1] = useState({
    resource: ViewAgreementIDData?.resourseId,
    startDate: ViewAgreementIDData?.startDate,
    endDate: ViewAgreementIDData?.endDate,
    rate: ViewAgreementIDData?.rate,
    partnerId: prevData?.partnerId,
    rateTypeId: ViewAgreementIDData?.resourceTypeId,
    agreementId: EditId,
    fileUrl: ViewAgreementIDData?.fileUrl,
  });

  useEffect(() => {
    setFormData1((prevValue) => ({
      ...prevValue,
      resource: ViewAgreementIDData?.resourseId,
      startDate: ViewAgreementIDData?.startDate,
      endDate: ViewAgreementIDData?.endDate,
      rate: ViewAgreementIDData?.rate,
      partnerId: prevData?.partnerId,
      rateTypeId: ViewAgreementIDData?.resourceTypeId,
      agreementId: EditId,
      fileUrl: ViewAgreementIDData?.fileUrl,
    }));
  }, [ViewAgreementIDData]);

  const {
    data: AgreementDataTech,
    isLoading1,
    isError1,
  } = useQuery(
    ["AgreementIDByHistory", { agreementId }],
    () => agreementId && AgreementIDByHistory(agreementId)
  );

  const mutation = useMutation(addPartnerAgreement, {
    onSuccess: (res) => {
      Toastify(res);
      refetch();
      setFormData((prevValue) => ({
        agreementDetails: [],
      }));
    },
  });
  const mutation1 = useMutation(EditPartnerAgreement, {
    onSuccess: (res) => {
      refetch();
      Toastify(res);
      setFormData1((prevValue) => ({
        ...prevValue,
        resource: "",
        startDate: "",
        endDate: "",
        rate: "",
        partnerId: "",
        rateTypeId: "",
        agreementId: "",
        fileUrl: "",
      }));
    },
  });

  const { data: getAggrementData } = useQuery(["ResourceType"], ResourceType);

  const getAggrementDataoption = getAggrementData?.map((e) => ({
    value: e.id,
    label: e.name,
  }));

  const { data: getResourceData } = useQuery(["ResourceById"], () =>
    getPartnerResource(prevData?.partnerId)
  );

  const getResourseDataoption = getResourceData?.map((e) => ({
    value: e?.id,
    label: e?.name,
  }));

  const CustomInput = () => {
    return (
      <span>
        <img
          src={dropdownIcon}
          style={{ position: "absolute", top: "32%", right: "3%" }}
          height="10"
          alt=""
        />
      </span>
    );
  };

  const CustomInputClg = React.forwardRef((props, ref) => {
    return (
      <div onClick={props.onClick}>
        <label
          ref={ref}
          style={{
            cursor: "pointer",
          }}
        >
          {props.value || props.placeholder}
        </label>
        <img
          src={PurpleIcon}
          style={{
            position: "absolute",
            right: "5%",
            top: "13%",
            cursor: "pointer",
          }}
          alt=""
        />
      </div>
    );
  });

  const CustomInputCross = React.forwardRef((props, ref) => {
    const clearData = () => {
      setFormData((prevFormData) => {
        const updatedAgreementDetails = prevFormData.agreementDetails.map(
          (detail, index) => {
            return {
              ...detail,
              [props?.properties]: "", // Clear the specific property value
            };
          }
        );

        return {
          ...prevFormData,
          agreementDetails: updatedAgreementDetails,
        };
      });
    };

    return (
      <div>
        <label
          ref={ref}
          style={{
            cursor: "pointer",
          }}
        >
          {props.value || props.placeholder}
        </label>
        <img
          src={cancel}
          width="15px"
          height="15px"
          style={{
            position: "absolute",
            right: "5%",
            top: "16%",
            cursor: "pointer",
          }}
          onClick={clearData}
          alt=""
        />
      </div>
    );
  });

  const CustomInputClg1 = React.forwardRef((props, ref) => {
    return (
      <div onClick={props.onClick}>
        <label
          ref={ref}
          style={{
            cursor: "pointer",
          }}
        >
          {props.value || props.placeholder}
        </label>
        <img
          src={PurpleIcon}
          style={{
            position: "absolute",
            right: "5%",
            top: "13%",
            cursor: "pointer",
          }}
          alt=""
        />
      </div>
    );
  });

  const CustomInputCross1 = React.forwardRef((props, ref) => {
    return (
      <div>
        <label
          ref={ref}
          style={{
            cursor: "pointer",
          }}
        >
          {props.value || props.placeholder}
        </label>
        <img
          src={cancel}
          width="15px"
          height="15px"
          style={{
            position: "absolute",
            right: "5%",
            top: "16%",
            cursor: "pointer",
          }}
          onClick={() =>
            setFormData1({ ...formData1, [props?.properties]: "" })
          }
          alt=""
        />
      </div>
    );
  });

  const HandlerAgreement = () => {
    // Map the formdata to store errors
    const newErrors = formData?.agreementDetails?.map(item => ({
      resource: item.resource ? '' : 'Please Enter Resource',
      rateTypeId: item.rateTypeId ? '' : 'Please Enter Resource Type',
    }));
    // set errors in state
    setErrors(newErrors);
    // check if all errors are '' then mutate
    if (newErrors.every(err => !err.resource && !err.rateTypeId)) {
      mutation.mutate(formData);
    }
  };

  const uploadDocMutation = useMutation(uploadFile, {
    onSuccess: (res) => {
      Toastify("Uploaded Successfully");
      setFormData1({ ...formData1, fileUrl: res });
    }
  })

  const handleImage = async (e, index) => {
    try {
      let url = await uploadFile(e.target.files[0]);
      let temp = [...formData?.agreementDetails];
      temp[index].fileUrl = url;
      setFormData({
        ...formData,
        agreementDetails: temp,
      });
      // Toastify("Uploaded Successfully")
    } catch {
      ToastifyError("Soemthing Went Wrong")
    }

    // uploadDocMutation.mutate(e.target.files[0]);
  };
  const handleImage2 = (e) => {
    uploadDocMutation.mutate(e.target.files[0]);
  };

  const EditHandlerAgreement = () => {
    if (formData1?.resource === "" || formData1?.resource === null || formData1?.rateTypeId === null || formData1?.rateTypeId === null) {
      setValidate(true);
    }
    else {
      mutation1.mutate(formData1);
      setValidate(false);
      setEditAgreement(true);
    }

  };

  const deleteFileMutation = useMutation(deleteFile, {
    onSuccess: (res) => {
      // Toastify(res);
    },
    onError: () => {
      ToastifyError("Something Went Wrong");
    }
  })

  const deleteDocument = (url) => {
    deleteFileMutation.mutate(url);
  }

  return (
    <>
      <div style={{ marginLeft: "10px" }}>
        <BreadCrum
          prevData={prevData}
          setPrevData={setPrevData}
          setNextScreen={setNextScreen}
        ></BreadCrum>
      </div>
      <Container>
        <InvoiceContainer>
          <Row>
            <Col>
              <h6 className={classes.primaryContact}>Partner Details</h6>
            </Col>
          </Row>
          <Row>
            <Col md={3}>
              <p className={classes.label}>Partner Name</p>
              <p className={classes.partnerlabal}>
                {PartnerDataById?.partnerName}
              </p>
            </Col>
            <Col md={3}>
              <p className={classes.label}>GSTIN</p>
              <p className={classes.partnerlabal}>{PartnerDataById?.gstin}</p>
            </Col>
            <Col md={3}>
              <p className={classes.label}>Location</p>
              <p className={classes.partnerlabal}>{PartnerDataById?.city}</p>
            </Col>
          </Row>
        </InvoiceContainer>
        {EditAgreement ? (
          <>
            <InvoiceContainer>
              <div className={classes.copy_expence}>
                Add Resource
                <span style={{ marginLeft: "10px", float: "right" }}>
                  <InvoiceButton
                    type={"plus2"}
                    style={{ width: "9%", height: "30px", paddingTop: "2px" }}
                    onClick={() => {
                      setHideData(true);
                      setLineItemArray([
                        ...lineItemArray,
                        Math.floor(Math.random() * 100),
                      ]);
                      setFormData({
                        ...formData,
                        agreementDetails: [
                          ...formData?.agreementDetails,
                          {
                            resource: "",
                            startDate: "",
                            endDate: "",
                            partnerId: prevData?.partnerId,
                          },
                        ],
                      });
                      setErrors((prev) => ([
                        ...prev,
                        { resource: '', rateTypeId: '' }
                      ]))
                    }}
                  >
                    Add
                  </InvoiceButton>
                </span>
              </div>
              {/*row 1*/}

              <Row>
                <div className={classes.Container1}>
                  <div className={classes.col12_item} style={{ marginLeft: "-18px", width: "15%" }}>
                    <p className={classes.label_item_resource}>Resource <span style={{ color: "red" }}>*</span></p>
                  </div>
                  <div
                    className={classes.col2_item}
                    style={{ width: "15%" }}
                  >
                    <p className={classes.label_item_resource_type}>
                      Resource Type <span style={{ color: "red" }}>*</span>
                    </p>
                  </div>
                  <div
                    className={classes.col3_item}
                    style={{ marginTop: "-1px", width: "15%" }}
                  >
                    <p className={classes.label_item5}>Start Date</p>
                  </div>
                  <div className={classes.col4_item} style={{ width: "15%" }}>
                    <p className={classes.label_item6}>End Date</p>
                  </div>
                  <div className={classes.col5_item} style={{ width: "15%" }}>
                    <p className={classes.label_item}></p>
                  </div>
                  <div className={classes.col5_item} style={{ width: "15%" }}>
                    <p className={classes.label_item6}>Upload SOW</p>
                  </div>
                  {/* <div className={classes.col5_item}>
                    <p className={classes.label_item}></p>
                  </div> */}
                </div>
              </Row>
              {HideData
                ? formData?.agreementDetails?.map((item, index) => {
                  return (
                    <>
                      <div className={classes.Container} key={`agreeM${index}`}>
                        <div className={classes.col12_item} style={{ width: "15%" }}>
                          <div style={{ margin: "0 20px 0 -25px" }}>
                            <Select
                              styles={InvoiceStyles}
                              classNamePrefix={"create_position"}
                              components={{
                                DropdownIndicator: () => CustomInput(),
                                IndicatorSeparator: () => null,
                              }}
                              options={getResourseDataoption}
                              value={
                                formData?.agreementDetails[index]?.resource
                                  ? getResourseDataoption?.find(
                                    (e) =>
                                      e?.value ===
                                      formData?.agreementDetails[index]
                                        ?.resource
                                  )
                                  : null
                              }
                              onChange={(e) => {
                                let temp = [...formData?.agreementDetails];
                                temp[index].resource = e?.value;
                                setFormData({
                                  ...formData,
                                  agreementDetails: temp,
                                });
                                let temp2 = [...errors];
                                temp2[index].resource = '';
                                setErrors(temp2);
                              }}
                            />
                            <p
                              style={{ paddingLeft: "15px" }}
                              className={classes.errorText}
                            >{errors[index]?.resource}</p>
                          </div>
                        </div>
                        <div
                          className={classes.col2_item}
                          style={{ marginLeft: "-25px", width: "15%" }}
                        >
                          <Select
                            styles={InvoiceStyles}
                            options={getAggrementDataoption}
                            classNamePrefix={"create_position"}
                            components={{
                              DropdownIndicator: () => CustomInput(),
                              IndicatorSeparator: () => null,
                            }}
                            value={
                              formData?.agreementDetails[index]?.rateTypeId
                                ? getAggrementDataoption?.find(
                                  (e) =>
                                    e?.value ===
                                    formData?.agreementDetails[index]
                                      ?.rateTypeId
                                )
                                : null
                            }
                            onChange={(e) => {
                              let temp = [...formData?.agreementDetails];
                              temp[index].rateTypeId = e?.value;
                              setFormData({
                                ...formData,
                                agreementDetails: temp,
                              });
                              let temp2 = [...errors];
                              temp2[index].rateTypeId = '';
                              setErrors(temp2);
                            }}
                          />{console.log(errors)}
                          <p
                            style={{ paddingLeft: "15px" }}
                            className={classes.errorText}
                          >{errors[index]?.rateTypeId}</p>
                        </div>
                        <div className={classes.col3_item} style={{ width: "15%" }}>
                          <div className={classes.cu_field23Last1}>
                            <DatePicker
                              placeholder=" DD MM YYYY"
                              peekNextMonth
                              showMonthDropdown
                              showYearDropdown
                              dropdownMode="select"
                              style={{ fontSize: "12px" }}
                              selected={
                                formData?.agreementDetails[index]?.startDate
                                  ? moment(
                                    formData?.agreementDetails[index]
                                      ?.startDate
                                  ).toDate()
                                  : null
                              }
                              onChange={(newDate) => {
                                if (moment(newDate).isAfter(formData?.agreementDetails[index]?.endDate)) {
                                  setFormData((prevFormData) => {
                                    const updatedAgreementDetails =
                                      prevFormData.agreementDetails.map(
                                        (detail, i) => {
                                          if (i === index) {
                                            return {
                                              ...detail,
                                              startDate: null,
                                              endDate: null
                                            };
                                          }
                                          return detail;
                                        }
                                      );

                                    return {
                                      ...prevFormData,
                                      agreementDetails: updatedAgreementDetails,
                                    };
                                  });
                                } else {
                                  setFormData((prevFormData) => {
                                    const updatedAgreementDetails =
                                      prevFormData.agreementDetails.map(
                                        (detail, i) => {
                                          if (i === index) {
                                            return {
                                              ...detail,
                                              startDate: moment(newDate).format(
                                                "YYYY-MM-DDTHH:mm:ss.SSS[Z]"
                                              ),
                                            };
                                          }
                                          return detail;
                                        }
                                      );

                                    return {
                                      ...prevFormData,
                                      agreementDetails: updatedAgreementDetails,
                                    };
                                  });
                                }
                              }}
                              dateFormat="dd MMM yyyy"
                              onKeyDown={(e) => {
                                e.preventDefault();
                              }}
                              customInput={
                                formData?.agreementDetails[index]
                                  ?.startDate ? (
                                  <CustomInputCross properties="startDate" />
                                ) : (
                                  <CustomInputClg />
                                )
                              }
                            />
                          </div>
                        </div>
                        <div className={classes.col4_item} style={{ width: "15%" }}>
                          <div className={classes.cu_field23Last1}>
                            <DatePicker
                              placeholder=" DD MM YYYY"
                              peekNextMonth
                              showMonthDropdown
                              showYearDropdown
                              dropdownMode="select"
                              selected={
                                formData?.agreementDetails[index]?.endDate
                                  ? moment(
                                    formData?.agreementDetails[index]
                                      ?.endDate
                                  ).toDate()
                                  : null
                              }
                              onChange={(newDate) => {
                                if (moment(newDate).isBefore(formData?.agreementDetails[index]?.startDate)) {
                                  setFormData((prevFormData) => {
                                    const updatedAgreementDetails =
                                      prevFormData.agreementDetails.map(
                                        (detail, i) => {
                                          if (i === index) {
                                            return {
                                              ...detail,
                                              endDate: null,
                                              startDate: null
                                            };
                                          }
                                          return detail;
                                        }
                                      );

                                    return {
                                      ...prevFormData,
                                      agreementDetails: updatedAgreementDetails,
                                    };
                                  });
                                } else {
                                  setFormData((prevFormData) => {
                                    const updatedAgreementDetails =
                                      prevFormData.agreementDetails.map(
                                        (detail, i) => {
                                          if (i === index) {
                                            return {
                                              ...detail,
                                              endDate: moment(newDate).format(
                                                "YYYY-MM-DDTHH:mm:ss.SSS[Z]"
                                              ),
                                            };
                                          }
                                          return detail;
                                        }
                                      );

                                    return {
                                      ...prevFormData,
                                      agreementDetails: updatedAgreementDetails,
                                    };
                                  });
                                }
                              }}
                              dateFormat="dd MMM yyyy"
                              onKeyDown={(e) => {
                                e.preventDefault();
                              }}
                              customInput={
                                formData?.agreementDetails[index]?.endDate ? (
                                  <CustomInputCross properties="endDate" />
                                ) : (
                                  <CustomInputClg />
                                )
                              }
                            />
                          </div>
                        </div>
                        <div className={classes.col5_item} style={{ width: "15%" }}>
                          <input
                            placeholder={
                              formData?.agreementDetails[index]
                                ?.rateTypeId === 1
                                ? "Monthly Rate"
                                : formData?.agreementDetails[index]
                                  ?.rateTypeId === 2
                                  ? "Hourly Rate"
                                  : "Rate"
                            }
                            type="number"
                            className={classes.smallInput}
                            onChange={(e) => {
                              let temp = [...formData?.agreementDetails];
                              temp[index].rate = e.target.value;
                              setFormData({
                                ...formData,
                                agreementDetails: temp,
                              });
                            }}
                            value={formData?.agreementDetails[index]?.rate}
                            min="0"
                            onWheel={(e) => e.target.blur()}
                            onKeyDown={(e) =>
                              (e.keyCode === 38 ||
                                e.keyCode === 40 ||
                                e.key === "e" ||
                                e.key === "-") &&
                              e.preventDefault()
                            }
                            onKeyPress={(e) =>
                              ((e.which !== 8 &&
                                e.which !== 0 &&
                                e.which < 48) ||
                                e.which > 57 ||
                                e.key === "e" ||
                                e.key === "-") &&
                              e.prevent
                            }
                          />
                        </div>
                        <div className={classes.col5_item} style={{ marginTop: "5px", width: "11.25rem" }}>
                          {formData?.agreementDetails[index]?.fileUrl ? (
                            <>
                              <div className={classes.sowupload}>
                                <label for="file-input">
                                  <a href={formData?.agreementDetails[index]?.fileUrl} target="_blank"><img
                                    id="image"
                                    src={pdf}
                                    alt="SOW"
                                    style={{
                                      borderRadius: "50%",
                                      width: "3rem",
                                      height: "3rem",
                                      border: "1px solid black",
                                      cursor: "pointer",
                                    }}
                                  // onClick={() => setShowImageProfile(true)}
                                  /></a>
                                </label>
                                <p
                                  onClick={() => {
                                    let temp = [...formData?.agreementDetails];
                                    deleteDocument(temp[index]?.fileUrl);
                                    temp[index].fileUrl = "";
                                    setFormData({
                                      ...formData,
                                      agreementDetails: temp,
                                    });

                                  }}
                                  className={classes.rmpdfcross}
                                >
                                  x
                                </p>
                              </div>
                            </>
                          ) : (
                            <input
                              id="file-input"
                              className={classes.upload_resume}
                              type="file"
                              style={{ width: "180px" }}
                              onChange={(e) => {
                                handleImage(e, index);
                              }}
                            />
                          )}
                        </div>

                        {formData?.agreementDetails?.length > 1 && (<div className={classes.col8_item} style={{ width: "1%" }}>

                          <>
                            <FaTrash
                              className={classes.remove_icon}
                              size={20}
                              onClick={(e) => {
                                setLineItemArray(
                                  lineItemArray.filter((_, i) => {
                                    return i !== index;
                                  })
                                );
                                setFormData({
                                  ...formData,
                                  agreementDetails:
                                    formData?.agreementDetails?.filter(
                                      (_, i) => i !== index
                                    ),
                                });
                              }}
                            />
                          </>

                        </div>)}

                      </div>
                    </>
                  );
                })
                : ""}
              <button
                className={classes.buttonAdd}
                onClick={HandlerAgreement}
                disabled={formData?.agreementDetails?.length < 1}
              >
                Submit
              </button>
            </InvoiceContainer>
          </>
        ) : (
          <>
            <InvoiceContainer>
              <div className={classes.copy_expence}>Edit Resource</div>
              {/*row 1*/}
              <>
                <div className={classes.Container}>
                  <div className={classes.col12_item} style={{ width: "15%" }}>
                    <p className={classes.label_item_resource1}>Resource <span style={{ color: "red" }}>*</span></p>
                    <div style={{ margin: "0 20px 0 -25px" }}>
                      <Select
                        styles={InvoiceStyles}
                        options={getResourseDataoption}
                        classNamePrefix={"create_position"}
                        components={{
                          DropdownIndicator: () => CustomInput(),
                          IndicatorSeparator: () => null,
                        }}
                        value={getResourseDataoption?.find(
                          (e) => e.value === formData1?.resource
                        )}
                        onChange={(e) => {
                          setFormData1({
                            ...formData1,
                            resource: e?.value,
                          });
                        }}
                      />
                      <p
                        style={{ paddingLeft: "15px" }}
                        className={validate && !formData1?.resource ? classes.errorText : classes.errorTextHidden}
                      >Please Select Resource</p>
                    </div>
                  </div>
                  <div className={classes.col2_item} style={{ width: "15%" }}>
                    <p className={classes.label_item_resource2}>
                      Resource Type <span style={{ color: "red" }}>*</span>
                    </p>

                    <Select
                      styles={InvoiceStyles}
                      options={getAggrementDataoption}
                      classNamePrefix={"create_position"}
                      components={{
                        DropdownIndicator: () => CustomInput(),
                        IndicatorSeparator: () => null,
                      }}
                      value={getAggrementDataoption?.find(
                        (e) => e.value === formData1?.rateTypeId
                      )}
                      onChange={(e) => {
                        setFormData1({
                          ...formData1,
                          rateTypeId: e?.value,
                        });
                      }}
                    />
                    <p
                      style={{ paddingLeft: "15px" }}
                      className={validate && !formData1?.rateTypeId ? classes.errorText : classes.errorTextHidden}
                    >Please Select Resource Type</p>
                  </div>
                  <div className={classes.col3_item} style={{ width: "15%" }}>
                    <p className={classes.label_item_resource3}>Start Date</p>
                    <div className={classes.cu_field23Last1}>
                      <DatePicker
                        placeholder=" DD MM YYYY"
                        peekNextMonth
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        maxDate={moment().toDate()}
                        selected={
                          formData1?.startDate
                            ? moment(formData1?.startDate).toDate()
                            : null
                        }
                        onChange={(date) => {
                          const dateString = date;

                          const dateObject = new Date(dateString);

                          const isoString = dateObject.toISOString();
                          const formattedDate = isoString.replace(
                            "T00:00:00.000Z",
                            "T05:52:34.459Z"
                          );
                          setFormData1({
                            ...formData1,
                            startDate: formattedDate,
                          });
                        }}
                        dateFormat="dd MMM yyyy"
                        onKeyDown={(e) => {
                          e.preventDefault();
                        }}
                        customInput={
                          formData1?.startDate ? (
                            <CustomInputCross1 properties="startDate" />
                          ) : (
                            <CustomInputClg1 />
                          )
                        }
                      />
                    </div>
                  </div>
                  <div className={classes.col4_item} style={{ width: "15%" }}>
                    <p className={classes.label_item_resource4}>End Date</p>
                    <div className={classes.cu_field23Last1}>
                      <DatePicker
                        placeholder=" DD MM YYYY"
                        peekNextMonth
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        // maxDate={moment().toDate()}
                        dateFormat="dd MMM yyyy"
                        onKeyDown={(e) => {
                          e.preventDefault();
                        }}
                        selected={
                          formData1?.endDate
                            ? moment(formData1?.endDate).toDate()
                            : null
                        }
                        onChange={(date) => {
                          const dateString = date;

                          const dateObject = new Date(dateString);

                          const isoString = dateObject.toISOString();
                          const formattedDate = isoString.replace(
                            "T00:00:00.000Z",
                            "T05:52:34.459Z"
                          );
                          setFormData1({
                            ...formData1,
                            endDate: formattedDate,
                          });
                        }}
                        customInput={
                          formData1?.endDate ? (
                            <CustomInputCross1 properties="endDate" />
                          ) : (
                            <CustomInputClg1 />
                          )
                        }
                      />
                    </div>
                  </div>
                  <div className={classes.col5_item} style={{ width: "15%" }}>
                    <p className={classes.label_item9}></p>
                    <input
                      type="number"
                      className={classes.smallInput}
                      onChange={(e) => {
                        setFormData1({
                          ...formData1,
                          rate: e.target.value,
                        });
                      }}
                      value={formData1?.rate}
                      min="0"
                      onWheel={(e) => e.target.blur()}
                      onKeyDown={(e) =>
                        (e.keyCode === 38 ||
                          e.keyCode === 40 ||
                          e.key === "e" ||
                          e.key === "-") &&
                        e.preventDefault()
                      }
                      onKeyPress={(e) =>
                        ((e.which !== 8 && e.which !== 0 && e.which < 48) ||
                          e.which > 57 ||
                          e.key === "e" ||
                          e.key === "-") &&
                        e.prevent
                      }
                    />
                  </div>
                  <div className={classes.col5_item} style={{ width: "15%" }}>

                    <p className={classes.label}>
                      Upload SOW
                    </p>

                    {formData1?.fileUrl ? (
                      <>
                        <div className={classes.sowupload}>
                          <label for="file-input">
                            <a href={formData1?.fileUrl} target="_blank">
                              <img
                                id="image"
                                src={pdf}
                                alt="SOW"
                                style={{
                                  borderRadius: "50%",
                                  width: "3rem",
                                  height: "3rem",
                                  border: "1px solid black",
                                  cursor: "pointer",
                                }}
                              // onClick={() => setShowImageProfile(true)}
                              /></a>
                          </label>
                          <p
                            onClick={() => {
                              deleteDocument(formData1?.fileUrl);
                              setFormData1({
                                ...formData1,
                                fileUrl: "",
                              });
                            }}
                            className={classes.rmpdfcross}
                          >
                            x
                          </p>
                        </div>
                      </>
                    ) : (
                      <input
                        id="file-input"
                        className={classes.upload_resume}
                        style={{ marginTop: "10px", width: "180px" }}
                        type="file"
                        onChange={(e) => {
                          handleImage2(e);
                        }}
                      />
                    )}
                  </div>
                </div>
              </>
            </InvoiceContainer>
            <button
              className={classes.buttonAdd4}
              onClick={() => {
                EditHandlerAgreement();
                setFormData({
                  agreementDetails: [],
                });
              }}
            >
              Submit
            </button>
            <button
              className={classes.buttonCancel}
              onClick={() => { setEditAgreement(true); setValidate(false); }}
            >
              Cancel
            </button>
          </>
        )}

        <Row>
          <Col>
            <h6 style={{ marginLeft: "14px", marginTop: "50px" }}>
              Resource Rate
            </h6>
          </Col>
        </Row>
        <Col md={12}>
          <InvoiceContainer>
            <div className={classes.table}>
              <div className={classes.th1}>
                <p className={classes.tableContent}>Resource</p>
                <p className={classes.tableContent}>Resource Type</p>
                <p className={classes.tableContent}>Start Date</p>
                <p className={classes.tableContent}>End Date</p>
                <p className={classes.tableContent}>Rate</p>
                <p className={classes.tableContent}>SOW</p>
                <p className={classes.tableContent}>Action</p>
              </div>
              <div>
                {isLoading && <p className={classes.no_data}>Loading .....</p>}
                {!isLoading && !isError ? (
                  AgreementList?.length > 0 ? (
                    typeof AgreementList !== "string" &&
                    AgreementList?.map((item, index) => {
                      return (
                        <div
                          className={classes.td1}
                          key={`agreement${item?.agreementId}`}
                          style={{ position: "relative" }}
                        >
                          <p>
                            <span
                              style={{
                                cursor: "pointer",
                                fontWeight: "700",
                                fontSize: "0.8rem",
                                textDecoration: "underline",
                              }}
                              onClick={() => setAgreementId(item?.agreementId)}
                            >
                              {item?.resource}
                            </span>
                          </p>

                          <p className={classes.tableContent}>
                            {item?.resourceType}
                          </p>

                          <p className={classes.tableContent}>
                            {item?.startDate
                              ? moment(item?.startDate).format("DD MMM YY")
                              : ""}
                          </p>

                          <p className={classes.tableContent}>
                            {item?.endDate
                              ? moment(item?.endDate).format("DD MMM YY")
                              : ""}
                          </p>

                          <p className={classes.tableContent}>{item?.rate}</p>
                          <p className={classes.tableContent}>{item?.fileUrl && <a href={item?.fileUrl} target="_blank"><CgNotes /></a>}</p>
                          <p
                            className={classes.tableContent}
                            onClick={() => {
                              setAgreementId(item?.agreementId);
                              setEditId(item?.agreementId);
                              setEditAgreement(false);
                            }}
                          >
                            <img
                              src={EditIcon}
                              alt=""
                              style={{ cursor: "pointer" }}
                            />
                          </p>
                        </div>
                      );
                    })
                  ) : (
                    <p className={classes.no_data}>No Data Found</p>
                  )
                ) : null}
              </div>
            </div>
          </InvoiceContainer>
        </Col>

        <Col md={12} style={{ marginTop: "10px" }}>
          <Row>
            <Col>
              <h6 style={{ marginLeft: "25px" }}>Resource History</h6>
            </Col>
          </Row>

          <InvoiceContainer>
            <div className={classes.table}>
              <div className={classes.th}>
                <p className={classes.tableContent}>Resource</p>
                <p className={classes.tableContent}>Resource Type</p>
                <p className={classes.tableContent}>Start Date</p>
                <p className={classes.tableContent}>End Date</p>
                <p className={classes.tableContent}>Rate</p>
                <p className={classes.tableContent} style={{ width: "32px" }}>SOW</p>
              </div>
              <div>
                {isLoading && <p className={classes.no_data}>Loading .....</p>}
                {!isLoading1 && !isError1 ? (
                  AgreementDataTech?.length > 0 ? (
                    typeof AgreementDataTech !== "string" &&
                    AgreementDataTech?.map((item, index) => {
                      return (
                        <div
                          className={classes.td}
                          key={item?.positionId}
                          style={{ position: "relative" }}
                        >
                          <CustomTooltip
                            value={item?.resource}
                            limit={15}
                            className={`${classes.tableContent} ${classes.link}`}
                            index={`resource${index}`}
                          />
                          <p className={classes.tableContent}>
                            {item?.resourceType}
                          </p>

                          <p className={classes.tableContent}>
                            {item?.startDate
                              ? moment(item?.startDate).format("DD MMM YY")
                              : ""}
                          </p>

                          <p className={classes.tableContent}>
                            {item?.endDate
                              ? moment(item?.endDate).format("DD MMM YY")
                              : ""}
                          </p>

                          <p className={classes.tableContent}>{item?.rate}</p>
                          <p className={classes.tableContent} style={{ width: "32px" }}>{item?.fileUrl && <a href={item?.fileUrl} target="_blank"><CgNotes /></a>}</p>
                        </div>
                      );
                    })
                  ) : (
                    <p className={classes.no_data}>No Data Found</p>
                  )
                ) : null}
              </div>
            </div>
          </InvoiceContainer>
        </Col>
        <button
          className={classes.buttonAdd1}
          onClick={() => {
            setNextScreen("PartnerList");
            setPrevData((prevData) => ({
              ...prevData,
              page2: "PartnerList",
              page3: null,
              page4: null,
              active: "PartnerList",
            }));
          }}
        >
          Done
        </button>
      </Container>
    </>
  );
};
export default Milestones;
