import React, { useEffect, useState } from "react";
import SideBar from "../../sidebar/SideBar";
import style from "./companyHierarchy.module.css";
import { useQuery } from "react-query";
import { getHierarchy, getActiveEmployees } from "../../../../Utils/axios/umsApi";
import { path } from "../../../../routes/PathNames";
import FamilyTree from "./familyTree/familyTree";
// import { customSearch } from "../../../EmployeePerformanceReview/utils/style";
// import Select from "react-select";
import { AiOutlineClose } from "react-icons/ai";
import searchIcon from "../../../../assets/Icon-search.png";
import FilterSearchSelect from "../../../atsDashboard/commonComponent/filterSearch/FilterSearchSelect";

const CompanyHierarchy = () => {
    const [empId, setEmpId] = useState(null)

    const [reporteeData, setReporteeData] = useState({
        employee: "InnovationM",
        employeesUnder: []
    });




    const { data: hierarchyData, isLoading: hierarchyDataLoading, isError: hierarchyDataError } = useQuery(
        ["getHierarchy", empId],
        () => getHierarchy(empId),
        {
            refetchOnWindowFocus: false,
        }
    );

    const { data: activeEmployeeData, isLoading: activeEmployeeDataLoading, isError: activeEmployeeDataError } = useQuery(
        ["getActiveEmployee"],
        () => getActiveEmployees(),
        {
            refetchOnWindowFocus: false,
        }
    );




    const getEmployeeNameOptions = activeEmployeeData?.map((item) => {
        return {
            value: item?.id,
            label: `${item?.name} (${item?.code})`,
            label1: item?.name,
            employeeCode: item?.code,
        };
    });

    const onSearchHandler = (e) => {
        setEmpId(e?.value);
    }


    useEffect(() => {
        setReporteeData({
            employee: "InnovationM",
            children: hierarchyData
        })
    }, [hierarchyData]);

    return (
        <div className={style.main_client_body}>
            <SideBar />
            <div className={style.main_create}>
                <div className={style.dashboard_container}>
                    <div>
                        <div className="d-flex align-items-center justify-content-between">
                            <p className={style.heading}>Reporting Hierarchy</p>
                            <div className="d-flex align-items-center">
                                <FilterSearchSelect
                                    placeholder="Select Employee"
                                    value={empId}
                                    options={getEmployeeNameOptions}
                                    onChange={(e) => onSearchHandler(e)}
                                    onClose={() => setEmpId(null)}
                                />
                            </div>
                        </div>
                        <div className={style.count_card_container}>
                            {
                                !empId ? <>{reporteeData?.children?.length > 0 ? <FamilyTree data={reporteeData} /> : 'Loading...'}</> : <div style={{fontSize:"20px"}}>
                                    {reporteeData?.children?.employee ? `Lead Name : ${reporteeData?.children?.employee}` : "No lead found"}
                                </div>
                            }

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default CompanyHierarchy;
