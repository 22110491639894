
const createImage = (url) =>
    new Promise((resolve, reject) => {
        const image = new Image();
        image.onload = () => resolve(image);
        image.onerror = (error) => reject(error);
        image.src = url;
    });

const getCroppedImg = async (imageSrc, crop, fileName = "additional-image.jpg") => {
    const image = await createImage(imageSrc);
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    canvas.width = crop.width;
    canvas.height = crop.height;

    ctx.drawImage(
        image,
        crop.x,
        crop.y,
        crop.width,
        crop.height,
        0,
        0,
        crop.width,
        crop.height
    );

    return new Promise((resolve) => {
        canvas.toBlob(
            (blob) => {
                const file = new File([blob], fileName, { type: "image/jpeg" });
                resolve(file);
            },
            "image/jpeg"
        );
    });
};

export default getCroppedImg;
