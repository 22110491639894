import React, { useState, useEffect } from "react";
import BreadCrum from "../../breadcrums/BreadCrum";
import InvoiceContainer from "../../CommonComponent/InvoiceContainer/InvoiceContainer";
import Note from "../../note/Note.js";
import style from "./EmployeeInductionDetails.module.css";
import InvoiceButton from "../../../Employee_Induction/CommonComponent/Button/InvoiceButton";
import { useMutation, useQuery } from "react-query";
import {
  EmployeeInduction,
  InductionNotes,
  addNote,
  deleteNote,
  getActivity,
  getCheckList,
  getEmployeeInduction,
  getJoiningKit,
} from "../../../../Utils/axios/InductionApi";

import { validateEmployeeAccess } from "../../../../Utils/axios/api";
import * as Img from "../../../../assets/index";
import moment from "moment";
import { employeeId } from "../../../../Utils/constants";
import { notifyError, notifySuccess } from "../../../atsDashboard/utils/notify.js";
import NotesTable from "../../notesTable/NotesTable.js";

function EmployeeInductionDetails({
  setNextScreen,
  prevData,
  setPrevData,
  setInductionData,
}) {
  const [checklistEmployee, setCheckListEmployee] = useState([]);

  const [activity, setActivity] = useState(null);
  const [joiningKit, setJoiningKit] = useState(null);
  const [isNote, setIsNote] = useState(false);
  const [isEditNote, setIsEditNote] = useState(false);
  const [noteData, setNoteData] = useState({
    employeeId: "",
    note: "",
    noteByEmpId: employeeId,
    noteId: null
  })
  const { data: getValidateEmp } = useQuery(
    ["validateEmployeeAccess"],
    () => validateEmployeeAccess(parseInt(employeeId)),
    {
      refetchOnWindowFocus: false,
      removeAfterUnmount: true,
      cacheTime: 1000,
    }
  );

  const getVData =
    getValidateEmp &&
    getValidateEmp?.map((i) => ({
      label: i?.elementCode,
      value: i?.empId,
    }));

  useEffect(() => {
    refetch();
  }, [prevData?.partnerId]);

  // getEmployeeInductionById of emp
  const { data: InductionView } = useQuery(["EmployeeInduction"], () =>
    EmployeeInduction(prevData?.idData)
  );
  const { data: Notes, refetch: refetchNotes } = useQuery(["InductionNotes"], () =>
    InductionNotes(prevData?.idData)
  );
  useEffect(() => {
    if (InductionView) {
      setNoteData({
        ...noteData,
        employeeId: InductionView.empId,
      })
    }
  }, [InductionView])
  // checklist sets activity and joiningkit checklistId
  const { data: checkList, refetch } = useQuery(
    ["checkList"],
    () => getCheckList(),
    {
      onSuccess: (res) => {
        const orientationActivity = res?.find((e) => e?.categoryName === "Employee Orientation");
        setActivity(orientationActivity?.checkListId);

        const employeeDetails = res?.find((e) => e?.categoryName === "Employee Details");
        setJoiningKit(
          employeeDetails?.checkListId
        );
        res?.length > 0 && setCheckListEmployee(res);
      },
      refetchOnWindowFocus: false,
    }
  );

  // give activity data
  const { data: ActivityStatus } = useQuery(
    ["getActivity", activity, prevData?.idData],
    () => activity && getActivity(activity, prevData?.idData),
    {
      cacheTime: 0,
    }
  );

  // give joiningKit data
  const { data: JoiningStatus2 } = useQuery(
    ["joiningStatus2", joiningKit, prevData?.idData],
    () => joiningKit && getJoiningKit(joiningKit, prevData?.idData),
    {
      cacheTime: 0,
    }
  );

  // getEmployeInduction by id
  const { data: checkListEmp, refetch: employeeInductionRefetch } = useQuery(
    ["getEmployeeInductions"],
    () => getEmployeeInduction(prevData?.idData),
    {
      onSuccess: (res) => {
        let status = false;
        for (let i = 0; i < res?.length; i++) {
          if (res[i]?.status === "Done" || res[i]?.status === "N.A") {
            status = true;
            break;
          }
        }
        if (status) {
          setCheckListEmployee(res);
        } else {
          refetch();
        }
      },
    }
  );

  useEffect(() => {
    setTimeout(() => {
      employeeInductionRefetch();
    }, 1000);
  }, []);

  const commonCheckListContainer = (categoryId) => {
    return (
      <div className={style.Container}>
        <div className={style.col212}>
          <p className={style.label}>Name</p>
          {checklistEmployee?.map((element) => {
            return (
              <>
                <p className={style.values}>
                  {element?.categoryId === categoryId
                    ? element?.checkListName
                    : ""}
                </p>
              </>
            );
          })}
        </div>
        <div className={style.col212}>
          <p className={style.label}>Status</p>
          {checklistEmployee?.map((element) => {
            return (
              <p className={style.values} key={element?.id}>
                {element?.categoryId === categoryId
                  ? element?.status === "Done"
                    ? "Done"
                    : element?.status === "N.A"
                      ? "N.A"
                      : "-"
                  : ""}
              </p>
            );
          })}
        </div>
      </div>
    );
  };

  const addNoteMutation = useMutation(addNote, {
    onSuccess: (data) => {
      notifySuccess(data);
      setIsNote(false);
      refetchNotes();
      setNoteData({
        employeeId: InductionView.empId,
        note: "",
        noteByEmpId: employeeId,
        noteId: null,
      })
    },

    onError: (error) => {
      notifyError("Error adding note.", error);
    },
  });
  const deleteNoteMutation = useMutation(deleteNote, {
    onSuccess: (data) => {
      notifySuccess(data);
      refetchNotes();
    },
    onError: (error) => {
      notifyError("Error deleting note.", error);
    },
  });
  const handleAddNote = (action) => {
    if (action === "edit") {
      addNoteMutation.mutate(noteData);
    } else {
      const newNoteData = { ...noteData, noteId: null }
      addNoteMutation.mutate(newNoteData);
    }

  }
  const handleNoteEdit = (editNote) => {
    setIsNote(true);
    setIsEditNote(true);
    setNoteData({
      employeeId: InductionView.empId,
      note: editNote.note,
      noteByEmpId: employeeId,
      noteId: editNote.noteId,
    })
  }
  const handleNoteDelete = (deleteNote) => {
    deleteNoteMutation.mutate(deleteNote);
  }

  return (
    <>
      <div>
        <BreadCrum
          prevData={prevData}
          setPrevData={setPrevData}
          setNextScreen={setNextScreen}
        ></BreadCrum>
      </div>
      {/* <div style={{ position: "sticky", top: 0 }}> */}
      <InvoiceContainer>
        <div className={style.emp_details}>Employee Details </div>
        <div className={style.Container}>
          <div className={style.col212}>
            <p className={style.label}>Emp Code</p>
            <p className={style.value}>{InductionView?.empCode}</p>
          </div>
          <div className={style.col212}>
            <p className={style.label}>Emp Name</p>
            <p className={style.value}>{InductionView?.employeeName}</p>
          </div>
          <div className={style.col212}>
            <p className={style.label}>Date of Joining</p>
            <p className={style.value}>
              {moment(InductionView?.dateOfJoining).format("DD MMM YY") ?? ""}
            </p>
          </div>
          <div className={style.col212}>
            <p className={style.label}>Designation</p>
            <p className={style.value}>{InductionView?.designation}</p>
          </div>
        </div>
        <div className={style.Container}>
          <div className={style.col212}>
            <p className={style.label}>Department</p>
            <p className={style.value}>{InductionView?.department}</p>
          </div>
        </div>
      </InvoiceContainer>
      {/* </div> */}
      <div style={{ height: "70vh", overflow: "scroll" }}>
        <div className={style.heading}>Employee Details</div>
        <InvoiceContainer>
          <div className={style.Container}>
            <div className={style.col212}>
              <p className={style.label}>Joining Kit Status</p>
              <p className={style.value}>{JoiningStatus2?.joiningStatus}</p>
            </div>
            <div className={style.col213}>
              <p className={style.labelFolderLink}>Folder Link</p>
              <p className={style.valueFolderLink}>
                <a href={InductionView?.folderURL} target="_blank" >
                  <img
                    src={
                      InductionView?.folderURL
                        ? Img.folderOpen
                        : Img.folder_link_blue
                    }
                    alt="folder"
                    height={20}
                    width={20}
                  />
                </a>
              </p>
            </div>
            <div className={style.col212}>
              <p className={style.label}>Induction Status</p>
              <p className={style.values}>
                {JoiningStatus2?.overallStatus ? "Done" : "In-Progress"}
              </p>
            </div>
          </div>
        </InvoiceContainer>
        <div className={style.heading}>Employee Induction Activities</div>
        <InvoiceContainer>{commonCheckListContainer(6)}</InvoiceContainer>
        <div className={style.heading}>Employee Document Details</div>
        <InvoiceContainer>{commonCheckListContainer(3)}</InvoiceContainer>
        <div className={style.heading}>Joining Form</div>
        <InvoiceContainer>{commonCheckListContainer(4)}</InvoiceContainer>
        <div className={style.heading}>Employee Orientation</div>
        <InvoiceContainer>
          <div className={style.Container}>
            <div className={style.col212}>
              <p className={style.label}>Activity Date</p>
              <p className={style.values}>
                {ActivityStatus?.date ? moment(ActivityStatus?.date).format("DD MMM YY") : ""}
              </p>
            </div>
            <div className={style.col212}>
              <p className={style.label}>HR Name</p>
              <p className={style.values}>{ActivityStatus?.hrName}</p>
            </div>
            <div className={style.col212}>
              <p className={style.label}>Status</p>
              <p className={style.values}>{ActivityStatus?.status}</p>
            </div>
          </div>
        </InvoiceContainer>
        <div className={style.heading}>Introduction</div>
        <InvoiceContainer>{commonCheckListContainer(7)}</InvoiceContainer>
        <div className={style.heading}>Letters</div>
        <InvoiceContainer>{commonCheckListContainer(8)}</InvoiceContainer>
        <div className={style.heading}>HRMS</div>
        <InvoiceContainer>{commonCheckListContainer(9)}</InvoiceContainer>
        <div className={style.heading}>Payroll</div>
        <InvoiceContainer>{commonCheckListContainer(10)}</InvoiceContainer>
        <div
          style={{
            display: "flex",
            justifyContent: "end",
            paddingRight: "1rem",
          }}
        >
          <div className={style.lastBtn}>
            <button className={style.addNoteBtn}
              onClick={
                () => {
                  if (!isNote) {
                    setNoteData({ ...noteData, note: "" })
                  }
                  setIsNote(!isNote)
                  setIsEditNote(false);
                }
              }
            >
              + Add Note
            </button>
          </div>

          <div className={style.lastBtn}>
            {getVData?.some((e) => {
              return e?.label === "144S9515W33893TD";
            }) && (
                <InvoiceButton
                  onClick={() => {
                    setNextScreen("EditEmployee");
                    setPrevData((prevData) => ({
                      ...prevData,
                      page2: "EditEmployee",
                      ActivityStatus: ActivityStatus,
                      checkListId1: checklistEmployee?.find(
                        (e) => e?.categoryName === "Employee Orientation"
                      )?.checkListId,
                      JoiningStatus2: JoiningStatus2,
                      overallStatus: false,
                      checkListId2: checklistEmployee?.find(
                        (e) => e?.categoryName === "Employee Details"
                      )?.checkListId,
                      ViewStatus: checkListEmp,
                      checklistEmployee: checklistEmployee,
                      page3: null,
                      page4: null,
                      active: "EditEmployee",
                      folderURL: InductionView?.folderURL
                    }));
                    setInductionData(checkListEmp);
                  }}
                >
                  Edit
                </InvoiceButton>
              )}
          </div>
        </div>
        {isNote &&
          <Note
            isEditNote={isEditNote}
            setIsNote={setIsNote}
            noteData={noteData}
            setNoteData={setNoteData}
            handleAddNote={handleAddNote}
          />}
        <div className={style.heading} >Notes</div>
        <InvoiceContainer>
          <NotesTable
            data={Notes}
            handleNoteEdit={handleNoteEdit}
            handleNoteDelete={handleNoteDelete}
          />
        </InvoiceContainer>
      </div >
    </>
  );
}

export default EmployeeInductionDetails;
