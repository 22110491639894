import React, { useState } from 'react';
import style from './NotesTable.module.css';
import Table from 'react-bootstrap/Table';
import Popup from 'reactjs-popup';
import { MdEdit, MdDeleteForever } from "react-icons/md";
import { employeeId } from '../../../Utils/constants';


function NotesTable({ data, handleNoteEdit, handleNoteDelete }) {
    const [confirmModal, setConfirmModal] = useState(false);
    const [deleteNote, setDeleteNote] = useState({});
    const handleDeletePopup = (note) => {
        setDeleteNote(note);
        setConfirmModal(true);
    }
    const closePopup = () => {
        setConfirmModal(false)
        setDeleteNote({});
    }
    const handleDelete = async () => {
        setConfirmModal(false);
        handleNoteDelete(deleteNote);
    }

    return (
        <div className={style.note_table}>
            {data?.count === 0 ? <p className={style.no_notes}>No Notes Available</p> :
                <Table borderless hover responsive >
                    <thead>
                        <tr>
                            <th style={{ width: '8rem' }}>Reported Date</th>
                            <th style={{ width: '10rem' }}>Username</th>
                            <th>Note</th>
                            <th style={{ width: '5rem' }}>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {false ? <tr>
                            <td colSpan={4} style={{ textAlign: 'center' }}>No Data Found</td>
                        </tr> : (
                            data?.noteList.map(note => (
                                <tr>
                                    <td style={{ width: '8rem' }}>{note.createdOn}</td>
                                    <td style={{ width: '10rem' }}>{note.noteByEmpName}</td>
                                    <td style={{whiteSpace: "pre-wrap"}}>{note.note}</td>
                                    <td style={{ width: '5rem' }}>
                                        {employeeId == note.noteByEmpId ?
                                            <div>
                                                <MdEdit
                                                    className={style.table_icon_active}
                                                    onClick={() => handleNoteEdit(note)}
                                                />
                                                <MdDeleteForever
                                                    className={style.table_icon_active}
                                                    onClick={() =>
                                                        handleDeletePopup(note)
                                                    }
                                                    style={{
                                                        cursor: "pointer",
                                                    }}
                                                />
                                            </div> :
                                            <div>
                                                <MdEdit
                                                    className={style.table_icon}
                                                />
                                                <MdDeleteForever
                                                    className={style.table_icon}
                                                />
                                            </div>}
                                    </td>
                                </tr>
                            ))

                        )}
                    </tbody>
                </Table>
            }
            <Popup
                open={confirmModal}
                closeOnDocumentClick={false}
            >
                <div className={style.confirmPopup}>
                    <h5 className={style.confirmPopupTitle}>
                        Are you sure you want to delete?
                    </h5>
                    <div className={style.btnCont}>
                        <button className={style.yesBtn}
                            onClick={handleDelete}
                        >
                            Yes
                        </button>
                        <button className={style.noBtn} onClick={closePopup}>
                            No
                        </button>
                    </div>
                </div>
            </Popup>
        </div>
    )
}

export default NotesTable