import React, { useEffect } from "react";
import { useQuery } from "react-query";
import {
  getInterviewById,
  getInterviewStatus,
  getInterviewTypeByInterviewId,
  skillsForHrInterview,
  skillsForPosition,
} from "../../../../Utils/axios/api";
import CommonHeader from "../../../../GenericComponent/header/CommonHeader";
import style from "./Feedback.module.css";
import HrFeedback from "./HrFeedback";
import TechnicalFeedback from "./TechnicalFeedback";
import { FcExpired } from "react-icons/fc";
import { employeeId } from "../../../../Utils/constants";

function Feedback(props) {
  const [showInterview, setShowInterview] = React.useState("");
  const [checkSubmit, setCheckSubmit] = React.useState(false);

  const { data: getInterviewTypeByInterview, refetch } = useQuery(
    ["getInterviewTypeByInterviewId"],
    () => getInterviewTypeByInterviewId(props.match.params.id),
    {
      refetchOnWindowFocus: false,
    }
  );

  const { data: getInterviewBy } = useQuery(
    ["getInterviewById", showInterview],
    () => getInterviewById(showInterview?.interviewId),
    {
      refetchOnWindowFocus: false,
      enabled: !!showInterview?.interviewId,
    }
  );
  // const { data: getFeedback } = useQuery(
  //   ["getFeedbackField", getInterviewBy],
  //   () => getFeedbackField(getInterviewBy?.candidateId),
  //   {
  //     refetchOnWindowFocus: false,
  //   }
  // );
  const { data: getTechFeedback } = useQuery(
    ["getFeedbackField", getInterviewBy],
    () => skillsForPosition(getInterviewBy?.positionId),
    {
      enabled: (!!getInterviewBy?.positionId) && showInterview?.interviewType === "Technical",
      refetchOnWindowFocus: false,
    }
  );

  const { data: getHrFeedback } = useQuery(
    ["getFeedbackField", getInterviewBy],
    skillsForHrInterview,
    {
      enabled: showInterview?.interviewType === "HR Round",
      refetchOnWindowFocus: false,
    }
  );

  const {data : interviewStatuses} = useQuery(
    ["getInterviewStatus"],
    getInterviewStatus,
    {
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    setShowInterview(getInterviewTypeByInterview);
  }, [getInterviewTypeByInterview]);

  useEffect(() => {
    refetch();
  }, [checkSubmit]);

  const convertTime = (value)=>{
    if(value!==undefined){let hrs;
    let currTime = new Date()
    let temp = value?.split(" ");
    if(temp[1]=="PM" && temp[0].split(':')[0]!=12){
      hrs=parseInt(temp[0].split(':')[0])+12;
    }
    else hrs=parseInt(temp[0].split(':')[0])
    if(hrs>currTime.getHours()) return false
    else if(hrs==currTime.getHours() && temp[0].split(':')[1]>currTime.getMinutes()) return false;
    else return true;}
  }
  
  let a = getInterviewBy?.dateOfInterView ? new Date(getInterviewBy?.dateOfInterView).toISOString().split('T')[0] : null;
  let b = new Date();
  b = b.toISOString().split('T')[0];
  // console.log(a>b)

  const comp = () =>{
    return <>
    {getInterviewBy ? <>{employeeId===getInterviewBy?.assignedInterviewerId ? 
      <>
        {showInterview?.interviewType === "HR Round" &&
          showInterview?.submitted === false && (a<b ? true : a==b ? convertTime(getInterviewBy?.interviewTime) : false) && (
            <HrFeedback
              fieldName={getHrFeedback}
              interviewId={showInterview?.interviewId}
              getInterviewBy={getInterviewBy}
              setCheckSubmit={setCheckSubmit}
              interviewStatuses={interviewStatuses}
            />
        )}
        {showInterview?.interviewType === "Technical" &&
          showInterview?.submitted === false && (a<b ? true : a==b ? convertTime(getInterviewBy?.interviewTime) : false) && (
            <TechnicalFeedback
              fieldName={getTechFeedback}
              interviewId={showInterview?.interviewId}
              getInterviewBy={getInterviewBy}
              setCheckSubmit={setCheckSubmit}
              getInterviewTypeByInterview={getInterviewTypeByInterview}
              interviewStatuses={interviewStatuses}
            />
          )}
          {(a>b ? true : (a==b ? !convertTime(getInterviewBy?.interviewTime) : false)) && (
            <div className={style.submitted}>
            <p>
              The interview has not started yet.
            </p>
          </div>
          )}
          {showInterview?.submitted === true && (
            <div className={style.submitted}>
              <p>
                {checkSubmit
                  ? "The interview feedback has been submitted."
                  : "The interview feedback has already been submitted."}{" "}
              </p>
            </div>
          )}
        </> : 
        <div className={style.noAccess}>
          <p>
            This link is no longer active as the interviewer has changed.
          </p>
        </div>
    }</> : null}</>
  }

  return (
    <>
      <CommonHeader />
    {showInterview?.expired===true ?
    <div className={style.expired}>
    <FcExpired style={{fontSize:"80px"}} /> <p>The link has been expired</p> </div>
    :
    comp()
    }
    </>
  );
}

export default Feedback;
