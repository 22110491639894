import React, { useState, useEffect } from "react";
import Header from "../../../../taskTracker/commonComponent/Header";
import style from "./OnsiteDescription.module.css";
import Sidebar from "../../../Clients/component/SideBar";
import cookie from "react-cookies";
import TableRow from "../../PoolResources/ResourceOnsiteDescription/TableRow";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Tooltip from "@mui/material/Tooltip";
import { useHistory } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import constants from "../../../../../Utils/constants";
import TaskTable from "../../OffShoreDescription/TaskTable";
import Moment from "moment";
import Pagination from "../../../CommonComponent/Pagination";
import ApplicationInformation from "../../PoolResources/PoolResourcesComment/PoolComment";
import { Table } from "react-bootstrap";
import { api } from "../../../CommonApi/api";
import { get, post } from "../../../CommonApi/axiosCall";
import { ToastifyError } from "../../../../../App";
import { Toastify } from "../../../../../App";
import { rmsAccessCode } from "../../../../../Utils/constants";
import { employeeId } from "../../../../../Utils/constants";
import useValidationAccess from "../../../../custumHooks/useValidationAccess";
import { useParams, useLocation } from "react-router-dom";

const AllocationOnsiteDescription = (props) => {
  const { checkValidateAccess } = useValidationAccess();

  const [OfShoreData, setOfShoreData] = useState([]);
  const [AddToggle, setAddToggle] = useState(false);
  const [ApplicationData, setApplicationData] = useState([]);
  const [applicationDetail, setApplicationDetail] = useState([]);
  const [commenttype, setCommentType] = useState("");
  const [show, setShow] = useState(false);
  const [addhandler, setAddHandler] = useState(false);
  const [editHandler, setEditHandler] = useState(false);
  const [myTaskDetails, setMyTaskDetails] = useState();
  const [pageCount, setpageCount] = useState(0);
  const [offshoreTotalTask, setoffshoreTotalTask] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [commentID, setCommentID] = useState();

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const history = useHistory();
  const id = searchParams.get("id");
  const url2 = constants.apiBaseUrl2;

  const EditHandler = () => {
    history.push(`/EditResourcesAllocationOnsite?id=${id}`);
  };

  const limit = 3;

  const handlePageChange = (page, status) => {
    if (status == "next") {
      setCurrentPage(page + 1);
    } else if (status == "prev") {
      setCurrentPage(page - 1);
    }
  };
  const HistoryHandler = () => {
    history.push(`/OnSiteChangeHistory?id=${id}`);
  };
  const EditTask = (id1) => {
    history.push({
      pathname: "/OnSite/TaskDetails",
      state: {
        id: id1,
        id1: id,
      },
    });
  };

  const viewPerformance = () => {
    window.open(`/EmployeePerformanceReview?id=${OfShoreData.empid}`, "_blank");
  };
  const MyTaskDetails = (id) => {
    get(
        url2 +
          `/getTaskDetailsByEmpIdForRms?empId=${id}&limit=3&page=${currentPage}`,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization: ` ${cookie.load("token")}`,
          },
        }
      )
      .then((res) => {
        setMyTaskDetails(res?.data?.responseData );
        setMyTaskDetails(res?.data?.responseData);
        setoffshoreTotalTask(res?.data?.responseData?.resourceCount);
        setpageCount(res?.data?.responseData?.resourceCount / 3);
      })
      .catch((err) => {});
  };
  const getOnSiteDetails = (id) => {
    let url = api.getOnsiteResourceDetailsById;
    if (id) {
      url += `?id=${id}`;
    }
    get(url)
      .then((res) => {
        setOfShoreData(res?.data?.responseData);
        getApplicationDetails(res?.data?.responseData?.empid);
        setAddToggle(false);
        MyTaskDetails(res?.data?.responseData?.empid);
        setShow(false);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    id && getOnSiteDetails(id);
  }, [show, currentPage]);

  const getApplicationDetails = (id) => {
    let url = api.getPoolResourceComments;
    if (id) {
      url += `?empId=${id}`;
    }
    url += `&limit=10`;

    get(url)
      .then((res) => {
        setApplicationData(res.data.responseData);
      })
      .catch((err) => {});
  };

  const getSingleDetailsById = (id) => {
    let url = api.getPoolResourceCommentById;
    if (id) {
      url += `?commentId=${id}`;
    }

    get(url)
      .then((res) => {
        setApplicationDetail(res.data.responseData);
      })
      .catch((err) => {});
  };

  const AddHandler = (id, type) => {
    setAddHandler(false);
    setEditHandler(true);
    setCommentType(type);
    setCommentID(id);
    if (id) {
      getSingleDetailsById(id);
    }
    window.scrollTo(0, 0);
    setAddToggle(!AddToggle);
  };

  const AddHandler1 = () => {
    setAddHandler(true);
    setEditHandler(false);
    setAddToggle(!AddToggle);
  };

  const AddTaskhandler = () => {
    history.push({
      pathname: "/CreateTask",
      state: {
        task: OfShoreData,
        comment: "",
        page: 2,
      },
    });
  };

  const HistoryHandler1 = () => {
    history.push({
      pathname: "/ResourcesAllocationOnsite",
    });
  };
  const BackHandler = () => {
    setAddToggle(!AddToggle);
  };
  useEffect(() => {
    commentID && getSingleDetailsById(commentID);
  }, [show]);

  const addWatchListt = (requesterId, resourceId) => {
    let url = api.addResouresWatchList;
    if (requesterId !== undefined && requesterId !== null) {
      url += `?requesterId=${requesterId}`;
    }
    if (resourceId !== undefined && resourceId !== null) {
      url += `&resourceId=${resourceId}`;
    }
    post(url)
      .then((res) => {
        if (res.data.responseStatus.statusCode === 1) {
          ToastifyError(res.data.responseData.message);
        } else if (res.data.responseStatus.statusCode === 0) {
          Toastify(res.data.responseData);
        }
      })
      .catch();
  };

  return (
    <>
      <Header />
      <div className={style.main_client_body}>
        <Sidebar />

        <div className={style.table_div}>
          <div className={style.table_div_head}>
            <div className={style.breadscrumb} style={{ marginTop: "22px" }}>
              <span style={{ fontWeight: "700" }}>
                View<span className={style.arrow}> {">"} </span>{" "}
                <span
                  className={style.recent_tab}
                  style={{ fontWeight: "700" }}
                >
                  Resource (Onsite)
                </span>
              </span>
              <Tooltip title="Add to Watchlist">
                <GroupAddIcon
                  onClick={() => addWatchListt(employeeId, OfShoreData?.empid)}
                  style={{ cursor: "pointer", color: "rgb(252, 187, 8)" }}
                />
              </Tooltip>
            </div>
            <div className={style.form_div}>
              <div id="create_task_id" className={style.gridContainer}>
                <div className={style.assignLabel} style={{ padding: "6px" }}>
                  <label>Client Name </label>
                  <p style={{ marginTop: "9px", marginLeft: "11px" }}>
                    {OfShoreData?.clientName}
                  </p>
                </div>
                <div
                  className={style.assignLabel}
                  style={{ height: "30px", padding: "6px" }}
                >
                  <label>Employee Name </label>
                  <p style={{ marginTop: "9px", marginLeft: "11px" }}>
                    {OfShoreData?.empName}
                  </p>
                </div>

                <div className={style.proSecton} style={{ padding: "6px" }}>
                  <label style={{ marginTop: "3px" }}>Technology </label>
                  <p style={{ marginTop: "9px", marginLeft: "11px" }}>
                    {OfShoreData?.skillName}
                  </p>
                </div>
                <div className={style.proSecton} style={{ padding: "6px" }}>
                  <label className={style.desc}>Project Start Date </label>

                  <p style={{ marginTop: "9px", marginLeft: "11px" }}>
                    {Moment(OfShoreData?.allocationdate).format("DD MMM YY")}
                  </p>
                </div>

                <div className={style.proSecton} style={{ padding: "6px" }}>
                  <label className={style.desc}>Project End Date </label>

                  <p style={{ marginTop: "9px", marginLeft: "11px" }}>
                    {OfShoreData?.deallocationdate
                      ? Moment(OfShoreData?.deallocationdate).format(
                          "DD MMM YY"
                        )
                      : OfShoreData?.deallocationdate}
                  </p>
                </div>
                <div className={style.proSecton} style={{ padding: "6px" }}>
                  <label className={style.desc}>Expected End Date </label>

                  <p style={{ marginTop: "9px", marginLeft: "11px" }}>
                    {OfShoreData?.expectedEndDate
                      ? Moment(OfShoreData?.expectedEndDate).format("DD MMM YY")
                      : OfShoreData?.expectedEndDate}
                  </p>
                </div>

                <div className={style.proSecton} style={{ padding: "6px" }}>
                  <label className={style.desc}>Experience </label>
                  <p style={{ marginTop: "9px", marginLeft: "11px" }}>
                    {~~(OfShoreData?.experience / 12)} Years{" "}
                    {OfShoreData?.experience % 12} Months
                  </p>
                </div>
                <div className={style.proSecton} style={{ padding: "6px" }}>
                  <label className={style.desc}>Last Comment Date </label>

                  <p style={{ marginTop: "9px", marginLeft: "11px" }}>
                    {OfShoreData?.currentCommentDate
                      ? Moment(OfShoreData?.currentCommentDate).format(
                          "DD MMM YY"
                        )
                      : null}
                  </p>
                </div>
                <div className={style.proSecton} style={{ padding: "6px" }}>
                  <label className={style.desc}>Employee Code </label>

                  <p style={{ marginTop: "9px", marginLeft: "11px" }}>
                    {OfShoreData?.empCode}
                  </p>
                </div>
                <div className={style.proSecton} style={{ padding: "6px" }}>
                  <label className={style.desc}>Salary Range </label>

                  <p style={{ marginTop: "9px", marginLeft: "11px" }}>
                    {OfShoreData?.salaryRange}
                  </p>
                </div>
                <div className={style.proSecton} style={{ padding: "6px" }}>
                  <label className={style.desc}>Client Notification Date</label>

                  <p style={{ marginTop: "9px", marginLeft: "11px" }}>
                    {OfShoreData?.clientNotificationDate &&
                      Moment(OfShoreData?.clientNotificationDate).format(
                        "DD MMM YY"
                      )}
                  </p>
                </div>
                <div className={style.proSecton} style={{ padding: "6px" }}>
                  <label className={style.desc}>Deployment Quarter </label>

                  <p style={{ marginTop: "9px", marginLeft: "11px" }}>
                    {OfShoreData?.deploymentQuarter}
                  </p>
                </div>
                <div className={style.proSecton} style={{ padding: "6px" }}>
                  <label className={style.desc}>Partner Informed </label>

                  <p style={{ marginTop: "9px", marginLeft: "11px" }}>
                    {OfShoreData?.isPartnerInformed
                      ? "Yes"
                      : OfShoreData?.isPartnerInformed
                      ? "No"
                      : ""}
                  </p>
                </div>
                <div className={style.proSecton} style={{ padding: "6px" }}>
                  <label className={style.desc}>Partner Informed Date</label>

                  <p style={{ marginTop: "9px", marginLeft: "11px" }}>
                    {OfShoreData?.partnerInformedDate &&
                      Moment(OfShoreData?.partnerInformedDate).format(
                        "DD MMM YY"
                      )}
                  </p>
                </div>
                <div className={style.proSecton} style={{ padding: "6px" }}>
                  <label className={style.desc}>Client Informed </label>

                  <p style={{ marginTop: "9px", marginLeft: "11px" }}>
                    {OfShoreData?.isClientInformed
                      ? "Yes"
                      : OfShoreData?.isClientInformed
                      ? "No"
                      : ""}
                  </p>
                </div>
                <div className={style.proSecton} style={{ padding: "6px" }}>
                  <label className={style.desc}>
                    Estimated Client Informed Date{" "}
                  </label>

                  <p style={{ marginTop: "9px", marginLeft: "11px" }}>
                    {OfShoreData?.clientInformedDate &&
                      Moment(OfShoreData?.clientInformedDate).format(
                        "DD MMM YY"
                      )}
                  </p>
                </div>
                <div className={style.proSecton} style={{ padding: "6px" }}>
                  <label className={style.desc}>Candidate Informed Date </label>

                  <p style={{ marginTop: "9px", marginLeft: "11px" }}>
                    {OfShoreData?.candidateInformedDate &&
                      Moment(OfShoreData?.candidateInformedDate).format(
                        "DD MMM YY"
                      )}
                  </p>
                </div>

                <div className={style.proSecton} style={{ padding: "6px" }}>
                  <label className={style.desc}>
                    Client Notification Reason{" "}
                  </label>

                  <p style={{ marginTop: "9px", marginLeft: "11px" }}>
                    {OfShoreData?.clientNotificationReason &&
                      OfShoreData?.clientNotificationReason}
                  </p>
                </div>

                <div className={style.proSecton} style={{ padding: "6px" }}>
                  <label className={style.desc}>Sales Person</label>

                  <p style={{ marginTop: "9px", marginLeft: "11px" }}>
                    {OfShoreData?.salesPersonName && OfShoreData?.salesPersonName}
                  </p>
                </div>

                <div className={style.proSecton} style={{ padding: "6px" }}>
                  <label className={style.desc}>Resource Source</label>

                  <p style={{ marginTop: "9px", marginLeft: "11px" }}>
                    {OfShoreData?.resourceSource &&
                    OfShoreData?.resourceSource === true
                      ? "Handover"
                      : "Self"}
                  </p>
                </div>

                <div className={style.proSecton} style={{ padding: "6px" }}>
                  <label className={style.desc}>Additional Core Skill</label>

                  <p style={{ marginTop: "9px", marginLeft: "11px" }}>
                    {OfShoreData?.resourceAdditionalSkills
                          ?.map((e) => e.additionalSkillName)
                          .join(", ")}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className={style.container}>
            <div style={{ marginTop: "15px" }}>
              {" "}
              <button className={style.gotoListBtn} onClick={HistoryHandler1}>
                {" "}
                Go To List
              </button>
            </div>
            <div className={style.btn_div}>
              <button className={style.viewFeedback} onClick={viewPerformance}>
                {" "}
                View Feedback
              </button>

              {checkValidateAccess(rmsAccessCode?.add_task_onsite) && (
                <button className={style.addTaskBtn} onClick={AddTaskhandler}>
                  {" "}
                  <span className={style.plus}>+</span>Add Task
                </button>
              )}
              {checkValidateAccess(rmsAccessCode?.add_comment_onsite) && (
                <button className={style.addcommentbtn} onClick={AddHandler1}>
                  {" "}
                  <span className={style.plus}>+</span>Add Comment
                </button>
              )}

              {checkValidateAccess(rmsAccessCode?.onsite_history) && (
                <button onClick={HistoryHandler} className={style.historybtn}>
                  Onsite History
                </button>
              )}
              {checkValidateAccess(rmsAccessCode?.edit_onsite) && (
                <button onClick={EditHandler} className={style.submitbtn}>
                  Edit
                </button>
              )}
            </div>
          </div>

          {AddToggle ? (
            <ApplicationInformation
              id={OfShoreData?.empid}
              historyy="onsitecomment"
              addhandler={addhandler}
              BackHandler={BackHandler}
              editHandler={editHandler}
              commenttype={commenttype}
              setShow={setShow}
              applicationDetail={applicationDetail}
            />
          ) : null}

          <div
            className={style.description_box1}
            style={{ marginBottom: "15px" }}
          >
            <div className={style.span2}>
              <p>My Task</p>
            </div>
            <div className={style.maindivContent}></div>
            <div
              className={style.original_table}
              style={{
                backgroundColor: "white",
                minHeight: "3rem",
                marginTop: "7px",
              }}
            >
              {myTaskDetails?.getAllData?.length > 0 ? (
                <Table borderless size="sm" style={{ fontSize: "12px" }}>
                  <thead
                    style={{
                      backgroundColor: "#F0F0F0",
                      position: "sticky",
                      top: "0px",
                    }}
                  >
                    <tr style={{ padding: "100px" }}>
                      <th style={{ padding: "25px" }}>Task Id</th>
                      <th style={{ padding: "25px" }}>Creation Date</th>
                      <th style={{ padding: "25px" }}>Task Title</th>
                      <th style={{ padding: "25px" }}>Assigned To</th>
                      <th style={{ padding: "25px" }}>Assigned By</th>
                      <th style={{ padding: "25px" }}>Status</th>
                      <th style={{ padding: "25px" }}>Priority</th>
                      <th style={{ padding: "25px" }}>Planned End Date</th>
                      <th style={{ padding: "25px" }}>Functional Area</th>
                    </tr>
                  </thead>
                  <tbody>
                    {myTaskDetails?.getAllData?.map((val) => {
                      return (
                        <TaskTable
                          clickHandler={EditTask}
                          data={val}
                          historyy={"offshore"}
                          id={id}
                          setShow={setShow}
                        />
                      );
                    })}
                  </tbody>
                </Table>
              ) : (
                <p className={style.p1} style={{ textAlign: "center" }}>
                  No Task Assigned
                </p>
              )}
            </div>
          </div>
          {offshoreTotalTask > 3 ? (
            <div style={{ marginBottom: "66px" }}>
              {offshoreTotalTask > limit && pageCount > 0 && (
                <div
                  className={style.paginationmobileContainer}
                  style={{ marginTop: "10px" }}
                >
                  <Pagination
                    pagesCount={pageCount}
                    pageSize={limit}
                    onPageChange={handlePageChange}
                    currentPage={currentPage}
                  />
                </div>
              )}
            </div>
          ) : null}
          <div className={style.description_box1}>
            <div className={style.span2}>
              <p>Comment History</p>
            </div>
            <div className={style.maindivContent}></div>
            <div
              className={style.original_table}
              style={{
                backgroundColor: "white",
                minHeight: "3rem",
                marginTop: "7px",
              }}
            >
              {ApplicationData?.getAllData?.length > 0 ? (
                <Table borderless size="sm" style={{ fontSize: "12px" }}>
                  <thead
                    style={{
                      backgroundColor: "#F0F0F0",
                      position: "sticky",
                      top: "0px",
                    }}
                  >
                    <tr>
                      <th>Reported Date</th>
                      <th>Username</th>
                      <th>Comment</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {ApplicationData?.getAllData?.map((val) => {
                      return (
                        <TableRow
                          clickHandler={AddHandler}
                          data={val}
                          historyy={"onsiteshore"}
                          id={id}
                          setShow={setShow}
                          OfShoreData={OfShoreData}
                        />
                      );
                    })}
                  </tbody>
                </Table>
              ) : (
                <p className={style.p1} style={{ textAlign: "center" }}>
                  No Comment
                </p>
              )}
            </div>
          </div>
        </div>

        <ToastContainer />
      </div>
    </>
  );
};

export default AllocationOnsiteDescription;
