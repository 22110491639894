import React, { useState } from "react";
import style from "./ActivePartnerList.module.css";
import CommonDropdown from "../../../../GenericComponent/ui/CommonDropdown";
import Pagination from "../../../ClientQuestionnaire/CommonComponent/Pagination";
import { useQuery } from "react-query";
import {
  getActivePartnerDeployment,
  getClientOnSite,
  getEmployeeName,
  getPartnersName,
  getAddResourceOffshore,
  getClientOnSitePartnerActiveDeployment,
  getEmployeeNamePartnerActiveDeployment
} from "../../../../Utils/axios/PortalManagement";
import moment from "moment";
import { checkIfEmpty } from "../../../../Utils/constants";
import TotalCount from "../../../atsDashboard/TotalCount/TotalCount";
import DropSwitchComm from "../../../atsDashboard/commonComponent/DropSwitch/DropSwitchComm";

const ActivePartnerList = () => {
  const [page, setPage] = useState(1);

  const [filterData, setFilterData] = useState({
    action: [1],
    clientId: [],
    employeeId: [],
    partnerId: [],
    employeeStatus: [null, null],
    endDateMonth: [],
    partners: [2],
    quaterlyDeployment: [],
    quaterlyEndOfDeployment: [],
    salaryRange: [],
    salesPersonId: [],
    skill: [],
    startDateMonth: [],
  });
const [isChecked,setIsChecked]=useState(false)
  const handlePageChange = (page, status) => {
    if (status === "next") {
      setPage(page + 1);
    } else {
      setPage(page - 1);
    }
  };

  const { data: activePartnerList } = useQuery(
    ["activePartnerList", { page, filterData }],
    () => getActivePartnerDeployment(page, 10, filterData)
  );
  const { data: getOffshoreData } = useQuery(
    ["getAddResourceOffshore", { page, filterData,isChecked }],
    () => isChecked&&getAddResourceOffshore(page, 10, filterData)
  );

  const { data: getOnsiteClient } = useQuery(["getOnsiteClient"], () =>
    getClientOnSitePartnerActiveDeployment()
  );

  const onsiteClientOption = Array.isArray(getOnsiteClient)
    ? getOnsiteClient?.map((item) => {
        return {
          id: item?.id,
          label: item?.name,
        };
      })
    : [];

  const { data: getEmployeeNameList } = useQuery(["getEmployeeNameList", isChecked], () =>
    getEmployeeNamePartnerActiveDeployment(isChecked)
  );

  console.log("getEmployeeNameList -- ", getEmployeeNameList)

  const getEmployeeNameOption = Array.isArray(getEmployeeNameList)
    ? getEmployeeNameList?.map((item) => {
        return {
          id: item?.id,
          label: item?.name,
        };
      })
    : [];

  const { data: getPartnersList } = useQuery(["getPartnersName", isChecked], () =>
    getPartnersName(isChecked)
  );

  const getPartnerOption = Array.isArray(getPartnersList)
    ? getPartnersList?.map((item) => {
        return {
          id: item?.id,
          label: item?.name,
        };
      })
    : [];

  return (
    <div className={style.active_partner_main_div}>
      <div className={style.top_div}>
        <h1 className={style.heading}>Active Partner Deployment</h1>
        <TotalCount totalCount={isChecked?getOffshoreData?.totalResources: activePartnerList?.totalResources}/>
        <div className={style.right_div}>
        <div className={style.drop_down_div} style={{width:"vw"}}>
        <DropSwitchComm
            isChecked={isChecked}
            handleChange={() => {setIsChecked(!isChecked)
            }}
            label={"Internal"}
          />
</div>
          {!isChecked && <div className={style.drop_down_div}>
          
            <CommonDropdown
              color="#c3b14b"
              options={onsiteClientOption}
              placeholder="Client"
              value={checkIfEmpty(filterData?.clientId[0], {
                label: filterData?.clientName,
                id: filterData?.clientId[0],
              })}
              onChange={(e) => {
                setPage(1);
                setFilterData({
                  ...filterData,
                  clientId: [e?.id],
                  clientName: e?.label,
                });
              }}
              customInputOnClick={() =>
                setFilterData({
                  ...filterData,
                  clientId: [],
                })
              }
            />
          </div>}

          <div className={style.drop_down_div2}>
            <CommonDropdown
              color="#c3b14b"
              options={getEmployeeNameOption}
              value={checkIfEmpty(filterData?.employeeId[0], {
                label: filterData?.employeeName,
                id: filterData?.employeeId[0],
              })}
              onChange={(e) => {
                setPage(1);
                setFilterData({
                  ...filterData,
                  employeeId: [e?.id],
                  employeeName: e?.label,
                });
              }}
              customInputOnClick={() =>
                setFilterData({
                  ...filterData,
                  employeeId: [],
                })
              }
              placeholder="Employee Name/Code"
            />
          </div>

          <div className={style.drop_down_div}>
            <CommonDropdown
              color="#c3b14b"
              options={getPartnerOption}
              value={checkIfEmpty(filterData?.partnerId[0], {
                label: filterData?.partnerName,
                id: filterData?.partnerId[0],
              })}
              onChange={(e) => {
                setPage(1);
                setFilterData({
                  ...filterData,
                  partnerId: [e?.id],
                  partnerName: e?.label,
                });
              }}
              customInputOnClick={() =>
                setFilterData({
                  ...filterData,
                  partnerId: [],
                })
              }
              placeholder="Partner"
            />
          </div>
        </div>
      </div>

      {!isChecked &&  (
        <div className={style.table_div}>
          <table className={style.table}>
            <thead className={style.table_heading}>
              <th className={style.table_head}>Code</th>
              <th className={style.table_head}>Client Name 000 </th>
              <th className={style.table_head}>Name</th>
              <th className={style.table_head}>
                Partner
                <br /> Name
              </th>
              <th className={style.table_head}>Exp</th>
              <th className={style.table_head}>
                Salary
                <br /> Range
              </th>
              <th className={style.table_head}>Technology</th>
              <th className={style.table_head}>Start Date</th>
              <th className={style.table_head}>End Date</th>
              <th className={style.table_head}>Rating</th>
              <th className={style.table_head}>Deployed By</th>
            </thead>

            <tbody>
              {
                activePartnerList?.resources?.length > 0?
                activePartnerList?.resources?.map((item) => (
                <tr>
                  <td className={style.table_column}>{item?.empCode || "-"}</td>
                  <td className={style.table_column}>
                    <p
                      title={item?.clientName || "-"}
                      className={style.client_name_para}
                    >
                      {item?.clientName || "-"}
                    </p>
                  </td>
                  <td className={style.table_column}>
                    <p title={item?.empName} className={style.empName}>
                      {item?.empName || "-"}
                    </p>
                  </td>
                  <td className={style.table_column}>
                    <p title={item?.employer} className={style.employee_name}>
                      {item?.employer || "-"}
                    </p>
                  </td>
                  <td className={style.table_column}>
                    {item?.experience
                      ? `${~~(item?.experience / 12)} Year ${
                          item?.experience % 12
                        } Month`
                      : "-"}
                  </td>
                  <td className={style.table_column}>
                    {item?.salaryRange || "-"}
                  </td>
                  <td className={style.table_column}>
                    <p title={item?.skillName} className={style.skill_name}>
                      {item?.skillName || "-"}
                    </p>
                  </td>
                  <td className={style.table_column}>
                    {item?.allocationdate
                      ? moment(item?.allocationdate)?.format("DD MMM YY")
                      : "-"}
                  </td>
                  <td className={style.table_column}>
                    {item?.deallocationdate
                      ? moment(item?.deallocationdate)?.format("DD MMM YY")
                      : "-"}
                  </td>
                  <td className={style.table_column}>
                    {item?.currentRating || "-"}
                  </td>
                  <td className={style.table_column}>
                    {item?.deployedBy || "-"}
                  </td>
                </tr>
              )):<p style={{ textAlign: "center", padding: "5% 0%" }}>No data found</p>}
            </tbody>
          </table>
        </div>
      )}
      {isChecked && (
        <div className={style.table_div}>
          <table className={style.table}>
            <thead className={style.table_heading}>
              <th className={style.table_head}>Code</th>
              <th className={style.table_head}>Project Name</th>
              <th className={style.table_head}>Name</th>
              <th className={style.table_head}>
                Partner
                <br /> Name
              </th>
              <th className={style.table_head}>Exp</th>
              <th className={style.table_head}>
                Salary
                <br /> Range
              </th>
              <th className={style.table_head}>Technology</th>
              <th className={style.table_head}>Start Date</th>
              <th className={style.table_head}>End Date</th>
              <th className={style.table_head}>Rating</th>
              <th className={style.table_head}>Deployed By</th>
            </thead>

            <tbody>
            {getOffshoreData?.resources?.length > 0?
              getOffshoreData?.resources?.map((item) => (
                <tr>
                  <td className={style.table_column}>{item?.empCode || "-"}</td>
                  <td className={style.table_column}>
                    <p
                      title={item?.projectName || "-"}
                      className={style.client_name_para}
                    >
                      {item?.projectName || "-"}
                    </p>
                  </td>
                  <td className={style.table_column}>
                    <p title={item?.empName} className={style.empName}>
                      {item?.empName || "-"}
                    </p>
                  </td>
                  <td className={style.table_column}>
                    <p title={item?.partnerName} className={style.employee_name}>
                      {item?.partnerName || "-"}
                    </p>
                  </td>
                  <td className={style.table_column}>
                    {item?.experience
                      ? `${~~(item?.experience / 12)} Year ${
                          item?.experience % 12
                        } Month`
                      : "-"}
                  </td>
                  <td className={style.table_column}>
                    {item?.salaryRange || "-"}
                  </td>
                  <td className={style.table_column}>
                    <p title={item?.skillName} className={style.skill_name}>
                      {item?.skillName || "-"}
                    </p>
                  </td>
                  <td className={style.table_column}>
                    {item?.allocationdate
                      ? moment(item?.allocationdate)?.format("DD MMM YY")
                      : "-"}
                  </td>
                  <td className={style.table_column}>
                    {item?.deallocationdate
                      ? moment(item?.deallocationdate)?.format("DD MMM YY")
                      : "-"}
                  </td>
                  <td className={style.table_column}>
                    {item?.currentRating || "-"}
                  </td>
                  <td className={style.table_column}>
                    {item?.deployedBy || "-"}
                  </td>
                </tr>
              )):<p style={{ textAlign: "center"}}>No data found</p>}
            </tbody>
          </table>
        </div>
      ) }


      <div className={style.pagination_div}>
        <Pagination
          onPageChange={handlePageChange}
          currentPage={page}
          pagesCount={activePartnerList?.totalResources / 10}
          color="#c3b14b"
          color2="white"
        />
      </div>
    </div>
  );
};

export default ActivePartnerList;
