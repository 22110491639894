import React, { useState, useEffect } from "react";
import style from "./CreateTaskComponent.module.css";
import calendar from "../../../../assets/calendar.png";
import "react-calendar/dist/Calendar.css";
import axios from "axios";
import cookie from "react-cookies";
import { useHistory } from "react-router-dom";
import Moment from "moment";
import Select from "react-select";
import constants, { employeeId } from "../../../../Utils/constants";
import DatePicker from "react-datepicker";
import { customStylesForSelectTaskTracker, customStylesForTaskTracker2 } from "../../../Projects_and_Resource_Master/Clients/component/style";
import { taskTrackerAccessCode } from "../../../../Utils/constants";
import useValidationAccess from "../../../custumHooks/useValidationAccess";
import { useLocation } from "react-router-dom";
import SideBar from "../../commonComponent/SideBar";
import style1 from "../CreateTask.module.css"
import { useMutation, useQuery } from "react-query";
import { addSpaceElementToSpace, getActiveMembersBySpace, getEmployeeList, getTaskDetailsByTaskId } from "../../taskTrackerAPIs/taskTrackerAPI";
import { Toastify, ToastifyError } from "../../../../App";

const CreateTaskForGroup = () => {
  const location = useLocation();
  const groupIdFromUrl = location.state?.spaceId;
  const { checkValidateAccess } = useValidationAccess();
  const currentDate = Moment().toDate();

  const [functionalArea, setFunctionalArea] = useState([]);
  const [peojectList, setProjectList] = useState([]);
  const [disable, setDisable] = useState(true);

  const CustomInput = React.forwardRef((props, ref) => {
    return (
      <div onClick={props.onClick} style={{height: "30px"}}>
        <label ref={ref}>{props.value || props.placeholder}</label>
        <img
          type="image/svg+xml"
          width="20px"
          style={{ position: "absolute", right: "5%", top: "28%", zIndex: "0" }}
          src={calendar}
          alt=""
        />
      </div>
    );
  });

  const { data:getTaskForEdit } = useQuery(
    ["getTaskByTaskId"],
    ()=>{if(location?.state?.task) return getTaskDetailsByTaskId(location?.state?.task?.taskId)},
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );
  

  const [employeeName, setEmployeeName] = useState(location?.state?.task?.assignedToName);
  const [relatedTo, setRelataedTo] = useState("");
  const [relatedToId, setRelataedToId] = useState(null);
  const [showRes, setshowRes] = useState(false);
  const [showErr, setShowErr] = useState(false);
  const [groupList, setGroupsList] = useState([]);
  const [employeeIds, setEmployeeIds] = useState(0);
  const [option, setOption] = useState();
  const [projectoptin, setProjectOption] = useState(null);
  const [funtionalAreaOpt, setFunctionalOption] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [validate, setValidate] = useState(false);
  const [groupId, setGroupid] = useState(location?.state?.spaceId);
  const spaceElementId = location?.state?.spaceElementId ? location?.state?.spaceElementId : (location?.state?.task?.spaceElement==="Task" || location?.state?.task?.spaceElement===null) ? 1 : location?.state?.task?.spaceElement==="Guideline" ? 2 : 3;
  const [state, setState] = useState({
    description: '',
    title: '',
  });
  const [startDate, setStartDate] = useState("");
  const history = useHistory();
  const FuncTionalAreaClear = () => {
    setFunctionalOption(null);
    setFunctionalArea([]);
    getFunctionalArea();
  };

  const {data : employeeList} = useQuery(
    ["getEmployeeList"],
    getEmployeeList,
    {
      refetchOnWindowFocus: false,
    }
  );
  const getemployee = employeeList?.map((i) => ({
    value: i.id,
    label: i.name,
  }));
  const getGroups = groupList?.map((i) => ({
    value: i.id,
    label: i.name,
  }));
  functionalArea?.sort((a, b) =>
    a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1
  );

  const {data : activeMember} = useQuery(
    ["getActiveMembersBySpace"],
    ()=>getActiveMembersBySpace(groupIdFromUrl),
    {
      refetchOnWindowFocus: false,
      enabled: spaceElementId===2,
    }
  );
  const activeMembers = activeMember?.map((i) => ({
    value: i.memberId,
    label: i.memberName,
  }));

  function handleChange(id) {
    setEmployeeIds(id);
  }

  function handleChange1(id) {
    setRelataedToId(id);
  }

  function handleOption(e) {
    e.preventDefault();
    setOption(e.target.value);
  }

  function handleOption1(e) {
    e.preventDefault();
    setProjectOption(e.target.value);
  }

  // function handleOption2(e) {
  //   e.preventDefault();

  //   if (e.target.value == 1) {
  //     setDisable(false);
  //     getProject();
  //   } else {
  //     // setDisable(true);
  //     setProjectList([]);
  //     setProjectOption(null);
  //   }
  //   setFunctionalOption(e.target.value);
  // }
  // const hideAlert = (data) => {
  //   setTimeout(() => {
  //     setshowRes(false);
  //     handleRedirect(data);
  //   }, 1000);
  // };

  const handleRedirect = () => {
    groupIdFromUrl
      ? history.push("/Groups")
      : history.push("/taskTracker/TaskAssignedByMeComponent");
  };

  const url = constants.apiBaseUrl;
  const url2 = constants.apiBaseUrl2;
  const baseUrl = constants.apiBaseUrlResource;

  const getFunctionalArea = () => {
    axios
      .get(url2 + "/getAllFunctionalArea", {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
          employeeId: employeeId,
        },
      })
      .then((res) => {
        setFunctionalArea(res.data.responseData);
      })
      .catch(() => { });
  };

  // const getProject = () => {
  //   axios
  //     .get(baseUrl + "/getAllActiveProject", {
  //       headers: {
  //         "Access-Control-Allow-Origin": "*",
  //         Authorization: ` ${cookie.load("token")}`,
  //         employeeId: employeeId,
  //       },
  //     })
  //     .then((res) => {
  //       setProjectList(res.data.responseData);
  //     })
  //     .catch(() => { });
  // };

  const addSpaceElementMutation = useMutation(addSpaceElementToSpace,{
    onSuccess: (res)=>{
      Toastify(res);
      let gName = getGroups.find(val=>val.value===groupId).label;
      history.goBack();
    },
    onError : ()=>{
      ToastifyError("Something Went Wrong!");
    }
  });

  function postData(e) {
    let data;
    if(spaceElementId===1){
        if (
            !employeeIds ||
            !startDate ||
            !option ||
            !state?.title
      
          ) {
            setValidate(true);
            return;
          }
          data = {
            assignedBy: employeeId,
            assignedTo: employeeIds,
            planedEndDate: new Date(startDate).toISOString(),
            priority: option,
            title: state.title.trim(),
            groupId: groupId,
            spaceElementId: spaceElementId,
          };
        
    } else if(spaceElementId===2){
        if (
            !employeeIds ||
            !state?.title
      
          ) {
            setValidate(true);
            return;
          }
          data = {
            assignedBy: employeeId,
            assignedTo: employeeIds,
            title: state.title.trim(),
            groupId: groupId,
            spaceElementId: spaceElementId,
          };

    } else {
      if (
        !state?.title
  
      ) {
        setValidate(true);
        return;
      }
      data = {
        assignedBy: employeeId,
        title: state.title.trim(),
        groupId: groupId,
        spaceElementId: spaceElementId,
      };
    }

    if (state.description) {
      data.description = state.description.trim();
    }
    
    setIsLoading(true);
    if(!addSpaceElementMutation.isLoading) addSpaceElementMutation.mutate(data);
    setIsLoading(false);
  }
  function postEditData(e) {
    let data;
    if(spaceElementId===1){
        if (
            !employeeIds ||
            !startDate ||
            !option ||
            !state?.title
      
          ) {
            setValidate(true);
            return;
          }
          data = {
            assignedBy: employeeId,
            assignedTo: employeeIds,
            planedEndDate: new Date(startDate).toISOString(),
            priority: option,
            title: state.title.trim(),
            groupId: groupId,
            spaceElementId: spaceElementId,
          };
        
    } else if(spaceElementId===2){
        if (
            !employeeIds ||
            !state?.title
      
          ) {
            setValidate(true);
            return;
          }
          data = {
            assignedBy: employeeId,
            assignedTo: employeeIds,
            title: state.title.trim(),
            groupId: groupId,
            spaceElementId: spaceElementId,
          };

    } else {
      if (
        !state?.title
  
      ) {
        setValidate(true);
        return;
      }
      data = {
        assignedBy: employeeId,
        title: state.title.trim(),
        groupId: groupId,
        spaceElementId: spaceElementId,
      };
    }

    if (state.description) {
      data.description = state.description.trim();
    }
    data.taskId = location?.state?.task?.taskId;
    setIsLoading(true);
    if(!addSpaceElementMutation.isLoading) addSpaceElementMutation.mutate(data);
    setIsLoading(false);
  }

  useEffect(() => {
    async function getGroupData() {
      await axios
        .get(url2 + `/groupListing`, {
          headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization: ` ${cookie.load("token")}`,
            employeeId: employeeId,
          },
        })
        .then((res) => {
          if(Array.isArray(res.data.responseData)) setGroupsList(res.data.responseData);
        })
        .catch((err) => {
          return err;
        });
    }
    getGroupData();
  }, []);

  function onChange(e) {
    const { name, value } = e.target;
    setState({ ...state, [name]: value });
  };

  useEffect(()=>{
    if(getTaskForEdit){
      setState({
        description: getTaskForEdit?.description,
        title: getTaskForEdit?.title,
      });
      setStartDate(getTaskForEdit?.plannedEndDate);
      setGroupid(getTaskForEdit?.groupId);
      setEmployeeIds(getemployee?.find(val=>val.label===getTaskForEdit?.assignedToName)?.value);
      setOption(getTaskForEdit?.priority);
    } else {
        setState({
          description: '',
          title: '',
        });
        setStartDate("");
        setEmployeeIds(0);
        setOption('');
    }
  },[getTaskForEdit, employeeList]);

  useEffect(() => {
    getFunctionalArea();
  }, []);
  return (
    <div>
      {/* <Header /> */}
      <div className={style1.mainSection}>
        <SideBar />
        <div className={style.rightSection}>
      {!location?.state?.task ? <div className={style.container}>
        <span>{spaceElementId === 1 ? 'Create Task' : spaceElementId === 2 ? 'Create Guideline' : 'Create Note'}</span>
        {showRes && (
          <div className={style.alert} id="alert_msg">
            Task added successfully!
          </div>
        )}
        <div className={style.createTask}>
          <div id="create_task_id" className={style.gridContainer}>
            <div>
              <div style={{ width: '95.5%' }}>
                <label className={style.font15}>
                  {!spaceElementId ? 'Task Title' : spaceElementId===1 ? 'Task Title' : spaceElementId===2 ? 'Guideline Title' : 'Note Title'}<span style={{ color: "red" }}>*</span> <br></br>
                  <input
                    className={style.input}
                    autoComplete="off"
                    type="input"
                    id="tast_title"
                    name="title"
                    value={state.title}
                    style={{ marginTop: "10px" }}
                    onChange={onChange}
                  />
                  <p
                    className={
                      validate && !state?.title
                        ? style.errorText
                        : style.errorTextHidden
                    }
                  >
                    {!spaceElementId ? 'Please Enter Task Title' : spaceElementId===1 ? 'Please Enter Task Title' : spaceElementId===2 ? 'Please Enter Guideline Title' : 'Please Enter Note Title'}
                  </p>
                </label>
              </div>
              <div>
                <label className={style.descLabel}>
                  {spaceElementId===1 ? 'Task Description' : spaceElementId===2 ? 'Guideline Description' : 'Note Description'}
                  <textarea
                    className={style.text}
                    style={{ color: "black" }}
                    name="description"
                    value={state.description}
                    onChange={onChange}
                    id="task_description"
                  />
                </label>
              </div>
              {spaceElementId!==3 && <div style={{ display: 'flex' }}>
                <div style={{ width: '33%' }}>
                  <label className={style.font15}>
                    Assigned To
                    <span style={{ color: "red" }}>*</span>
                    <Select
                      styles={customStylesForSelectTaskTracker}
                      placeholder="Search By Client Name"
                      components={{
                        DropdownIndicator: () => null,
                        IndicatorSeparator: () => null,
                      }}
                      onChange={(e) => {
                        setEmployeeName(e.label);
                        handleChange(e.value);
                      }}
                      options={spaceElementId===2 ? activeMembers : getemployee}
                    />
                    <p
                      className={
                        validate && !employeeIds
                          ? style.errorText
                          : style.errorTextHidden
                      }
                    >
                      Please Select Assigned To
                    </p>
                  </label>
                </div>
                {spaceElementId!==2 && <div style={{ width: '33%' }}>
                  <label className={style.font15}>
                    Priority<span style={{ color: "red" }}>*</span> <br />
                    <select onChange={(e) => handleOption(e)} style={{marginTop: "5px"}}>
                      <option value="" disabled="" hidden selected="">
                        ---select---
                      </option>
                      <option value="High">High</option>
                      <option value="Medium">Medium</option>
                      <option value="Low">Low</option>
                    </select>
                    <p
                      className={
                        validate && !option
                          ? style.errorText
                          : style.errorTextHidden
                      }
                    >
                      Please Select Priority
                    </p>
                  </label>
                </div>}
                {spaceElementId!==2 && <div style={{ width: '33%' }}>
                  <label className={style.font15}>
                    Planned End Date<span style={{ color: "red" }}>*</span>
                  </label>
                  <div className={style.input}>
                    <DatePicker
                      dateFormat="dd MMM yy"
                      minDate={new Date()}
                      value={startDate ? Moment(startDate)?.format("DD MMM YYYY"): null}
                      startDate={null}
                      onChange={(date) => {let temp = Moment(date)?.add(12, 'hours'); setStartDate(temp)}}
                      customInput={<CustomInput />}
                    />
                  </div>
                  <p
                    className={
                      validate && !startDate
                        ? style.errorText
                        : style.errorTextHidden
                    }
                  >
                    Please Select Planned End Date
                  </p>
                </div>}
                {spaceElementId===2 && <div style={{ width: '33%' }}>
                  <label className={style.font15}>
                    Space
                    <div style={{ marginTop: "10px" }}>
                      <Select
                        styles={customStylesForTaskTracker2}
                        placeholder="Search By Client Name"
                        components={{
                          DropdownIndicator: () => null,
                          IndicatorSeparator: () => null,
                        }}
                        value={getGroups?.find(
                          (i) => i.value === groupId
                        )}
                        isDisabled={!!groupIdFromUrl}
                        onChange={(e) => {
                          setDisable(false)
                          setGroupid(e.value);
                        }}
                        options={getGroups}
                      />
                    </div>
                  </label>
                </div>}
              </div>}
              {spaceElementId!==2 && <div style={{ display: 'flex' }}>
                <div style={{ width: '33%' }}>
                  <label className={style.font15}>
                    Space
                    <div style={{ marginTop: "10px" }}>
                      <Select
                        styles={customStylesForTaskTracker2}
                        placeholder="Search By Client Name"
                        components={{
                          DropdownIndicator: () => null,
                          IndicatorSeparator: () => null,
                        }}
                        value={getGroups?.find(
                          (i) => i.value === groupId
                        )}
                        isDisabled={!!groupIdFromUrl}
                        onChange={(e) => {
                          setDisable(false)
                          setGroupid(e.value);
                        }}
                        options={getGroups}
                      />
                    </div>
                  </label>
                </div>
                
              </div>}
            </div>
          </div>
        </div>
        {checkValidateAccess(taskTrackerAccessCode?.submit_CreateTask) && (<div style={{display: "flex", justifyContent: "flex-end", gap: "10px"}}>
          <button className={style.cancelButton} onClick={()=>history.goBack()}>Cancel</button>
          
            <button
              className={style.submitButton}
              onClick={postData}
              disabled={isLoading}
            >
              Submit
            </button>
          
        </div>)}
      </div> : 
      <div className={style.container}>
      <span>Edit {!spaceElementId ? 'Task' : spaceElementId===1 ? 'Task' : spaceElementId===2 ? 'Guideline' : 'Note'}</span>
      {showRes && (
        <div className={style.alert} id="alert_msg">
          Task added successfully!
        </div>
      )}
      <div className={style.createTask}>
        <div id="create_task_id" className={style.gridContainer}>
          <div>
            <div style={{ width: '95.5%' }}>
              <label className={style.font15}>
                {!spaceElementId ? 'Task' : spaceElementId===1 ? 'Task' : spaceElementId===2 ? 'Guideline' : 'Note'} Title <span style={{ color: "red" }}>*</span> <br></br>
                <input
                  className={style.input}
                  autocomplete="off"
                  type="input"
                  id="tast_title"
                  name="title"
                  value={state.title}
                  style={{ marginTop: "10px" }}
                  onChange={onChange}
                />
                <p
                  className={
                    validate && !state?.title
                      ? style.errorText
                      : style.errorTextHidden
                  }
                >
                  {!spaceElementId ? 'Please Enter Task Title' : spaceElementId===1 ? 'Please Enter Task Title' : spaceElementId===2 ? 'Please Enter Guideline Title' : 'Please Enter Note Title'}
                </p>
              </label>
            </div>
            <div>
              <label className={style.descLabel}>
                {!spaceElementId ? 'Task Descroption' : spaceElementId===1 ? 'Task Description' : spaceElementId===2 ? 'Guideline Description' : 'Note Description'}
                <textarea
                  className={style.text}
                  style={{ color: "black" }}
                  name="description"
                  value={state.description}
                  onChange={onChange}
                  id="task_description"
                />
              </label>
            </div>
            {spaceElementId!==3 && <div style={{ display: 'flex' }}>
              <div style={{ width: '33%' }}>
                <label className={style.font15}>
                  Assigned To
                  <span style={{ color: "red" }}>*</span>
                  <Select
                    styles={customStylesForSelectTaskTracker}
                    placeholder="Search By Client Name"
                    components={{
                      DropdownIndicator: () => null,
                      IndicatorSeparator: () => null,
                    }}
                    onChange={(e) => {
                      setEmployeeName(e.label);
                      handleChange(e.value);
                    }}
                    options={spaceElementId===2 ? activeMembers : getemployee}
                    value={getemployee?.find(val=>val.label===employeeName)}
                  />
                  <p
                    className={
                      validate && !employeeIds
                        ? style.errorText
                        : style.errorTextHidden
                    }
                  >
                    Please Select Assigned To
                  </p>
                </label>
              </div>
              {spaceElementId!==2 && <div style={{ width: '33%' }}>
                <label className={style.font15}>
                  Priority<span style={{ color: "red" }}>*</span> <br />
                  <select onChange={(e) => handleOption(e)} style={{marginTop: "5px"}} value={option}>
                    <option value="" disabled="" hidden selected="">
                      ---select---
                    </option>
                    <option value="High">High</option>
                    <option value="Medium">Medium</option>
                    <option value="Low">Low</option>
                  </select>
                  <p
                    className={
                      validate && !option
                        ? style.errorText
                        : style.errorTextHidden
                    }
                  >
                    Please Select Priority
                  </p>
                </label>
              </div>}
              {spaceElementId!==2 && <div style={{ width: '33%' }}>
                <label className={style.font15}>
                  Planned End Date<span style={{ color: "red" }}>*</span>
                </label>
                <div className={style.input}>
                  <DatePicker
                    dateFormat="dd MMM yy"
                    minDate={new Date()}
                    value={startDate ? Moment(startDate).format("DD MMM YYYY") : null}
                    startDate={null}
                    onChange={(date) => {let temp = Moment(date)?.add(12, 'hours'); setStartDate(temp)}}
                    customInput={<CustomInput />}
                    // value={startDate}
                  />
                </div>
                <p
                  className={
                    validate && !startDate
                      ? style.errorText
                      : style.errorTextHidden
                  }
                >
                  Please Select Planned End Date
                </p>
              </div>}
              {spaceElementId===2 && <div style={{ width: '33%' }}>
                <label className={style.font15}>
                  Space
                  <div style={{ marginTop: "10px" }}>
                    <Select
                      styles={customStylesForTaskTracker2}
                      placeholder="Search By Client Name"
                      components={{
                        DropdownIndicator: () => null,
                        IndicatorSeparator: () => null,
                      }}
                      value={getGroups?.find(
                        (i) => i.value === groupId
                      )}
                      isDisabled={!!groupIdFromUrl}
                      onChange={(e) => {
                        setDisable(false)
                        setGroupid(e.value);
                      }}
                      options={getGroups}
                    />
                  </div>
                </label>
              </div>}
            </div>}
            {spaceElementId!==2 && <div style={{ display: 'flex' }}>
              <div style={{ width: '33%' }}>
                <label className={style.font15}>
                  Space
                  <div style={{ marginTop: "10px" }}>
                    <Select
                      styles={customStylesForTaskTracker2}
                      placeholder="Search By Client Name"
                      components={{
                        DropdownIndicator: () => null,
                        IndicatorSeparator: () => null,
                      }}
                      value={getGroups?.find(
                        (i) => i.value === groupId
                      )}
                      isDisabled={!!groupIdFromUrl}
                      onChange={(e) => {
                        setDisable(false)
                        setGroupid(e.value);
                      }}
                      options={getGroups}
                    />
                  </div>
                </label>
              </div>
              
            </div>}
          </div>
        </div>
      </div>
      {checkValidateAccess(taskTrackerAccessCode?.submit_CreateTask) && (<div style={{display: "flex", justifyContent: "flex-end", gap: "10px"}}>
        <button className={style.cancelButton} onClick={()=>history.goBack()}>Cancel</button>
        
          <button
            className={style.submitButton}
            onClick={postEditData}
            disabled={isLoading}
          >
            Submit
          </button>
        
      </div>)}
    </div>
      }
    </div>
      </div>
    </div>
    
  );
};

export default CreateTaskForGroup;
