import BreadCrum from "../../../breadcrums/BreadCrum";
import ATSContainer from "../../../customTooltip/ATSContainer";
import styled from "./inActivePositionReport.module.scss";
import {
  getClientsSalesOwnerList,
  getDashboardForInActiveReport,
  getHoldsPostionsAccountManagementInactiveReport,
  getInActivePositionReport,
} from "../../../../../Utils/axios/api";
import { useQuery } from "react-query";
import moment from "moment";
import {
  handleAddComment,
  getCommentForPosition,
} from "../../../commonComponent/CommentCommon";
import { useState } from "react";
import { CgNotes } from "react-icons/cg";
import ConfirmPopup from "../../../../userManegment/pageLayout/viewUser/ConfirmPopup";
import ATSButton from "../../../customTooltip/ATSButton";
import TotalCount from "../../../TotalCount/TotalCount";
import Pagination from "../../../../Projects_and_Resource_Master/CommonComponent/Pagination";
import FilterSearchSelect from "../../../commonComponent/filterSearch/FilterSearchSelect";
import searchIcon from "../../../../../assets/Icon-search.png";
import { AiOutlineClose } from "react-icons/ai";
import Select from "react-select";
import { customSearch } from "../../../utils/style";
// import { Modal } from "react-responsive-modal";
import CommentHistory from "../../../commonComponent/CommentNew/CommentHistory/CommentHistory";
import { MdOutlineInsertComment } from "react-icons/md";
import { lazyLoad } from "../../../../../GenericComponent/lazyLoad/LazyLoadCommon";

const InActivePositionReport = ({ prevData, setPrevData, setNextScreen }) => {
  const Modal = lazyLoad(() => import("../../../commonComponent/Modal/Modal"));
  const [isModalOpen1, setModalOpen1] = useState(false);
  const openModal1 = () => setModalOpen1(true);
  const closeModal1 = () => setModalOpen1(false);
  const [showCommentModal, setShowCommentModal] = useState(false);
  const [myPositionId, setMyPositionId] = useState(null);
  const [myPositionName, setMyPositionName] = useState(null);
  const [positionCodee, setPositionCodee] = useState("");
  const [myClientName, setMyClientName] = useState(null);
  const [myCandidateId, setMyCandidateId] = useState(null);
  const [notes, setNotes] = useState("");
  const [show, setShow] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [filter, setFilter] = useState({
    statusId: "",
    reportType: "",
  });
  const [filter1, setFilter1] = useState({
    salesOwner: "",
  });
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [inActiveUsers, setInActiveUsers] = useState([]);
  const [inActivePosition, setInActivePosition] = useState({
    salesOwner: null,
    statusId: null,
  });

  const [isWeek, setIsWeek] = useState(false)

  const currentDate = new Date();

  // Function to get the start date of a week (Monday)
  function getStartOfWeek(date) {
    const day = date.getDay();
    const diff = date.getDate() - day + (day === 0 ? -6 : 1); // Adjust when day is Sunday
    return new Date(date.setDate(diff));
  }

  // Function to format the date as 'dd'
  function formatDate(date) {
    return date.getDate();
  }

  // Function to format the month as 'long'
  function formatMonth(date) {
    return date.toLocaleString("en-us", { month: "long" });
  }

  // Get the current month and year
  const currentMonth = currentDate.getMonth(); // Adding 1 since month index starts from 0
  const currentYear = currentDate.getFullYear();

  // Create array of months from January to the current month
  const months = Array.from({ length: currentMonth }, (_, index) => {
    const monthNumber = index + 1; // Adding 1 since month index starts from 0
    const monthName = new Date(currentYear, monthNumber - 1).toLocaleString(
      "en-us",
      { month: "long" }
    );
    const startDate = new Date(currentYear, monthNumber - 1, 1);
    return {
      value:
        moment(startDate)
          .format("YYYY-MM-DDTHH:mm:ss")
          .toString()
          .split("T")[0] + "T00:00:00.000Z",
      label: monthName,
      isWeek : false
    };
  }).reverse();

  // Get the start date of the current week
  let currentWeekStart = getStartOfWeek(new Date(currentDate));

  // Create array of recent 4 weeks
  const weeks = Array.from({ length: 4 }, (_, index) => {
    const weekStartDate = new Date(currentWeekStart);
    weekStartDate.setDate(currentWeekStart.getDate() - index * 7);

    const weekEndDate = new Date(weekStartDate);
    weekEndDate.setDate(weekStartDate.getDate() + 6); // End date of the week

    const weekStartString =
      moment(weekStartDate)
        .format("YYYY-MM-DDTHH:mm:ss")
        .toString()
        .split("T")[0] + "T00:00:00.000Z";
    const weekLabel = `${formatDate(weekStartDate)} - ${formatDate(
      weekEndDate
    )} ${formatMonth(weekStartDate)}`;

    return { value: weekStartString, label: weekLabel, isWeek : true };
  }); 

  // Combine months and weeks into a single array
  const options = [...weeks, ...months];

  const handleMonthChange = (selectedOption) => {
    setSelectedMonth(selectedOption.value);
    setIsWeek(selectedOption?.isWeek)
  };

  const handleIsActivePosition = (salesOwnerId, statusId) => {
    setInActivePosition({
      salesOwner: salesOwnerId,
      statusId: statusId,
    });
  };

  const handleCancelMonth = () => {
    setInActivePosition({
      salesOwner: null,
      statusId: null,
    });
    setSelectedMonth(null);
    setIsWeek(false)
  };

  const handlePageChange = (page, status) => {
    if (status === "next") {
      setCurrentPage(page + 1);
    } else if (status === "prev") {
      setCurrentPage(page - 1);
    }
  };
  // const { data, isLoading, isError } = useQuery(["getHoldsPostionsAccountManagementInactiveReport", filter, currentPage],
  //   () => getHoldsPostionsAccountManagementInactiveReport(10, currentPage, filter.statusId, filter.reportType))

  const {
    data: inActiveData,
    isLoading: isLoadingDashboard,
    isError: isErrorDashboard,
  } = useQuery(
    ["getDashboardForInActiveReport", filter1, currentPage, selectedMonth],
    () =>
      getDashboardForInActiveReport(
        10,
        currentPage,
        filter1.salesOwner,
        selectedMonth,
        isWeek === true || isWeek === false ? !isWeek : null
      )
  );

  const {
    data,
    isLoading: isLoadingActivePosition,
    isError: isErrorActivePosition,
  } = useQuery(
    [
      "getInActivePositionReport",
      inActivePosition.salesOwner,
      inActivePosition.statusId,
    ],
    () =>
      getInActivePositionReport(
        inActivePosition.salesOwner,
        inActivePosition.statusId,
        selectedMonth,
        isWeek === true || isWeek === false ? !isWeek : null
      ),
    {
      enabled:
        inActivePosition.salesOwner !== null &&
        inActivePosition.statusId !== null,
    }
  );

  const { data: getSalesOwnerList } = useQuery(
    ["getClientsSalesOwnerList"],
    () => getClientsSalesOwnerList(),
    {
      refetchOnWindowFocus: false,
    }
  );
  const salesOwnerListOption =
    getSalesOwnerList?.length > 0
      ? getSalesOwnerList?.map((e) => ({
          value: e.id,
          label: e.name,
        }))
      : [];
  const inActivePositionData = inActiveData?.inActivePosition;
  const inActivePositionCount = inActiveData?.count;
  const statusIdOption = [
    { label: "Hold by Us", value: 1 },
    { label: "Hold by Client", value: 2 },
    { label: "Closed by Us", value: 3 },
    { label: "Closed by Client", value: 4 },
  ];
  return (
    <>
      <BreadCrum
        prevData={prevData}
        setPrevData={setPrevData}
        setNextScreen={setNextScreen}
        // PlusComponent={<TotalCount totalCount={data?.positionCount} />}
      ></BreadCrum>
      <div className={styled.filterContainer}>
        <p className={styled.mainHeading}>
          Dashboard{" "}
          <span className={styled.countData}>{inActiveData?.count}</span>
        </p>
        <div className={styled.s2}>
          {selectedMonth === null ? (
            <Select
              value={null}
              onChange={handleMonthChange}
              options={options}
              placeholder="Choose Duration"
            />
          ) : (
            <Select
              value={options.find((month) => month.value === selectedMonth)}
              onChange={handleMonthChange}
              components={{
                IndicatorSeparator: () => null,
                DropdownIndicator: () => (
                  <div>
                    {selectedMonth && (
                      <AiOutlineClose
                        style={{ marginRight: "10px" }}
                        // onClick={() => setSelectedMonth(null)}
                        onClick={() => handleCancelMonth()}
                      />
                    )}
                  </div>
                ),
              }}
              options={options}
              placeholder="Choose Duration"
            />
          )}
        </div>

        <div className={styled.s1}>
          {filter1?.salesOwner === "" ? (
            <span className={styled.cp_serchIcon}>
              <img src={searchIcon} alt="searchIcon" draggable="false"/>
            </span>
          ) : (
            <span
              className={styled.cp_serchIcon}
              onClick={() => {
                setFilter1({
                  ...filter1,
                  salesOwner: "",
                });
              }}
            >
              <AiOutlineClose />
            </span>
          )}
          <Select
            styles={customSearch}
            classNamePrefix={"create_position"}
            placeholder="Position Owner Name"
            components={{
              DropdownIndicator: () => null,
              IndicatorSeparator: () => null,
            }}
            value={
              filter1?.salesOwner
                ? salesOwnerListOption?.find(
                    (e) => e?.label === filter1?.salesOwner
                  )
                : ""
            }
            onChange={(e) =>
              setFilter1((item) => ({ ...item, salesOwner: e?.value }))
            }
            options={salesOwnerListOption}
          />
        </div>
      </div>
      <ATSContainer
        styles={{
          overflow: "auto",
          marginBottom: "40px",
          borderRadius: "10px",
        }}
      >
        <div className={styled.th1}>
          <p>Position Owner Name</p>
          <p>Hold By Us</p>
          <p>Hold By Client</p>
          <p>Closed By Us</p>
          <p>Closed By Client</p>
        </div>
        {isLoadingDashboard && <div className={styled.noData}>Loading...</div>}
        {isErrorDashboard && (
          <div className={styled.noData}>Something went wrong</div>
        )}
        {!isErrorDashboard && !isLoadingDashboard ? (
          inActivePositionData?.length > 0 ? (
            inActivePositionData?.map((e) => {
              return (
                <div className={styled.td1}>
                  <p>{e?.name}</p>
                  <p
                    style={{ cursor: "pointer", textDecoration: "underline" }}
                    onClick={() =>
                      handleIsActivePosition(e?.id, statusIdOption[0].value)
                    }
                  >
                    {e?.holdByUsCount > 0 ? e?.holdByUsCount : ""}
                  </p>
                  <p
                    style={{ cursor: "pointer", textDecoration: "underline" }}
                    onClick={() =>
                      handleIsActivePosition(e?.id, statusIdOption[1].value)
                    }
                  >
                    {e?.holdByClientCount > 0 ? e?.holdByClientCount : ""}
                  </p>
                  <p
                    style={{ cursor: "pointer", textDecoration: "underline" }}
                    onClick={() =>
                      handleIsActivePosition(e?.id, statusIdOption[2].value)
                    }
                  >
                    {e?.closedByUsCount > 0 ? e?.closedByUsCount : ""}
                  </p>
                  <p
                    style={{ cursor: "pointer", textDecoration: "underline" }}
                    onClick={() =>
                      handleIsActivePosition(e?.id, statusIdOption[3].value)
                    }
                  >
                    {e?.closedByClientCount > 0 ? e?.closedByClientCount : ""}
                  </p>
                </div>
              );
            })
          ) : (
            <div className={styled.noData}>No Data</div>
          )
        ) : null}
      </ATSContainer>
      <div className={styled.pagination}>
        <Pagination
          onPageChange={handlePageChange}
          currentPage={currentPage}
          pagesCount={inActivePositionCount / 10}
          color="#65A15E"
          color2="white"
        />
      </div>
      {/* <div className={styled.filterContainer}>
      <p className={styled.mainHeading}>In-Active Positions Report <span className={styled.countData}>{data?.positionCount}</span></p>
      <div className={styled.dropdowns}>
        <FilterSearchSelect
          placeholder=""
          options={[
            { value: 1, label: "Daywise" },
            { value: 2, label: "Weekwise" },
            { value: 3, label: "Monthwise" },
            { value: 4, label: "Yearwise" }
          ]}
          value={filter?.reportType}
          onChange={(e) =>
            setFilter((prev) => ({ ...prev, reportType: e?.value }))
          }
          onClose={() => setFilter((prev) => ({ ...prev, reportType: "" }))}
        />
        <FilterSearchSelect
          placeholder="Status"
          options={statusIdOption}
          value={filter?.statusId}
          onChange={(e) =>
            setFilter((prev) => ({ ...prev, statusId: e?.value }))
          }
          onClose={() => setFilter((prev) => ({ ...prev, statusId: "" }))}
        />
      </div>
    </div> */}

      {inActivePosition.salesOwner === null &&
      inActivePosition.statusId === null ? (
        <div></div>
      ) : (
        <ATSContainer styles={{ overflow: "auto" }}>
          <div className={styled.th}>
            <p>Position Code</p>
            <p>Core Skill</p>
            <p></p>
            <p>Position Type</p>
            <p>Client Name</p>
            <p>Created By</p>
            <p>Position Owner</p>
            <p>Opened On</p>
            <p>Status</p>
            <p>Hold Date</p>
            <p>
              Number of Profile <br />
              Shared
            </p>
            <p>Notes</p>
          </div>
          {isLoadingActivePosition && (
            <div className={styled.noData}>Loading...</div>
          )}
          {isErrorActivePosition && (
            <div className={styled.noData}>Something went wrong</div>
          )}
          {!isErrorActivePosition &&
          !isLoadingActivePosition &&
          data?.length > 0 ? (
            data.map((e) => (
              <div className={styled.td} key={e.id}>
                {" "}
                {/* Added key prop */}
                <p
                  onClick={() => {
                    setNextScreen("viewPosition");
                    setPrevData((prevData) => ({
                      ...prevData,
                      positionId: e.id,
                      page1: "reportNew",
                      page2: "InActivePositionsReport",
                      page3: "viewPosition",
                      page4: null,
                      active: "viewPosition",
                    }));
                  }}
                >
                  {e.positioncode}
                </p>
                <p>{e.skillName}</p>
                <p>
                  <MdOutlineInsertComment
                    style={{
                      cursor: "pointer",
                      height: "13.5px",
                      marginLeft: "1px",
                      color: "black",
                    }}
                    onClick={() => {
                      openModal1();
                      setShowCommentModal(true);
                      setMyPositionId(e?.id);
                      setMyPositionName(e?.skillName);
                      setMyClientName(e?.clientName);
                      setPositionCodee(e?.positioncode)
                    }}
                    title={" Add Comment For Position"}
                  />
                </p>
                <p>{e.positionType}</p>
                <p>{e.clientName}</p>
                <p>{e.createdBy}</p>
                <p>{e.positionOwner}</p>
                <p>
                  {e.positionOpenDate
                    ? moment(e.positionOpenDate).format("DD MMM YY")
                    : null}
                </p>
                <p>{e.status}</p>
                <p>
                  {e.markAsHoldDate
                    ? moment(e.markAsHoldDate).format("DD MMM YY")
                    : null}
                </p>
                <p>{e.numberOfprofileShared > 0 && e.numberOfprofileShared}</p>
                <p
                  onClick={() => {
                    setNotes(e.note);
                    setShow(true);
                  }}
                >
                  {e.note && (
                    <CgNotes style={{ color: "green", fontSize: "1.3rem" }} />
                  )}
                </p>
              </div>
            ))
          ) : (
            <div className={styled.noData}>No Data</div>
          )}
        </ATSContainer>
      )}

      <ConfirmPopup open={show}>
        <div className={styled.containerNotes}>
          <div className={styled.content}>
            {notes?.split("\n").map((e) => (
              <p>{e}</p>
            ))}
          </div>
          <div className={styled.btnNotes}>
            <ATSButton onClick={() => setShow(false)}>Done</ATSButton>
          </div>
        </div>
      </ConfirmPopup>
      <Modal isOpen={isModalOpen1} onClose={closeModal1}>
        <div className={styled.modal_container_watchlist2}>
          <div className={styled.watchlist_inner_container}>
            <CommentHistory
              candidateId={myCandidateId}
              closeModal={closeModal1}
              setShowCommentModal={setShowCommentModal}
              positionId={myPositionId}
              positionName={myPositionName}
              myClientName={myClientName}
              handleAddComment={handleAddComment}
              getCommentForPosition={getCommentForPosition}
              positionCodee={positionCodee}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};
export default InActivePositionReport;
