import React, { useEffect, useState } from "react";
import CreatableSelect from "react-select/creatable";
import { customStyles } from "../../utils/style";
import style from "./OptionOrdering.module.css";
import { useMutation } from "react-query";
import {
  addSkill,
  specialization,
} from "../../../../Utils/axios/PortalManagement";
import moment from "moment";

function OptionOrdering(props) {
  const [index, setIndex] = useState(-1);
  useEffect(() => {}, [props.formData.mandatorySkill_id]);

  const handleDown = (arr) => {
    if (index == -1) {
      return;
    }
    if (index > arr.length - 2) return;
    let index2 = index + 1;

    [arr[index], arr[index2]] = [arr[index2], arr[index]];
    props.setFormData({
      ...props.formData,
      mandatorySkill_id: arr,
    });
    setIndex((prevState) => prevState + 1);
  };
  const handleUP = (arr) => {
    if (index < 1) return;
    let index2 = index - 1;
    [arr[index], arr[index2]] = [arr[index2], arr[index]];
    props.setFormData({
      ...props.formData,
      mandatorySkill_id: arr,
    });
    setIndex((prevState) => prevState - 1);
  };

  const mutation = useMutation(addSkill, {
    onSuccess: (res) => {
      let selected = props.formData?.mandatorySkill_id;
      selected.pop();

      selected = [...selected, { value: res?.id, label: res?.skillName }];
      let items = {};
      selected?.forEach((element) => {
        if (!items[element?.value]) {
          items[element?.value] = element;
        }
      });
      let newData = Object.values(items);
      // const newSelect = selected?.map((item) => {
      //   if (item?.label == res?.mandatorySkillName) {
      //     return { value: res?.id, label: res?.mandatorySkillName };
      //   } else {
      //     return item;
      //   }
      // });

      props.setFormData({
        ...props.formData,
        mandatorySkill_id: newData,
      });
    },
  });

  const isSameUser = (a, b) => a?.value === b?.value && a?.label === b?.label;
  const handleCross = (left, right, compareFunction) => {
    if (!left) {
      return;
    }
    return left.filter(
      (leftValue) =>
        !right.some((rightValue) => compareFunction(leftValue, rightValue))
    );
  };
  const handleSelect = (e) => {
    props.setFormData({
      ...props.formData,
      mandatorySkill_id: e,
    });
    let prevSelect = handleCross(
      props.formData?.mandatorySkill_id,
      e,
      isSameUser
    );

    if (prevSelect?.length > 0) {
      return;
    }
    if (
      props.getMandatorySkillOption?.some(
        (item) => item?.label == e[e.length - 1]?.label
      )
    ) {
    } else {
      if (e?.length > 0) {
        setTimeout(() => {
          mutation.mutate({
            skill: e[e.length - 1]?.label,
          });
        }, 1000);
      }
    }
  };

  const currentDate = moment().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
  let formSpecial = props?.formData?.mandatorySkill_id?.map((e) => {
    return {
      specialization: e?.label,
      specializationId: e?.value,
      date: currentDate,
    };
  });

  const mutation1 = useMutation(specialization, {
    onSuccess: () => {
      props.refetchViewPartner();
    },
  });

  const specializationData = {
    // date:currentDate,
    partnerId: props?.prevDataId,
    specializationRequestlist: formSpecial,
  };

  const handlerOnbording = () => {
    mutation1.mutate(specializationData);
  };

  return (
    <>
      <h6 style={{ paddingLeft: "9px", paddingTop: "6px" }}>Specialization</h6>
      <div
        style={{
          width: "45rem",
          // height: "70vh",
          display: "flex",
          justifyContent: "space-between",
          overflow: "hidden",
          // minHeight: "13rem",
        }}
      >
        <div style={{ width: "21rem" }}>
          <CreatableSelect
            styles={customStyles}
            classNamePrefix={"create_position"}
            isMulti
            components={{
              DropdownIndicator: () => null,
              IndicatorSeparator: () => null,
            }}
            // onChange={(e) =>
            //   props.setFormData({
            //     ...props.formData,
            //     mandatorySkill_id: e,
            //   })
            // }
            closeMenuOnSelect={false}
            onChange={(e) => handleSelect(e)}
            value={props?.formData?.mandatorySkill_id}
            options={props.getMandatorySkillOption}
            formatCreateLabel={(userInput) => `+ Add "${userInput}"`}
          />
        </div>

        <div
          style={{
            width: "22rem",
            margin: "0 auto",
            borderLeft: "1px solid black",
            paddingLeft: "1rem",
            minHeight: "15rem",
            overflow: "hidden",
          }}
        >
          <div style={{ minHeight: "78%" }}>
            {props?.formData?.mandatorySkill_id?.length > 0 &&
              props?.formData?.mandatorySkill_id?.map((item, key) => {
                return (
                  <div key={key} className={style.catainer}>
                    <span
                      onClick={() => setIndex(key)}
                      className={style.circle}
                      style={
                        index === key
                          ? {
                              width: "1rem",
                              height: "1rem",
                              borderRadius: "50%",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              border: "1px solid #65a15e",
                              MozBorderRadius: "50%",
                              WebkitBorderRadius: "50%",
                              background: "rgb(96, 228, 96)",
                              display: "inline-block",
                            }
                          : {
                              width: "1rem",
                              height: "1rem",
                              borderRadius: "50%",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              border: "1px solid #65a15e",
                              MozBorderRadius: "50%",
                              WebkitBorderRadius: "50%",
                              display: "inline-block",
                            }
                      }
                    ></span>

                    <span style={{ width: "17rem" }}>{item?.label}</span>
                  </div>
                );
              })}
          </div>
          {props?.formData?.mandatorySkill_id?.length > 0 && (
            <div
              style={{
                display: "flex",
                width: "inherit",
                // justifyContent: "space-between",
                height: "2rem",
                float: "right",
              }}
            >
              <div
                style={{
                  marginLeft: "8%",
                  display: "flex",
                  //   justifyContent: "space-between",
                  width: "10rem",
                }}
              >
                <button
                  className={style.b_inactive}
                  onClick={() => handleUP(props?.formData?.mandatorySkill_id)}
                >
                  Up
                </button>
                <button
                  className={style.b_inactive}
                  onClick={() => handleDown(props?.formData?.mandatorySkill_id)}
                >
                  Down
                </button>
              </div>
              <button
                className={style.b_inactive}
                // onClick={() =>
                //    props.HandleSpecial(),
                //    handlerOnbording
                //   }
                onClick={() => {
                  props.HandleSpecial();
                  handlerOnbording();
                  props.setSpecialDetail(false)
                }}
              >
                Done
              </button>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default OptionOrdering;
