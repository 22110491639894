export const customStyles = {

  control: (style, state) => ({
    ...style,
    boxShadow: "0px 3px 9px #00000029",
    borderRadius: "9px",
    background: state.isDisabled ? "#999" : "f5f6fa",
    backgroundColor:
      // change color when disabled
      state.isDisabled ? "#999" : "#f5f6fa",
    opacity: state.isDisabled ? "0.2" : "1",
    border: "none",
    // minWidth: "inherit",
    margin: "4px 1.5% 0px 0%",
    height: "2.3rem",
    outline: "none",
    width: "100%",

    cursor: "pointer",
  }),

  placeholder: () => ({
    display: "none",
  }),
  clearIndicator: (base) => ({
    ...base,
    display: "none",
  }),
  option: (provided, state) => ({
    ...provided,
    "&:hover": {
      backgroundColor: "rgb(252, 187, 8)",
    },
    backgroundColor: state.isSelected ? "rgb(252, 187, 8)" : "white",
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: "999",
  }),
};

export const customStylesForPlatform = {

  control: (style, state) => ({
    ...style,
    boxShadow: "0px 3px 9px #00000029",
    borderRadius: "9px",
    background: state.isDisabled ? "#f5f6fa" : "f5f6fa",
    backgroundColor:
      // change color when disabled
      state.isDisabled ? "#f5f6fa" : "#f5f6fa",
    opacity: state.isDisabled ? "1" : "1",
    border: "none",
    // minWidth: "inherit",
    margin: "4px 1.5% 0px 0%",
    height: "2.3rem",
    outline: "none",
    width: "100%",

    cursor: "pointer",
  }),

  placeholder: () => ({
    display: "none",
  }),
  clearIndicator: (base) => ({
    ...base,
    display: "none",
  }),
  option: (provided, state) => ({
    ...provided,
    "&:hover": {
      backgroundColor: "rgb(252, 187, 8)",
    },
    backgroundColor: state.isSelected ? "rgb(252, 187, 8)" : "white",
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: "999",
  }),
};

export const customStylesForTaskTracker = {
  '.css-1i9fq18-ValueContainer': {
    display: 'none',
  },
  menu: (style) => ({
    ...style,
    width: 100,
  }),

  control: (style, state) => ({

    ...style,
    boxShadow: "0px 3px 9px #00000029",
    borderRadius: "9px",
    background: state.isDisabled ? "#f5f6fa" : "f5f6fa",
    backgroundColor:
      // change color when disabled
      state.isDisabled ? "#f5f6fa" : "#f5f6fa",
    opacity: state.isDisabled ? "1" : "1",
    border: "none",
    // minWidth: "inherit",
    margin: "5px 1.5% 0px 0%",
    height: "2.3rem",
    outline: "none",
    width: "395px",
    cursor: "pointer",

  }),

  placeholder: () => ({
    display: "none",
  }),
  clearIndicator: (base) => ({
    ...base,
    display: "none",
  }),
  option: (provided, state) => ({
    ...provided,
    "&:hover": {
      backgroundColor: "#65a15e",
    },
    backgroundColor: state.isSelected ? "#65a15e" : "white",
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: "999",
    width: "90%",
  }),

  valueContainer: (provided) => ({
    ...provided,
    position: "absolute",
    marginTop: "-5px"
  })
};

export const customStylesForTaskTracker1 = {
  '.css-1i9fq18-ValueContainer': {
    display: 'none',
  },
  menu: (style) => ({
    ...style,
    width: 100,
  }),

  control: (style, state) => ({

    ...style,
    boxShadow: "0px 3px 9px #00000029",
    borderRadius: "9px",
    background: state.isDisabled ? "#f5f6fa" : "f5f6fa",
    backgroundColor:
      // change color when disabled
      state.isDisabled ? "#f5f6fa" : "#f5f6fa",
    opacity: state.isDisabled ? "1" : "1",
    border: "none",
    // minWidth: "inherit",
    margin: "5px 1.5% 0px 0%",
    height: "2.3rem",
    outline: "none",
    width: "91%",
    cursor: "pointer",

  }),

  placeholder: () => ({
    display: "none",
  }),
  clearIndicator: (base) => ({
    ...base,
    display: "none",
  }),
  option: (provided, state) => ({
    ...provided,
    "&:hover": {
      backgroundColor: "#65a15e",
    },
    backgroundColor: state.isSelected ? "#65a15e" : "white",
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: "999",
    width: "90%",
  }),

  valueContainer: (provided) => ({
    ...provided,
    position: "absolute",
    marginTop: "-5px"
  })
};

export const customStylesForTaskTracker6 = {
  '.css-1i9fq18-ValueContainer': {
    display: 'none',
  },
  menu: (style) => ({
    ...style,
    width: 100,
  }),

  control: (style, state) => ({

    ...style,
    boxShadow: "0px 3px 9px #00000029",
    borderRadius: "9px",
    background: state.isDisabled ? "#f5f6fa" : "f5f6fa",
    backgroundColor:
      // change color when disabled
      state.isDisabled ? "#f5f6fa" : "#f5f6fa",
    opacity: state.isDisabled ? "1" : "1",
    border: "none",
    // minWidth: "inherit",
    margin: "5px 1.5% 0px 0%",
    height: "2.3rem",
    outline: "none",
    width: "90%",
    cursor: "pointer",

  }),

  placeholder: () => ({
    display: "none",
  }),
  clearIndicator: (base) => ({
    ...base,
    display: "none",
  }),
  option: (provided, state) => ({
    ...provided,
    "&:hover": {
      backgroundColor: "#65a15e",
    },
    backgroundColor: state.isSelected ? "#65a15e" : "white",
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: "999",
    width: "90%",
  }),

  valueContainer: (provided) => ({
    ...provided,
    position: "absolute",
    marginTop: "-5px"
  })
};

export const customStylesForTaskTracker5 = {
  '.css-1i9fq18-ValueContainer': {
    display: 'none',
  },
  menu: (style) => ({
    ...style,
    width: 100,
  }),

  control: (style, state) => ({

    ...style,
    boxShadow: "0px 3px 9px #00000029",
    borderRadius: "9px",
    background: state.isDisabled ? "#f5f6fa" : "f5f6fa",
    backgroundColor:
      // change color when disabled
      state.isDisabled ? "#f5f6fa" : "#f5f6fa",
    opacity: state.isDisabled ? "1" : "1",
    border: "none",
    // minWidth: "inherit",
    margin: "5px 1.5% 0px 0%",
    height: "2.3rem",
    outline: "none",
    width: "90%",
    cursor: "pointer",

  }),

  placeholder: () => ({
    display: "none",
  }),
  clearIndicator: (base) => ({
    ...base,
    display: "none",
  }),
  option: (provided, state) => ({
    ...provided,
    "&:hover": {
      backgroundColor: "#65a15e",
    },
    backgroundColor: state.isSelected ? "#65a15e" : "white",
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: "999",
    width: "90%",
  }),

  valueContainer: (provided) => ({
    ...provided,
    position: "absolute",
    marginTop: "-5px"
  })
};

export const customStylesForTaskTracker2 = {
  '.css-1i9fq18-ValueContainer': {
    display: 'none',
  },
  menu: (style) => ({
    ...style,
    width: 100,
  }),

  control: (style, state) => ({

    ...style,
    boxShadow: "0px 3px 9px #00000029",
    borderRadius: "9px",
    background: state.isDisabled ? "#f5f6fa" : "f5f6fa",
    backgroundColor:
      // change color when disabled
      state.isDisabled ? "#f5f6fa" : "#f5f6fa",
    opacity: state.isDisabled ? "1" : "1",
    border: "none",
    // minWidth: "inherit",
    margin: "5px 2.8% 0px 0%",
    height: "2.3rem",
    outline: "none",
    cursor: "pointer",
    width: '90%'

  }),

  placeholder: () => ({
    display: "none",
  }),
  clearIndicator: (base) => ({
    ...base,
    display: "none",
  }),
  option: (provided, state) => ({
    ...provided,
    "&:hover": {
      backgroundColor: "#65a15e",
    },
    backgroundColor: state.isSelected ? "#65a15e" : "white",
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: "999",
    width: "90%",
  }),

  valueContainer: (provided) => ({
    ...provided,
    position: "absolute",
    marginTop: "-5px"
  })
};

export const customStylesForTaskTrackerSpaces = {
  '.css-1i9fq18-ValueContainer': {
    display: 'none',
  },
  control: (style, state) => ({
    ...style,
    boxShadow: "0px 3px 9px #00000029",
    borderRadius: "9px",
    background: state.isDisabled ? "#f5f6fa" : "#f5f6fa",
    backgroundColor: state.isDisabled ? "#f5f6fa" : "#f5f6fa",
    opacity: state.isDisabled ? "1" : "1",
    border: "none",
    margin: "5px 2.8% 0px 0%",
    height: "2.3rem",
    outline: "none",
    cursor: "pointer",
    width: '90%',
    position: 'relative', // Make sure the cross button is positioned correctly
  }),

  menu: (style) => ({
    ...style,
    zIndex: "999",
    width: "90%",
  }),

  singleValue: (provided) => ({
    ...provided,
    gridArea: '1 / 1 / 2 / 3',
    maxWidth: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    color: 'rgb(51, 51, 51)',
    marginLeft: '2px',
    marginRight: '12px',
    boxSizing: 'border-box',
  }),

  valueContainer: (provided) => ({
    ...provided,
    position: "absolute",
    marginTop: "-5px"
  }),

  clearIndicator: (base) => ({
    ...base,
    width: "100%",
    justifyContent: "flex-end", // Align the clear button to the right
  }),

  option: (provided, state) => ({
    ...provided,
    "&:hover": {
      backgroundColor: "#65a15e",
    },
    backgroundColor: state.isSelected ? "#65a15e" : "white",
  }),

  placeholder: () => ({
    display: "none", // Hide the placeholder text
  }),
};

export const customStylesForTaskTracker4 = {
  '.css-1i9fq18-ValueContainer': {
    display: 'none',
  },
  menu: (style) => ({
    ...style,
    width: 100,
  }),

  control: (style, state) => ({

    ...style,
    boxShadow: "0px 3px 9px #00000029",
    borderRadius: "9px",
    background: state.isDisabled ? "#f5f6fa" : "f5f6fa",
    backgroundColor:
      // change color when disabled
      state.isDisabled ? "#f5f6fa" : "#f5f6fa",
    opacity: state.isDisabled ? "1" : "1",
    border: "none",
    // minWidth: "inherit",
    margin: "5px 2.8% 0px 0%",
    height: "2.3rem",
    outline: "none",
    cursor: "pointer",
    width: '90%'

  }),

  placeholder: () => ({
    display: "none",
  }),
  clearIndicator: (base) => ({
    ...base,
    display: "none",
  }),
  option: (provided, state) => ({
    ...provided,
    "&:hover": {
      backgroundColor: "#65a15e",
    },
    backgroundColor: state.isSelected ? "#65a15e" : "white",
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: "999",
    width: "90%",
  }),

  valueContainer: (provided) => ({
    ...provided,
    position: "absolute",
    marginTop: "-5px"
  })
};

export const customStylesForTaskTracker3 = {
  '.css-1i9fq18-ValueContainer': {
    display: 'none',
  },
  menu: (style) => ({
    ...style,
    width: 100,
  }),

  control: (style, state) => ({

    ...style,
    boxShadow: "0px 3px 9px #00000029",
    borderRadius: "9px",
    background: state.isDisabled ? "#f5f6fa" : "f5f6fa",
    backgroundColor:
      // change color when disabled
      state.isDisabled ? "#f5f6fa" : "#f5f6fa",
    opacity: state.isDisabled ? "1" : "1",
    border: "none",
    // minWidth: "inherit",
    margin: "5px 2.8% 0px 0%",
    height: "2.3rem",
    outline: "none",
    cursor: "pointer",
    width: '90%'

  }),

  placeholder: () => ({
    display: "none",
  }),
  clearIndicator: (base) => ({
    ...base,
    display: "none",
  }),
  option: (provided, state) => ({
    ...provided,
    "&:hover": {
      backgroundColor: "#65a15e",
    },
    backgroundColor: state.isSelected ? "#65a15e" : "white",
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: "999",
    width: "90%",
  }),

  valueContainer: (provided) => ({
    ...provided,
    position: "absolute",
    marginTop: "-5px"
  })
};

export const customStylesForSelectTaskTracker = {
  '.css-1i9fq18-ValueContainer': {
    display: 'none',
  },
  menu: (style) => ({
    ...style,
    width: 100,
  }),

  control: (style, state) => ({

    ...style,
    boxShadow: "0px 3px 9px #00000029",
    borderRadius: "9px",
    background: state.isDisabled ? "#f5f6fa" : "f5f6fa",
    backgroundColor:
      // change color when disabled
      state.isDisabled ? "#f5f6fa" : "#f5f6fa",
    opacity: state.isDisabled ? "1" : "1",
    border: "none",
    // minWidth: "inherit",
    margin: "5px 1.5% 0px 0%",
    height: "2.3rem",
    outline: "none",
    cursor: "pointer",
    width: '90%'

  }),

  placeholder: () => ({
    display: "none",
  }),
  clearIndicator: (base) => ({
    ...base,
    display: "none",
  }),
  option: (provided, state) => ({
    ...provided,
    "&:hover": {
      backgroundColor: "#65a15e",
    },
    backgroundColor: state.isSelected ? "#65a15e" : "white",
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: "999",
    width: "90%",
  }),

  valueContainer: (provided) => ({
    ...provided,
    position: "absolute",
    marginTop: "-5px"
  })
};

export const customStylesForRMS = {

  menu: (style) => ({
    ...style,
    width: 100,
  }),

  control: (style, state) => ({

    ...style,
    boxShadow: "0px 3px 9px #00000029",
    borderRadius: "9px",
    background: state.isDisabled ? "#999" : "f5f6fa",
    backgroundColor:
      // change color when disabled
      state.isDisabled ? "#999" : "#f5f6fa",
    opacity: state.isDisabled ? "0.2" : "1",
    border: "none",
    // minWidth: "inherit",
    margin: "5px 1.5% 0px 0%",
    height: "2.3rem",
    outline: "none",
    width: "100%",
    cursor: "pointer",
  }),


  placeholder: () => ({
    display: "none",
  }),
  clearIndicator: (base) => ({
    ...base,
    display: "none",
  }),
  option: (provided, state) => ({
    ...provided,
    "&:hover": {
      backgroundColor: "rgb(252, 187, 8)",
    },
    backgroundColor: state.isSelected ? "rgb(252, 187, 8)" : "white",
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: "999",
    width: "100%",
  }),
  valueContainer: (provided) => ({
    ...provided,
    position: "absolute"
  })
};

export const customStylesForRMSMulti = {
  menu: (provided) => ({
    ...provided,
    zIndex: "999", // Ensure it stays above other elements
    width: "100%", // Match the control's width
    overflow: "auto", // Prevent menu overflow
  }),

  control: (style, state) => ({
    ...style,
    boxShadow: "0px 3px 9px #00000029",
    borderRadius: "9px",
    background: state.isDisabled ? "#999" : "#f5f6fa",
    backgroundColor: state.isDisabled ? "#999" : "#f5f6fa",
    opacity: state.isDisabled ? "0.2" : "1",
    border: "none",
    margin: "5px 1.5% 0px 0%",
    height: "2.3rem", // Maintain consistent height
    outline: "none",
    width: "100%", // Ensure it spans the full width of the container
    cursor: "pointer",
  }),

  placeholder: (provided) => ({
    ...provided,
    display: "none", // Hide the placeholder as required
  }),

  clearIndicator: (base) => ({
    ...base,
    display: "none", // Hide the clear button
  }),

  option: (provided, state) => ({
    ...provided,
    "&:hover": {
      backgroundColor: "rgb(252, 187, 8)", // Highlight on hover
    },
    backgroundColor: state.isSelected ? "rgb(252, 187, 8)" : "white", // Selected option styling
  }),

  valueContainer: (provided) => ({
    ...provided,
    padding: "0 8px", // Adjust padding to fit content
    position: "relative", // Fix potential layout disruptions
  }),
};



export const customSearch = {
  control: () => ({
    margin: "0 10px",
    borderRadius: "9px",
    padding: "0 20px 0 0px",
    border: "1px solid rgba(0, 0, 0, 0.192)",
  }),
  placeholder: () => ({
    // display: "none",

    marginTop: "-30px",
    color: "#6666",
  }),


  //  option color on hover green
  option: (provided, state) => ({
    ...provided,
    "&:hover": {
      backgroundColor: "rgb(252, 187, 8)",
    },
    backgroundColor: state.isSelected ? "rgb(252, 187, 8)" : "white",
  }),
  // dropdown width
  menu: (provided) => ({
    ...provided,
    width: "92%",
    marginLeft: "0.6rem",
  }),
};