import axios from "axios";
const apiKey = process.env.REACT_APP_PRICE_REVISION_URL;
const apiKey2 = process.env.REACT_APP_RESOURCEMASTER_APP_URL;
const apiKey3 = process.env.REACT_APP_CLIENT_CONTRACT_URL;
const apiKey4 = process.env.REACT_APP_PORTAL_MASTER_URL;

export const getPriceList = async (
  limit,
  page,
  revised,
  clientId,
  endMonthId,
  startMonthId,
  salesRepId,
  activeEmployees,
  isOnsite
) => {
  const queryParams = [];

  if (revised) {
    queryParams.push(`isRevised=${revised === "Done" ? true : false}`);
  }

  if (clientId) {
    queryParams.push(`clientId=${clientId}`);
  }

  if (endMonthId) {
    queryParams.push(`contractEndMonthId=${endMonthId}`);
  }

  if (startMonthId) {
    queryParams.push(`resourceStartMonthId=${startMonthId}`);
  }

  if (salesRepId) {
    queryParams.push(`salesRepId=${salesRepId}`);
  }

  if(isOnsite === true || isOnsite === false) {
    queryParams.push(`isOnsite=${isOnsite}`);
  }

  queryParams.push(`activeEmployees=${activeEmployees === undefined ? true : activeEmployees}`);

  console.log("acriveemp -- ",activeEmployees)

  const queryString = queryParams.join("&");

  const apiUrl = `${apiKey}/price-revision-resources-info-listing?limit=${limit}&page=${page}${
    queryString ? `&${queryString}` : ""
  }`;

  const response = await axios.get(apiUrl);
  return response?.data?.responseData;
};

// export const getRevisionDuesList = async (limit, page) => {
//   const queryParams = [];
//   const queryString = queryParams.join("&");

//   const apiUrl = `${apiKey}/revision-dues-info-listing-by-current-month?limit=${limit}&page=${page}${
//     queryString ? `&${queryString}` : ""
//   }`;

//   const response = await axios.get(apiUrl);
//   return response?.data?.responseData;
// };

export const getRevisionDuesList = async (limit, page,toggle) => {
  const queryParams = [];
  const queryString = queryParams.join("&");

  const apiUrl = `${apiKey}/revision-dues-info-listing-by-current-month?limit=${limit}&page=${page}&revisionDuesDateOrder=${toggle}${
    queryString ? `&${queryString}` : ""
  }`;

  const response = await axios.get(apiUrl);
  return response?.data?.responseData;
};

export const getResourceDetails = async (clientId, resourceId, onsite,
  projectId) => {
  const response = await axios.get(
    `${apiKey}/resource-details-by-id?clientId=${clientId}&resourceId=${resourceId}&isOnsite=${onsite === "false" ? false : true}${onsite === "false" ? `&projectId=${projectId}` : ""}`
  );
  return response?.data?.responseData;
};

export const getAdditionalInformation = async (clientId, resourceId, onsite,
  projectId) => {
  const response = await axios.get(
    `${apiKey}/pricing-resource-info-by-resource-and-client?clientId=${clientId}&resourceId=${resourceId}&isOnsite=${onsite === "false" ? false : true}${onsite === "false" ? `&projectId=${projectId}` : ""}`
  );
  return response?.data?.responseData;
};

export const getClientList = async () => {
  const response = await axios.get(`${apiKey2}/getClientOnSite`);
  return response?.data?.responseData;
};

export const getClientListClientRates = async () => {
  const response = await axios.get(`${apiKey2}/getClientFilterData`);
  return response?.data?.responseData;
};

export const getStartMonthList = async (isOnsite) => {
  const response = await axios.get(
    `${apiKey}/get-start-month-names-for-filter?isOnsite=${isOnsite}`
  );
  return response?.data?.responseData;
};

export const getEndMonthList = async () => {
  const response = await axios.get(`${apiKey}/get-end-month-names-for-filter`);
  return response?.data?.responseData;
};

export const getApproveByList = async () => {
  const response = await axios.get(`${apiKey}/get-approved-by-for-drop-down`);
  return response?.data?.responseData;
};

export const getContractTypeList = async () => {
  const response = await axios.get(`${apiKey3}/get-contract-type-list`);
  return response?.data?.responseData;
};

export const createAdditionalInformation = async (data) => {
  const response = await axios.post(
    `${apiKey}/add-additional-information`,
    data
  );
  return response?.data;
};

export const updateAdditionalInformation = async (data) => {
  const response = await axios.put(
    `${apiKey}/update-additional-information`,
    data
  );
  return response?.data;
};

export const getHistoryOfAdditionalInfo = async (id) => {
  const response = await axios.get(
    `${apiKey}/pricing-info-history-by-id?id=${id}`
  );
  console.log("response --- ", response?.data?.responseData);
  return response?.data?.responseData;
};

export const getSalesRepList = async () => {
  const response = await axios.get(`${apiKey4}/get-sales-rep-for-filter`, {
    headers: {
      Authorization: null,
    },
  });
  return response?.data?.responseData;
};
