import React, { useEffect, useContext, useState } from "react";
import SideBar from "../Clients/component/SideBar";
import style from "./OffshoreReport.module.css";
import OffshorReportTable from "./offshoreReportTable";
import FilterListIcon from "@mui/icons-material/FilterList";
import { Modal } from "react-responsive-modal";
import { useHistory } from "react-router-dom";
import loaderImg from "../../../assets/loader.gif";
import constants, { employeeId } from "../../../Utils/constants";
import axios from "axios";
import Moment from "moment";
import { api } from "../CommonApi/api";
import { get } from "../CommonApi/axiosCall";
import Select from "react-select";
import { DataContext } from "../CommonComponent/useContext/DataContext";
import searchIcon from "../../../assets/Icon-search.png";
import { AiOutlineClose } from "react-icons/ai";
import cookie from "react-cookies";
import { customSearch } from "../Clients/component/style";
import Pagination from "../CommonComponent/Pagination";
import milestone from "../../../assets/milestone.png";
import rightTickPRM from "../../../assets/rightTickPRM.png";
import Filter from "../components1/Filter";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";

const apiKey = process.env.REACT_APP_ATS_APP_URL;

const OfferAcceptedCandidate = (props) => {
  const history = useHistory();
  const { data, setData } = useContext(DataContext);
  const baseUrl = constants.apiBaseUrlResource;
  const [applied, setApplied] = useState(
    props.history?.location.state?.allData?.applied
      ? props.history.location.state?.allData?.applied
      : false
  );
  const [showFilter, setShowFilter] = useState(false);
  const [OffShoreData, setOffshoreData] = useState();
  const [currentPage, setCurrentPage] = useState(
    props.history?.location?.state?.allData?.currentPage
      ? props.history.location?.state?.allData?.currentPage
      : 1
  );
  const [totalResourceOffShore, setTotalResourceOffShore] = useState(0);
  const [pageCount, setpageCount] = useState(0);
  const [offshoreEmp, setOffshoreEmp] = useState([]);
  const [offshoreProject, setOffshoreProject] = useState([]);
  const [offshoreSkills, setOffshoreSkills] = useState([]);
  const [offshoreSkills1, setOffshoreSkills1] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [partnerName, setParnername] = useState([]);
  const [ExitDate, setExitDate] = useState();
  const [show, setShow] = useState(false);
  const [, setAction] = useState([]);
  const [, setBandwidths] = useState([]);
  const [, setTechnology] = useState([]);
  const [, setEmployeeId] = useState([]);
  const [filterName, setFilterName] = useState(
    props.history.location.state?.allData?.filterName
      ? props.history.location.state?.allData?.filterName
      : {}
  );
  const [, setProjectId] = useState([]);
  const [, setSkills] = useState([]);
  const [allData, setAllData] = useState(
    data?.offerAcceptedData
      ? data?.offerAcceptedData
      : {
          name: null,
          status: [],
          empId: employeeId,
          sortBy: ["excepted_joining_date asc"],
          isForRMS:true,
          isMarkForRelease: false,
          skillId: [],
        }
  );
  offshoreEmp?.sort((a, b) =>
    a.name?.toUpperCase() > b.name?.toUpperCase() ? 1 : -1
  );

  const url = process.env.REACT_APP_UMS_APP_URL;

  const getResourceOffShoreData = (clearData, currentPage) => {
    const filterData = allData;
    setIsLoaded(true);
    axios
      .post(
        apiKey + "/getAllOffers?" + "limit=" + limit + "&page=" + currentPage,
        filterData,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization: ` ${cookie.load("token")}`,
          },
        }
      )
      .then((res) => {
        setIsLoaded(false);
        setOffshoreData(res.data.responseData.getAllData);
        setTotalResourceOffShore(res.data.responseData.positionCount);
        setpageCount(res.data.responseData.positionCount / 10);
      })
      .catch(() => {
        setIsLoaded(false);
      });
  };

  const getOffshorePartner = () => {
    let baseURL = api.getPartialPoolResources;
    get(baseURL)
      .then((res) => {
        setParnername(res.data.responseData);
      })
      .catch(() => {});
  };

  useEffect(() => {
    if (data?.partialoffshore?.handleFilter) {
      setApplied(true);
    }
    getOffshoreProject();
    getOffshoreEmp();
    getOffshoreSkills();
    getOffshoreSkills1();
  }, []);
  useEffect(() => {
    getResourceOffShoreData(allData, currentPage);
  }, [currentPage, allData]);
  const getOffshoreEmp = () => {
    axios
      .get(baseUrl + "/getAllocatedOffShoreEmployee", {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
        },
      })
      .then((res) => {
        // console.log(res.data.responseData.employees);
      })
      .catch(() => {});
  };

  const getOffshoreProject = () => {
    axios
      .get(baseUrl + "/getProjectOffShore", {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
        },
      })
      .then((res) => {
        setOffshoreProject(res.data.responseData);
      })
      .catch(() => {});
  };

  const getUserData = () => {
    axios
      .get(url + `/updateUsersDetails`, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
        },
      })
      .then(() => {})
      .catch(() => {});
  };

  const getOffshoreSkills = () => {
    axios
      .get(baseUrl + "/getOffshoreEmployeeSkillForFilterData", {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
        },
      })
      .then((res) => {
        setOffshoreSkills(res.data.responseData);
      })
      .catch(() => {});
  };

  const getOffshoreSkills1 = () => {
    axios
      .get(apiKey + "/get-all-core-skills", {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
        },
      })
      .then((res) => {
        setOffshoreSkills1(res.data.responseData);
      })
      .catch(() => {});
  };

  const limit = 10;

  const handlePageChange = (page, status) => {
    if (status === "next") {
      setCurrentPage(page + 1);
    } else if (status === "prev") {
      setCurrentPage(page - 1);
    }
  };
  const handleFilter = () => {
    setShowFilter(!showFilter);
  };

  const closeFilter = () => {
    setCurrentPage(1);
    setAllData((preValue) => {
      return preValue
        ? {
            ...preValue,
            name: null,
            status: [],
            empId: employeeId,
            sortBy: ["excepted_joining_date asc"],
            isMarkForRelease: false,
            skillId: [],
          }
        : null;
    });

    setTechnology([]);
    setAction([]);
    setProjectId([]);
    setShowFilter(false);
    setApplied(false);
  };
  useEffect(() => {
    setData({ ...data, partialoffshore: allData });
  }, [allData]);

  const handleApplied = (arg) => {
    setAllData({ ...allData, handleFilter: true });
    if (
      allData?.bandwidths?.length !== 0 ||
      allData?.skillId?.length !== 0 ||
      allData?.salaryRange?.length !== 0
    ) {
      setApplied(true);
    }
    setShowFilter(false);
    setCurrentPage(1);
    getResourceOffShoreData(allData, 1);
  };

  useEffect(() => {
    getUserData();
    getOffshorePartner();
  }, []);

  return (
    <>
      <div className={style.main_client_body}>
        <SideBar />

        <div className={style.table_div}>
          <div className={style.table_div_head}>
            <div
              className={style.breadscrumb}
              style={{ padding: "6px 0px" }}
            ></div>

            <div className={style.second_child}>
              <div
                style={{ padding: "10px 0px", fontWeight: "600", width: "10%" }}
              >
                Final Stages
              </div>
              <div style={{ width: "22%" }}>
                <p className={style.count}>
                  <span
                    className={style.backColor1}
                    style={{ fontSize: "1.235rem", fontWeight: "500" }}
                  >
                    {totalResourceOffShore}
                  </span>
                </p>
              </div>
              <div style={{ width: "25%", paddingLeft: "7%" }}>
                <button className={style.filter_btn} onClick={handleFilter}>
                  {applied && (
                    <img
                      className={style.appliedcheck}
                      src={rightTickPRM}
                      alt="img"
                    />
                  )}
                  {<FilterListIcon style={{ marginRight: "10px" }} />}
                  Filter
                </button>
              </div>
            </div>
          </div>

          <div
            onClick={() => {
              setShowFilter(false);
            }}
            className={style.offshorelist}
            style={{
              backgroundColor: "white",
              minHeight: "28rem",
              overflow: "auto",
            }}
          >
            {isLoaded ? (
              <div>
                <img
                  src={loaderImg}
                  alt="loader"
                  style={{ marginLeft: "47%", marginTop: "8%", width: "12%" }}
                  className={style.loader}
                />
                <p
                  style={{
                    fontSize: "24px",
                    fontWeight: "bold",
                    paddingLeft: "38%",
                    position: "absolute",
                    color: "#FBBB08",
                    marginTop: "-4px",
                    fontStyle: "italic",
                    marginLeft: "34.5%",
                  }}
                >
                  Loading...
                </p>
              </div>
            ) : totalResourceOffShore > 0 ? (
              <table style={{ fontSize: "12px", border: "none" }}>
                <thead
                  style={{
                    backgroundColor: "#F0F0F0",
                    top: "0px",
                  }}
                >
                  <tr style={{ padding: "100px" }}>
                    <th style={{ padding: "10px", width: "13rem" }}>Name</th>
                    <td
                      style={{
                        padding: "10px",
                        width: "16rem",
                        fontWeight: "700",
                      }}
                    >
                      Core Skill
                    </td>
                    <th style={{ padding: "10px" }}>Experience</th>
                    <th style={{ padding: "10px" }}>EDOJ</th>
                    <th style={{ padding: "10px" }}>Days To Join</th>
                  </tr>
                </thead>
                <tbody>
                  {OffShoreData?.map((val) => {
                    return (
                      <OffshorReportTable
                        val={val}
                        setExitDate={setExitDate}
                        setShow={setShow}
                        allData={allData}
                        currentPage={currentPage}
                        filterName={filterName}
                        applied={applied}
                      />
                    );
                  })}
                </tbody>
              </table>
            ) : (
              <div className={style.noData}>
                <img src={milestone} alt="img" />
                <h3 className={style.noData_header}>No data available</h3>
              </div>
            )}
          </div>
          {totalResourceOffShore > limit && pageCount > 0 && (
            <div
              className={style.paginationmobileContainer}
              style={{ marginTop: "20px" }}
            >
              <Pagination
                pagesCount={pageCount}
                pageSize={limit}
                onPageChange={handlePageChange}
                currentPage={currentPage}
              />
            </div>
          )}
        </div>
      </div>
      {showFilter && (
        <Filter
          closeFilter={closeFilter}
          handleApplied={handleApplied}
          filterData={allData}
          type={"partialoffshoreReport"}
          setAction={setAction}
          setBandwidths={setBandwidths}
          employeeId={offshoreEmp}
          setEmployeeId={setEmployeeId}
          projectList={offshoreProject}
          setProject={setProjectId}
          offshoreSkills={offshoreSkills1}
          setSkills={setSkills}
        />
      )}
      <Modal
        open={show}
        center
        onClose={() => setShow(false)}
        showCloseIcon={false}
        classNames={{
          modal: "reactmodal",
        }}
      >
        <div className={style.modal_container}>
          {ExitDate
            ? Moment(ExitDate).format("DD MMM YY")
            : "No Date Available"}
        </div>
      </Modal>
    </>
  );
};

export default OfferAcceptedCandidate;
