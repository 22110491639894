import React, { useEffect, useState } from "react";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import style from "./PriceList.module.css";
import ReasonImg from "../../../../assets/Reason.png";
import { Modal } from "react-bootstrap";
import { MdHistory } from "react-icons/md";
import { MdEdit } from "react-icons/md";
import { getHistoryOfAdditionalInfo } from "../../../../Utils/axios/priceRevisionApi";
import { useQueries, useQuery } from "react-query";
import moment from "moment";

const PriceList = ({ data, handleSetAdditionalInfo }) => {
  const [show, setShow] = useState(false);
  const [reason, setReason] = useState("");
  const handleClose = () => {
    setShow(false);
  };
  const [showHistory, setShowHistory] = useState(false);
  const [id, setId] = useState(null);
  console.log("id --- ", id);

  const {
    data: history,
    isLoading,
    refetch,
  } = useQuery(["getHistoryOfAdditionalInfo", id], () =>
    getHistoryOfAdditionalInfo(id),
    {
      enabled: !!id,
    }
  );

  useEffect(() => {
    if (id) {
      refetch();
      setShowHistory(true);
    }
  }, [id]);

  console.log("data -- ", data);

  const tableBody = isLoading ? (
    <tr>
      <td
        colSpan={6}
        className={style.tdPriceList}
        style={{ textAlign: "center" }}
      >
        Loading...
      </td>
    </tr>
  ) : history?.length ? (
    history?.map((item) => (
      <tr className={style.tableRow}>
        <td className={`${style.tdPriceList} ${style.pl_3rem}`}>
          {item?.modifiedOn && moment(item?.modifiedOn).format("DD MMM YY")}
        </td>
        <td className={style.tdPriceList}>{item?.changeByEmpName}</td>
        <td className={style.tdPriceList}>{item?.fields}</td>
        <td className={style.tdPriceList}>
          {(item?.prevDescription !== "" || item?.currentDescription !== "") &&
            item?.prevDescription + " -> " + item?.currentDescription}
        </td>
      </tr>
    ))
  ) : (
    <tr>
      <td
        colSpan={6}
        className={style.tdPriceList}
        style={{ textAlign: "center" }}
      >
        No Data Found
      </td>
    </tr>
  );

  return (
    <div className={style.main_div}>
      <p className={style.heading}>Additional Information</p>
      <div className={style.table_div}>
        <div className={style.table}>
          <div className={style.th}>
            <p className={style.tableContent}>Last Client Rate</p>
            <p className={style.tableContent}>Current Client Rate</p>
            <p className={style.tableContent}>Revised</p>
            <p className={style.tableContent}>Approved By</p>
            <p className={style.tableContent}>Reason</p>
            <p className={`${style.tableContent} ${style.width_2rem}`}></p>
            <p className={`${style.tableContent} ${style.width_2rem}`}></p>
          </div>
        </div>
        {data?.message ? (
          <div className={style.td}>{data?.message}</div>
        ) : (
          data?.map((item) => (
            <div className={style.td}>
              <p className={style.para}>
                <CurrencyRupeeIcon className={style.rupee_icon} />
                {item?.lastClientRate || "NA"}
              </p>
              <p className={style.para}>
                {" "}
                <CurrencyRupeeIcon className={style.rupee_icon} />
                {item?.currentClientRate || "NA"}
              </p>
              <p className={style.para}>
                {item?.isRevised === true
                  ? "Done"
                  : item?.isRevised === false
                  ? "Defer"
                  : "NA"}
              </p>
              <p className={style.para}>{item?.approvedByName}</p>
              <p className={style.para}>
                <div className={style.reason_img_div}>
                  <img
                    onClick={() => {
                      setShow(true);
                      setReason(item?.reason);
                    }}
                    onKeyDown={(event) => {
                      if (event.key === "Enter") {
                        setShow(true);
                        setReason(item?.reason);
                      }
                    }}
                    tabIndex={0}
                    className={style.reason_img}
                    src={ReasonImg}
                    alt="img"
                  />
                </div>
              </p>
              <p className={style.width_2rem}>
                {item?.existsHistory && (
                  <MdHistory
                    style={{
                      fontSize: "17px",
                      color: "#289e98",
                      cursor: "pointer",
                    }}
                    title="Employee History"
                    onClick={() => {
                      setId(item?.id);
                    }}
                  />
                )}
              </p>
              <p
                onClick={() => {
                  handleSetAdditionalInfo(item?.id);
                }}
              >
                <MdEdit
                  style={{
                    marginLeft: "5px",
                    color: "#239e98",
                    cursor: "pointer",
                  }}
                />
              </p>
            </div>
          ))
        )}
      </div>

      <Modal show={show} centered size="md" onHide={handleClose}>
        <Modal.Header
          style={{
            paddingBottom: "0px",
            paddingTop: "6px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <h6 style={{ fontSize: "14px", color: "#00979d" }}>Reason</h6>
        </Modal.Header>
        <div className={style.modal_para_div}>
          {reason
            ? reason?.split("\n")?.map((line, index) => (
                <>
                  {line}
                  {index !== reason?.split("\n")?.length - 1 && <br />}
                </>
              ))
            : ""}
        </div>
      </Modal>

      <Modal
        show={showHistory}
        centered
        size="md"
        onHide={() => setShowHistory(false)}
      >
        <Modal.Header
          style={{
            paddingBottom: "0px",
            paddingTop: "6px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          closeButton={true}
        >
          <h6 style={{ fontSize: "14px", color: "#00979d", width: "100%" }}>
            <div style={{ margin: "auto", width: "10%" }}>History</div>
          </h6>
        </Modal.Header>
        <div className={style.modal_para_div}>
          <table>
            <thead>
              <tr className={style.tableHead}>
                <th className={`${style.thPriceList} ${style.pl_3rem}`}>
                  Date Modified
                </th>
                <th className={style.thPriceList}>Change By</th>
                <th className={style.thPriceList}>Fields</th>
                <th className={style.thPriceList}>Changes</th>
              </tr>
            </thead>
            <tbody>{tableBody}</tbody>
          </table>
        </div>
      </Modal>
    </div>
  );
};


export default PriceList;
