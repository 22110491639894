import React, { useEffect, useContext, useState } from "react";
import Header from "../../../taskTracker/commonComponent/Header";
import SideBar from "../../Clients/component/SideBar";
import style from "./PoolResources.module.css";
import TableRow from "./TableRow";
import FilterListIcon from "@mui/icons-material/FilterList";
import axios from "axios";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import { DataContext } from "../../CommonComponent/useContext/DataContext";
import loaderImg from "../../../../assets/loader.gif";
import cookie from "react-cookies";
import { Link } from "react-router-dom";
import { Modal } from "react-responsive-modal";
import PoolResourcesDownload from "./poolResourcesDownload/ReviewFileDownload";
import Select from "react-select";
import { customSearch } from "../../Clients/component/style";
import searchIcon from "../../../../assets/Icon-search.png";
import { AiOutlineClose } from "react-icons/ai";
import milestone from "../../../../assets/milestone.png";
import Pagination from "../../CommonComponent/Pagination";
import rightTickPRM from "../../../../assets/rightTickPRM.png";
import Filter from "../../components/Filter";
import constants from "../../../../Utils/constants";
import { api } from "../../CommonApi/api";
import { get, post } from "../../CommonApi/axiosCall";
import sortDownarrowImg from "../../../../assets/Down.png";
import sortUparrowImg from "../../../../assets/Up.png";
import { useHistory } from "react-router-dom";
import { useMutation } from "react-query";
import { addToPoolResource } from "../../../../Utils/axios/rmsApi";
import { notifyError, notifySuccess } from "../../../atsDashboard/utils/notify";

const PoolResources = (props) => {
  const [note, setNote] = useState("") ;
  const history = useHistory();
  const { data, setData } = useContext(DataContext);
  const baseUrl = constants.apiBaseUrlResource;
  const [showFilter, setShowFilter] = useState(false);
  const [partnerName, setParnername] = useState([]);
  const [applied, setApplied] = useState(
    props.history.location.state?.allData?.applied
      ? props.history.location?.state?.allData?.applied
      : false
  );
  const [poolData, setPoolData] = useState([]);
  const [currentPage, setCurrentPage] = useState(
    props.history.location?.state?.allData?.currentPage
      ? props.history.location?.state?.allData?.currentPage
      : 1
  );
  const [pageCount, setpageCount] = useState(0);
  const [totalResourceOnsite, setTotalResourceOffShore] = useState(0);
  const [clientListing, setClientListing] = useState([]);
  const [empListing, setEmpListing] = useState([]);
  const [onsiteSkills, setOnsiteSkills] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [sortOrder, setSortOrder] = useState(null);
  const [downLoadData, setDownloadData] = useState(0);
  const [filterName, setFilterName] = useState(
    props.history.location.state?.allData?.filterName
      ? props.history.location.state?.allData?.filterName
      : {}
  );

  const url1 = constants.apiBaseUrl;

  const [action, setAction] = useState([]);
  const [clientId, setClientId] = useState([]);
  const [employeeId, setEmployeeId] = useState([]);
  const [skills, setSkills] = useState([]);
  const [show, setShow] = useState(false);
  const [empId, setEmpID] = useState(0);
  const [AllocationInformation, SetAllocationInformation] = useState([]);
  const [resourcePool, setResourcePool] = useState(false);
  const [filteredData, setFilteredData] = useState();
  const [fDlen, setFDlen] = useState(11);

  const [allData, setAllData] = useState(
    data?.PoolResources
      ? data?.PoolResources
      : props.history.location.state?.allData.allData
      ? props.history.location.state?.allData.allData
      : {
          statusId: action,
          empId: employeeId,
          skillId: skills,
          experienceRange: [],
          salaryRange: [],
          handleFilter: false,
          partnerId: [],
          skillOrder: sortOrder,
          isRemovedFromPool: null
        }
  );
  const url = process.env.REACT_APP_UMS_APP_URL;
  const onSearchHandler = (e, option) => {
    if (e || e === null) {
      switch (option) {
        case "Code":
          setAllData({ ...allData, empId: [e?.value] });
          setFilterName({ ...filterName, positionCode: e?.label });
          break;
        case "Code1":
          setAllData({ ...allData, partnerId: [e?.value] });
          setFilterName({ ...filterName, positionCode: e?.label });
          break;
        default:
      }
    }
  };

  const handleSorting = () => {
    setSortOrder(sortOrder === null ? true : sortOrder === true ? false : true);
  };
  useEffect(() => {
    setAllData({ ...allData, skillOrder: sortOrder });
  }, [sortOrder]);



  empListing?.sort((a, b) =>
    a.name?.toUpperCase() > b.name?.toUpperCase() ? 1 : -1
  );

  const getPositionCodeForFilterOption = empListing?.map((i) => ({
    value: i.id,
    label: `${i.name}  ${i.code}`,
  }));

  const getAddResourceOnsiteDataDown = (clearData, currentPage, limit) => {
    const filterData = clearData?.empId?.length == 0 ? allData : allData;
    axios
      .post(
        baseUrl +
          "/getPoolResource?" +
          "limit=" +
          limit +
          "&page=" +
          currentPage,
        filterData,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization: ` ${cookie.load("token")}`,
          },
        }
      )
      .then((res) => {
        setDownloadData(res.data.responseData.resources);
        const filtered = res.data.responseData.resources?.filter(
          obj=>obj.partialPool===true
        );
        setFilteredData(filtered);
        setFDlen(filtered.length);
      })
      .catch(() => {});
  };

  const getAddResourceOnsiteData = (clearData, currentPage) => {
    const filterData = clearData?.empId?.length == 0 ? allData : allData;
    setIsLoaded(true);
    axios
      .post(
        baseUrl +
          "/getPoolResource?" +
          "limit=" +
          limit +
          "&page=" +
          currentPage,
        filterData,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization: ` ${cookie.load("token")}`,
          },
        }
      )
      .then((res) => {
        setIsLoaded(false);
        setPoolData(res.data.responseData);
        setTotalResourceOffShore(res.data.responseData.totalResources);
        setpageCount(res.data.responseData.totalResources / 10);
        getAddResourceOnsiteDataDown(
          clearData,
          currentPage,
          res.data.responseData.totalResources
        );
      })
      .catch(() => {
        setIsLoaded(false);
      });
  };

  const getClientListing = () => {
    axios
      .get(baseUrl + "/getClientOnSite", {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
        },
      })
      .then((res) => {
        setClientListing(res.data.responseData);
      })
      .catch(() => {});
  };

  const getProjectAllocationByID = (id) => {
    axios
      .get(baseUrl + `/getProjectAllocationByEmpId?empId=${empId}`, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
        },
      })
      .then((res) => {
        SetAllocationInformation(res.data.responseData);
      })
      .catch(() => {});
  };

  const getUserData = () => {
    axios
      .get(url + `/updateUsersDetails`, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
        },
      })
      .then(() => {})
      .catch(() => {});
  };

  const getEmpListing = () => {
    axios
      .get(baseUrl + "/get-pool-resources-employee-for-filter", {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
        },
      })
      .then((res) => {
        setEmpListing(res.data.responseData);
      })
      .catch(() => {});
  };

  const getOnsiteSkills = () => {
    axios
      .get(baseUrl + "/get-pool-resources-technologies", {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
        },
      })
      .then((res) => {
        setOnsiteSkills(res.data.responseData);
      })
      .catch(() => {});
  };

  const limit = 10;

  const handlePageChange = (page, status) => {
    if (status == "next") {
      setCurrentPage(page + 1);
    } else if (status == "prev") {
      setCurrentPage(page - 1);
    }
  };

  const handleFilter = () => {
    setShowFilter(!showFilter);
  };
  const getOffshorePartner = () => {
    let baseURL = api.getPoolResourcespartner;
    get(baseURL)
      .then((res) => {
        setParnername(res.data.responseData);
      })
      .catch(() => {});
  };

  const closeFilter = () => {
    setAllData({ ...allData, handleFilter: false });
    setCurrentPage(1);
    setAllData((preValue) => {
      return preValue
        ? {
            ...preValue,
            empId: allData?.empId,
            skillId: [],
            statusId: [],
            experienceRange: [],
            salaryRange: [],
            isRemovedFromPool: null
          }
        : null;
    });
    // getAddResourceOnsiteData(allData, 1);
    setAction([]);
    setClientId([]);
    // setEmployeeId([]);
    setSkills([]);
    setShowFilter(false);
    setApplied(false);
  };
  const addToPoolResourceMutate = useMutation(addToPoolResource, {onSuccess:(res) => {
    if(res?.data?.responseStatus?.statusCode === 0) {
      notifySuccess(res?.data?.responseData)
    }else {
      notifyError(res?.data?.responseData?.message)
    }  
  },
  onError:(err) => {
    notifyError(err)
  }
})
  useEffect(() => {
    setData({ ...data, PoolResources: allData });
  }, [allData]);

  useEffect(() => {
    if (data?.PoolResources?.handleFilter) {
      setApplied(true);
    }
    getUserData();
    getClientListing();
    getEmpListing();
    getOnsiteSkills();
    getOffshorePartner();
  }, []);
  const handleApplied = (arg) => {
    setAllData({ ...allData, handleFilter: true });
    if (
      allData?.skillId?.length > 0 ||
      allData?.statusId?.length > 0 ||
      allData?.experienceRange?.length > 0 ||
      allData?.salaryRange?.length > 0
    ) {
      setApplied(true);
    } else {
      setApplied(false);
    }
    if (
      allData?.skillId?.length == 0 &&
      allData?.statusId?.length == 0 &&
      allData?.empId?.length == 0 &&
      allData?.experienceRange.length == 0 &&
      allData?.salaryRange.length == 0
    ) {
      setAllData((preValue) => ({
        ...preValue,
        skillId: [],
        statusId: [],
        empId: [],
        experienceRange: [],
        salaryRange: [],
      }));
    }
    setShowFilter(false);
    setCurrentPage(1);
    getAddResourceOnsiteData(allData, 1);
  };

  useEffect(() => {
    getAddResourceOnsiteData(allData, currentPage);
  }, [currentPage, allData, addToPoolResourceMutate?.isSuccess]);

  useEffect(() => {
    empId > 0 && getProjectAllocationByID(empId);
  }, [show]);

  const getPartnerList = partnerName?.map((i) => ({
    value: i.id,
    label: i.name,
  }));
  const value = allData?.empId[0]
    ? getPositionCodeForFilterOption?.find((e) => e.value === allData.empId[0])
    : null;
  const value1 = allData?.partnerId[0]
    ? getPartnerList?.find((e) => e.value === allData.partnerId[0])
    : null;
  return (
    <>
      <Header />
      <div className={style.main_client_body}>
        <SideBar />

        <div className={style.table_div}>
          <div className={style.table_div_head}>
            <div className={style.breadscrumb} style={{ padding: "6px 0px" }}>
              {/* <span><span>Dashboard{'>'}</span><span className={style.recent_tab}>Resource Allocation Onsite </span></span> */}
            </div>

            <div className={style.second_child}>
              <div
                style={{ padding: "10px 0px", fontWeight: "600", width: "12%" }}
              >
                Resources (Pool)
              </div>
              <div style={{ width: "13%" }}>
                {!resourcePool && <p className={style.count}>
                  <span
                    className={style.backColor1}
                    style={{ fontSize: "1.235rem", fontWeight: "500" }}
                  >
                    {totalResourceOnsite}
                  </span>
                </p>}
                {resourcePool && <p className={style.count}>
                  <span
                    className={style.backColor1}
                    style={{ fontSize: "1.235rem", fontWeight: "500" }}
                  >
                    {filteredData?.length}
                  </span>
                </p>}
              </div>
              <div style={{ width: "8%" }} aria-id="PM0001">
                <p className={style.download}>
                  <span
                    className={style.backColor1}
                    style={{
                      fontSize: "2rem",
                      fontWeight: "500",
                      cursor: "pointer",
                      color: "#a15e5e",
                    }}
                  >
                    <PoolResourcesDownload data={downLoadData} />
                  </span>
                </p>
              </div>

              <div style={{ width: "21%", paddingLeft: "3%" }}>
                <button className={style.filter_btn} onClick={handleFilter}>
                  {applied && (
                    <img className={style.appliedcheck} src={rightTickPRM} />
                  )}
                  {<FilterListIcon style={{ marginRight: "10px" }} />}
                  Filter
                </button>
              </div>
              <div
                className={style.s1}
                style={{ width: "24%" }}
                onClick={() => {
                  setShowFilter(false);
                }}
              >
                {allData?.empId.length === 0 ? (
                  <span className={style.cp_serchIcon}>
                    <img src={searchIcon} />
                  </span>
                ) : (
                  <span
                    className={style.cp_serchIcon}
                    onClick={() => {
                      setAllData({
                        ...allData,
                        empId: [],
                      });
                      setFilterName({ ...filterName, positionCode: null });
                    }}
                  >
                    <AiOutlineClose />
                  </span>
                )}
                <Select
                  styles={customSearch}
                  classNamePrefix={"create_position"}
                  placeholder="Employee Name/Code"
                  components={{
                    DropdownIndicator: () => null,
                    IndicatorSeparator: () => null,
                  }}
                  value={value}
                  onChange={(e) => {
                    onSearchHandler(e, "Code");
                    setShowFilter(false);
                    setCurrentPage(1);
                  }}
                  options={getPositionCodeForFilterOption}
                />
              </div>
              <div
                className={style.s1}
                style={{ width: "22%" }}
                onClick={() => {
                  setShowFilter(false);
                }}
              >
                {allData?.partnerId?.length === 0 ? (
                  <span className={style.cp_serchIcon}>
                    <img src={searchIcon} alt="img" />
                  </span>
                ) : (
                  <span
                    className={style.cp_serchIcon}
                    onClick={() => {
                      setAllData({
                        ...allData,
                        partnerId: [],
                      });
                      setFilterName({ ...filterName, positionCode: null });
                    }}
                  >
                    <AiOutlineClose />
                  </span>
                )}
                <Select
                  styles={customSearch}
                  classNamePrefix={"create_position"}
                  placeholder="Partner"
                  components={{
                    DropdownIndicator: () => null,
                    IndicatorSeparator: () => null,
                  }}
                  value={value1}
                  onChange={(e) => {
                    onSearchHandler(e, "Code1");
                    setCurrentPage(1);
                    setShowFilter(false);
                  }}
                  options={getPartnerList}
                />
              </div>
              {/* <div className={style.btn_add}>
                <Link to="/AddPollResources" style={{ textDecoration: "none" }}>
                  <button className={style.create_btn}>
                    <span className={style.plus}>+</span>Allocate Resource
                  </button>
                </Link>
              </div> */}
            </div>
          </div>
          <div style={{display: "flex", alignItems: "center", gap: "3px"}}>
          <input
          type="checkbox"
          style={{accentColor: "green", visibility: "visible", width: "1.1rem", height: "1.1rem"}}
          defaultChecked={allData?.isRemovedFromPool}
          id="removedFromPool"
          onClick={() =>{allData?.isRemovedFromPool && setCurrentPage(1) ; setAllData(prev => ({...prev, isRemovedFromPool:prev.isRemovedFromPool === null ? true : null}))}}
        />
          <label htmlFor="removedFromPool" className={style.main} style={{fontWeight: "600", color: "black", paddingRight: "2rem"}}>
        
        Removed From Pool
      </label>
      <input
          type="checkbox"
          style={{accentColor: "green", visibility: "visible", width: "1.1rem", height: "1.1rem"}}
          onClick={()=>{setResourcePool((val)=>!val)}}
        />
          <label htmlFor="removedFromPool" className={style.main} style={{fontWeight: "600", color: "black"}}>
        
        Resources (Partial Pool)
      </label>
        </div>

          <div
            onClick={() => {
              setShowFilter(false);
            }}
            className={style.poolList}
          >
            {isLoaded ? (
              <div>
                <img
                  src={loaderImg}
                  alt="loader"
                  style={{ marginLeft: "47%", marginTop: "8%", width: "12%" }}
                  className={style.loader}
                />
                <p
                  style={{
                    fontSize: "24px",
                    fontWeight: "bold",
                    paddingLeft: "38%",
                    position: "absolute",
                    color: "#FBBB08",
                    marginTop: "-4px",
                    fontStyle: "italic",
                    marginLeft: "34.5%",
                  }}
                >
                  Loading...
                </p>
              </div>
            ) : poolData.resources?.length > 0 ? (
              <table style={{ fontSize: "12px", border: "none" }}>
                <thead
                  style={{
                    backgroundColor: "#F0F0F0",
                    position: "sticky",
                    top: "0px",
                  }}
                >
                  <tr style={{ padding: "100px" }}>
                    <th style={{ padding: "10px",position: "sticky", left: "0", zIndex: "1", backgroundColor: "#F0F0F0"  }}>Code</th>
                    <th style={{ padding: "10px",position: "sticky", left: "68px", zIndex: "1", backgroundColor: "#F0F0F0" }}></th>
                    {/* <th style={{ padding: "10px",position: "sticky", left: "68px", zIndex: "1", backgroundColor: "#F0F0F0" }}></th> */}
                    <th style={{ padding: "10px",position: "sticky", left: "128px", zIndex: "1", backgroundColor: "#F0F0F0"  }}>Name</th>
                    <th style={{ padding: "10px" }}>Exp</th>
                    <th style={{ padding: "10px" }}>Exp <br/> Range</th>
                    <th style={{ padding: "10px" }}>{" "} Salary <br/> Range</th>
                    <th style={{ padding: "10px" }}>Technology{" "}
                      <img
                        alt=""
                        style={{
                          cursor: "pointer",
                          position: "relative",
                          bottom: "0px",
                          left: "5px",
                        }}
                        src={
                          sortOrder === true ? sortUparrowImg : sortDownarrowImg
                        }
                        onClick={handleSorting}
                      />
                    </th>
                    <th style={{ padding: "10px" }}>
                      {" "}
                      Pool Start <br /> Date
                    </th>
                    <th style={{ padding: "10px" }}>
                      Pool
                      <br /> Age
                    </th>
                    <th style={{ padding: "10px" }}>
                      Availability
                      <br /> Date
                    </th>
                    <th style={{ padding: "10px" }}>
                      Bench
                      <br /> Age
                    </th>
                    <th style={{ padding: "10px" }}>
                      Last Comment
                      <br /> Date
                    </th>
                    <th style={{ padding: "10px" }}>
                      Project/Client
                      <br /> Name
                    </th>
                    <th style={{ padding: "10px" }}>Partner Name</th>
                    <th style={{ padding: "10px" }}>Current Location</th>
                    <th style={{ padding: "10px" }}>Actions</th>
                    <th></th>
                    <th></th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  
                  {!resourcePool && poolData?.resources.map((val) => {
                    return (
                      <TableRow
                        isForcedAddedToPool={val.isForcedAddedToPool}
                        benchAge={val.benchAge}
                        candidateAvailablityDate={val.candidateAvailablityDate}
                        empName={val.empName}
                        empId={val.empId}
                        poolEndDate={val.poolEndDate}
                        poolStartdate={val.poolStartdate}
                        skillName={val.skillName}
                        statusTypeName={val.statusTypeName}
                        experience={val.experience}
                        id={val.id}
                        resignStatus={val.resignStatus}
                        setShow={setShow}
                        currentCommentDate={val.currentCommentDate}
                        experienceRange={val.experienceRange}
                        setEmpID={setEmpID}
                        empCode={val.empCode}
                        salaryRange={val.salaryRange}
                        applied={applied}
                        currentPage={currentPage}
                        allData={allData}
                        filterName={filterName}
                        val={val}
                        isActive={val.isActive}
                        addToPoolResourceMutate={addToPoolResourceMutate}
                        refetchPoolResources={() => getAddResourceOnsiteData(allData, currentPage)}
                        note={note} setNote={setNote}
                        partialPool={val.partialPool}
                        resourcePool={val.resourcePool}
                      />
                    );
                  })}
                  {resourcePool && filteredData?.map((val)=>{
                    if(val.partialPool){
                      return(
                        <TableRow
                        benchAge={val.benchAge}
                        candidateAvailablityDate={val.candidateAvailablityDate}
                        empName={val.empName}
                        empId={val.empId}
                        poolEndDate={val.poolEndDate}
                        poolStartdate={val.poolStartdate}
                        skillName={val.skillName}
                        statusTypeName={val.statusTypeName}
                        experience={val.experience}
                        id={val.id}
                        resignStatus={val.resignStatus}
                        setShow={setShow}
                        currentCommentDate={val.currentCommentDate}
                        experienceRange={val.experienceRange}
                        setEmpID={setEmpID}
                        empCode={val.empCode}
                        salaryRange={val.salaryRange}
                        applied={applied}
                        currentPage={currentPage}
                        allData={allData}
                        filterName={filterName}
                        val={val}
                        isActive={val.isActive}
                        addToPoolResourceMutate={addToPoolResourceMutate}
                        refetchPoolResources={() => getAddResourceOnsiteData(allData, currentPage)}
                        note={note} setNote={setNote}
                        partialPool={val.partialPool}
                      />
                      );
                    }
                  })}
                </tbody>
              </table>
            ) : (
              <div className={style.noData}>
                <img src={milestone} />
                <h3 className={style.noData_header}>No data available</h3>
              </div>
            )}

            {/* } */}
          </div>

          {!resourcePool && totalResourceOnsite > limit && pageCount > 0 &&  (
            <div
              className={style.paginationmobileContainer}
              style={{ marginTop: "20px" }}
            >
              <Pagination
                pagesCount={pageCount}
                pageSize={limit}
                onPageChange={handlePageChange}
                currentPage={currentPage}
              />
            </div>
          )}
          {resourcePool && fDlen>10 && (
            <div
            className={style.paginationmobileContainer}
            style={{ marginTop: "20px" }}
          >
            <Pagination
              pagesCount={pageCount}
              pageSize={limit}
              onPageChange={handlePageChange}
              currentPage={currentPage}
            />
          </div>
          )}
        </div>
      </div>
      {showFilter && (
        <Filter
          closeFilter={closeFilter}
          handleApplied={handleApplied}
          filterData={allData}
          type={"pool"}
          empListing={empListing}
          clientListing={clientListing}
          setAction={setAction}
          setEmployeeId={setEmployeeId}
          setClientId={setClientId}
          setSkills={setSkills}
          onsiteSkills={onsiteSkills}
        />
      )}
      {/* <Modal
        open={show}
        center
        onClose={() => setShow(false)}
        showCloseIcon={false}
        classNames={{
          modal: "reactmodal",
        }}
      >
        <div className={style.modal_container}>
                  <p>
                  Project/Client Name
                  </p>
        
          <div className={style.M_button}>
            <div className={style.button}></div>
          </div>
        </div>
      </Modal> */}
    </>
  );
};

export default PoolResources;
