import React from "react";
import style from "./filterSearch.module.scss";
import searchIcon from "../../../../assets/Icon-search.png";
import { AiOutlineClose } from "react-icons/ai";
import { customSearch, customSearchEF } from "../../utils/style";
import Select from "react-select";

const FilterSearchSelect = ({
  options,
  onClose,
  value,
  onChange,
  placeholder,
}) => {
  return (
    <div className={style.search}>
      {value ? (
        <span className={style.cp_serchIcon} onClick={onClose}
        onKeyDown={onClose}
        tabIndex={0}>
          <AiOutlineClose />
        </span>
      ) : (
        <span className={style.cp_serchIcon}>
          <img src={searchIcon} alt="searchIcon" draggable="false"/>
        </span>
      )}
      <Select
        styles={customSearchEF}
        classNamePrefix={"create_position"}
        placeholder={placeholder}
        components={{
          DropdownIndicator: () => null,
          IndicatorSeparator: () => null,
        }}
        options={options}
        value={value ? options?.find((e) => e?.value === value) : null}
        onChange={onChange}
      />
    </div>
  );
};

export default FilterSearchSelect;
