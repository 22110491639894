import React from "react";
import style from "./tasktracker.module.css";

import MyTasks from "./myTasks/myTasks";
import { Route, Switch } from "react-router-dom";
import Header from "./commonComponent/Header";
import SideBar from "./commonComponent/SideBar";

export default function TaskTracker(props) {
  return (
    <div>
      <Header />
      <div className={style.mainSection}>
        <SideBar />
        <Switch>
          <Route path={"/taskTracker/MyTasks"} exact component={MyTasks} />
        </Switch>
      </div>
    </div>
  );
}
