import React, { useState } from "react";
import style from "./ResourceDetails.module.css";
import Header from "../component/Header/Header";
import Sidebar from "../component/Sidebar/Sidebar";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Nodata from "../component/Nodata/Nodata";
import AddPricing from "../component/AddPricing/AddPricing";
import PriceList from "../component/PriceList/PriceList";
import { decrypted_value, employeeId } from "../../../Utils/constants";
import {
  createAdditionalInformation,
  getAdditionalInformation,
  getResourceDetails,
  updateAdditionalInformation,
} from "../../../Utils/axios/priceRevisionApi";
import { validateEmployeeAccess } from "../../../Utils/axios/api";
import { useMutation, useQuery, useQueryClient } from "react-query";
import moment from "moment";
import Loading from "../component/Loading/Loading";
import { Toastify, ToastifyError } from "../../../App";
import { useParams } from "react-router-dom";
import { number } from "prop-types";

const ResourceDetails = (props) => {
  const [recentDetails, setRecentDetails] = useState({});
  const history = useHistory();
  const queryClient = useQueryClient();
  const [id, setId] = useState(1);
  const [formData, setFormData] = useState({
    lastClientRate: "",
    currentClientRate: "",
    isRevised: "",
    approveBy: "",
    approvedById: "",
    // contractType: "",
    // contractTypeId: "",
    reason: "",
    lastRevisionDate: "",
    reviewDateManually: "",
  });
  const [validate, setValidate] = useState(false);
  const [getAdditionalInfoId, setGetAdditionalInfoId] = useState(null);
  const [create, setCreate] = useState(true);

  const onsite = new URLSearchParams(history?.location?.search)?.get("onsite");
  const projectId = new URLSearchParams(history?.location?.search)?.get(
    "projectId"
  );
  console.log("params --- ", onsite);

  const { data, isLoading } = useQuery(
    [
      "getResourceDetails",
      decrypted_value(history?.location?.search, "clientId"),
      decrypted_value(history?.location?.search, "resourceId"),
    ],
    () =>
      getResourceDetails(
        decrypted_value(history?.location?.search, "clientId"),
        decrypted_value(history?.location?.search, "resourceId"),
        onsite,
        projectId
      )
  );

  const { data: getAdditionalInfo, refetch } = useQuery(
    [
      "getAdditionalInformation",
      decrypted_value(history?.location?.search, "clientId"),
      decrypted_value(history?.location?.search, "resourceId"),
    ],
    () =>
      getAdditionalInformation(
        decrypted_value(history?.location?.search, "clientId"),
        decrypted_value(history?.location?.search, "resourceId"),
        onsite,
        projectId
      )
  );

  const createPricingMutation = useMutation(createAdditionalInformation, {
    onSuccess: (res) => {
      if (res?.responseStatus?.statusCode === 1) {
        ToastifyError(res.responseData.message);
      } else {
        Toastify(res.responseData);
        queryClient.invalidateQueries("getResourceDetails");
        setId(3);
        refetch();
        setFormData({
          lastClientRate: "",
          currentClientRate: "",
          isRevised: "",
          approveBy: "",
          approvedById: "",
          // contractType: "",
          // contractTypeId: "",
          reason: "",
          lastRevisionDate: "",
          reviewDateManually: "",
        });
      }
    },

    onError: (err) => {
      ToastifyError("Something Went Wrong");
    },
  });

  const updatePricingMutation = useMutation(updateAdditionalInformation, {
    onSuccess: (res) => {
      if (res?.responseStatus?.statusCode === 1) {
        ToastifyError(res.responseData.message);
      } else {
        Toastify(res.responseData);
        queryClient.invalidateQueries("getResourceDetails");
        setId(3);
        setFormData({
          lastClientRate: "",
          currentClientRate: "",
          isRevised: "",
          approveBy: "",
          approvedById: "",
          // contractType: "",
          // contractTypeId: "",
          reason: "",
          lastRevisionDate: "",
          reviewDateManually: "",
        });
        refetch();
        setCreate(false)
      }
    },

    onError: (err) => {
      ToastifyError("Something Went Wrong");
    },
  });

  const { data: getValidateEmp } = useQuery(
    ["validateEmployeeAccess"],
    () => validateEmployeeAccess(parseInt(employeeId)),
    {
      refetchOnWindowFocus: false,
      removeAfterUnmount: true,
      cacheTime: 1000,
    }
  );

  const getVData = getValidateEmp?.map((i) => ({
    label: i?.elementCode,
    value: i?.empId,
  }));

  const handleAddAdditionalPricing = () => {
    if (!formData?.currentClientRate || !formData?.reviewDateManually) {
      setValidate(true);
      return;
    }
    if (formData?.isRevised && (!formData?.approvedById || !formData?.reason)) {
      setValidate(true);
      return;
    }
    if(!create && !formData?.reason) {
      setValidate(true);
      return;
    }
    setValidate(false);
    const data = {};
    if (formData?.approvedById) {
      data.approvedById = Number(formData?.approvedById);
    }
    if (formData?.contractEndDate) {
      data.contractEndDate = formData?.contractEndDate;
    }
    if (formData?.contractStartDate) {
      data.contractStartDate = formData?.contractStartDate;
    }
    if (formData?.currentClientRate) {
      data.currentClientRate = Number(formData?.currentClientRate);
    }
    if (formData?.isRevised) {
      data.isRevised =
        formData?.isRevised === "Done"
          ? true
          : formData?.isRevised === "Defer"
          ? false
          : null;
    }
    if (formData?.lastClientRate) {
      data.lastClientRate = Number(formData?.lastClientRate);
    }
    if (formData?.reason) {
      data.reason = formData?.reason;
    }
    if (formData?.lastRevisionDate) {
      data.lastRevisionDate = formData?.lastRevisionDate;
    }
    if (formData?.reviewDateManually) {
      data.reviewDateManually = formData?.reviewDateManually;
    }
    if (onsite === "false" && projectId !== null && onsite !== undefined) {
      data.projectId = projectId;
    }
    if (formData?.id) {
      data.id = Number(formData?.id);
    }

    data.clientId = Number(
      decrypted_value(history?.location?.search, "clientId")
    );
    data.resourceId = Number(
      decrypted_value(history?.location?.search, "resourceId")
    );
    data.onsite = onsite === "true" ? true : false;
    if (!create) {
      data.changeByEmpId = employeeId;
    }

    create
      ? createPricingMutation.mutate(data)
      : updatePricingMutation.mutate(data);
  };

  const handleSetAdditionalInfo = (id) => {
    setGetAdditionalInfoId(id);
    if (getAdditionalInfo && getAdditionalInfo?.length > 0) {
      const data = getAdditionalInfo?.filter((i) => i.id === id);
      setFormData({
        lastClientRate: data[0]?.lastClientRate,
        currentClientRate: data[0]?.currentClientRate,
        isRevised:
          data[0]?.isRevised === true
            ? "Done"
            : data[0]?.isRevised === false
            ? "Defer"
            : "",
        approveBy: data[0]?.approveBy,
        approvedById: data[0]?.approvedById,
        // contractType: data[0]?.contractType,
        // contractTypeId: data[0]?.contractTypeId,
        reason: data[0]?.reason,
        lastRevisionDate: data[0]?.lastRevisionDate,
        reviewDateManually: data[0]?.reviewDateManually,
        id: data[0]?.id,
      });
    }
    setId(2);
    setCreate(false);
  };

  return (
    <>
      {isLoading && <Loading />}
      <Header />
      <div className={style.resource_details_main_div}>
        <div className={style.sidebar_div}>
          <Sidebar />
        </div>
        <div className={style.main_div}>
          <div className={style.breadcrum}>
            <span
              onClick={() =>
                history.push({
                  pathname: "/price_listing",
                  state: { allData: props?.history?.location?.state },
                })
              }
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  history.push({
                    pathname: "/price_listing",
                    state: { allData: props?.history?.location?.state },
                  });
                }
              }}
              tabIndex={0}
              className={style.first_breadcrum}
            >
              Resource Pricing List
            </span>
            <span className={style.arrow}>{" > "}</span>
            <span className={style.second_breadcrum}>Resource Detail</span>
          </div>

          <div className={style.details_div}>
            <p className={style.details_heading}>Employee Details</p>
            <div className={style.second_div}>
              <div className={style.single_div}>
                <p className={style.resource_head}>Resource Name</p>
                <p className={style.resource_para}>
                  {data?.resourceName || "-"}
                </p>
              </div>

              <div className={style.single_div}>
                <p className={style.resource_head}>Resource Start Date</p>
                <p className={style.resource_para}>
                  {data?.resourceStartDate
                    ? moment(data?.resourceStartDate)?.format("DD-MMM-YY")
                    : "-"}
                </p>
              </div>

              {/* <div className={style.single_div}>
                <p className={style.resource_head}>Start Month</p>
                <p className={style.resource_para}>
                  {data?.resourceStartDate
                    ? moment(data?.resourceStartDate)?.format("MMM")
                    : "-"}
                </p>
              </div> */}

              <div className={style.single_div}>
                <p className={style.resource_head}>Technology</p>
                <p className={style.resource_para}>{data?.skill || "-"}</p>
              </div>

              <div className={style.single_div}>
                <p className={style.resource_head}>Experience</p>
                <p className={style.resource_para}>
                  {data?.experience
                    ? `${~~(data?.experience / 12)} Year ${
                        data?.experience % 12
                      } Month`
                    : "-"}
                </p>
              </div>
              {onsite === "true" && (
                <div className={style.single_div}>
                  <p className={style.resource_head}>Sales Rep</p>
                  <p className={style.resource_para}>{data?.salesRep || "-"}</p>
                </div>
              )}
              {onsite !== "true" && (
                <div className={style.single_div}>
                  <p className={style.resource_head}>Client Name</p>
                  <p className={style.resource_para}>
                    {data?.clientName || "-"}
                  </p>
                </div>
              )}
            </div>

            <div className={style.second_div}>
              {onsite === "true" && (
                <div className={style.single_div}>
                  <p className={style.resource_head}>Client Name</p>
                  <p className={style.resource_para}>
                    {data?.clientName || "-"}
                  </p>
                </div>
              )}

              <div className={style.single_div}>
                <p className={style.resource_head}>Contract Start Date</p>
                <p className={style.resource_para}>
                  {data?.contractStartDate
                    ? moment(data?.contractStartDate)?.format("DD-MMM-YY")
                    : "-"}
                </p>
              </div>

              <div className={style.single_div}>
                <p className={style.resource_head}>Contract End Date</p>
                <p className={style.resource_para}>
                  {data?.contractEndDate
                    ? moment(data?.contractEndDate)?.format("DD-MMM-YY")
                    : "-"}
                </p>
              </div>

              {/* <div className={style.single_div}>
                <p className={style.resource_head}>End Month</p>
                <p className={style.resource_para}>{data?.contractEndDate
                    ? moment(data?.contractEndDate)?.format("MMM")
                    : "-"}</p>
              </div> */}
              <div className={style.single_div}>
                <p className={style.resource_head}>Contract Type</p>
                <p className={style.resource_para}>
                  {data?.contractType || "-"}
                </p>
              </div>
            </div>

            {/* <div className={style.second_div}>
              
            </div> */}
          </div>

          <div className={style.button_div}>
            {getVData?.some((e) => {
              return e?.label === "359T2710D98693HX";
            }) && (
              <button
                onClick={() => {
                  setId(2);
                  data?.completeInfoResponse?.length > 0 &&
                    setRecentDetails({ ...data?.completeInfoResponse[0] });

                    setFormData({
                      lastClientRate: "",
                      currentClientRate: "",
                      isRevised: "",
                      approveBy: "",
                      approvedById: "",
                      // contractType: "",
                      // contractTypeId: "",
                      reason: "",
                      lastRevisionDate: "",
                      reviewDateManually: "",
                    });
                    setCreate(true)
                }}
                className={style.btn}
              >
                Add Pricing
              </button>
            )}
          </div>

          <div className={style.additional_div}>
            {id === 2 ? (
              <AddPricing
                formData={formData}
                setFormData={setFormData}
                recentDetails={recentDetails}
                validate={validate}
                create={create}
              />
            ) : getAdditionalInfo?.length === 0 ? (
              <Nodata />
            ) : id === 3 || getAdditionalInfo?.length > 0 ? (
              <PriceList
                data={getAdditionalInfo}
                handleSetAdditionalInfo={(id) => handleSetAdditionalInfo(id)}
              />
            ) : null}
          </div>

          {id === 2 && (
            <div className={style.button_div}>
              <button
                onClick={() => {
                  setId(3);
                  setFormData({
                    lastClientRate: "",
                    currentClientRate: "",
                    isRevised: "",
                    approveBy: "",
                    approvedById: "",
                    // contractType: "",
                    // contractTypeId: "",
                    reason: "",
                    lastRevisionDate: "",
                    reviewDateManually: "",
                  });
                }}
                className={style.btn2}
              >
                Cancel
              </button>

              <button
                onClick={() => {
                  handleAddAdditionalPricing();
                }}
                className={style.btn}
              >
                Done
              </button>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ResourceDetails;
