import React, { useState, useEffect } from "react";
import Header from "../../../taskTracker/commonComponent/Header";
import style from "./AddResourcesAllocationOnsite.module.css";
import Sidebar from "../../Clients/component/SideBar";
import cookie from "react-cookies";
import axios from "axios";
import DatePicker from "react-datepicker";
import calendar from "../../../../assets/Grievance portal/dateldpi.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import dropdownIcon from "../../../../assets/downIcon.png";
import { useHistory } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import { customStylesForRMS } from "../../Clients/component/style";
import constants, { employeeId } from "../../../../Utils/constants";
import Moment from "moment";
import { api } from "../../CommonApi/api";
import { get, post } from "../../CommonApi/axiosCall";
import CreatableSelect from "react-select/creatable";

const AddResourcesAllocationOnsite = () => {
  const CustomInput = React.forwardRef((props, ref) => {
    return (
      <div onClick={props.onClick}>
        <label
          ref={ref}
          style={{ fontSize: "15px", fontWeight: 500, marginTop: "5px" }}
        >
          {props.value || props.placeholder}
        </label>
        <img
          type="image/svg+xml"
          width="20px"
          style={{ position: "absolute", right: "5%", top: "28%", zIndex: "0" }}
          src={calendar}
          onClick={props.onClick}
          alt=""
        />
      </div>
    );
  });
  const [startDate, setStartDate] = useState(0);
  const [endDate, setEndDate] = useState(0);
  const [projectList, setProjectList] = useState([]);
  const [error, setError] = useState(false);
  const history = useHistory();
  const [expectedEndDate, setExpectedEndDate] = useState(0);
  const [disable, setDisable] = useState(false);
  const [state, setState] = useState({
    additionalSkills: [],
    allocationDate: "",
    deallocationDate: "",
    empId: 0,
    clientId: 0,
    skill: "",
    clientInformedDate: "",
    clientNotificationDate: "",
    partnerInformedDate: "",
    isPartnerInformed: null,
    isClientInformed: null,
    candidateInformedDate: null,
    clientInformedReason: null,
    salesPersonEmpId: 0,
    resourceSource: false,
    clientNotificationReasonId: null,
  });

  const handleOnChange = (field, value) => {
    setState((prevState) => ({
      ...prevState,
      [field]: value, 
    }));
  };

  const customStyles = {
    control: () => ({
      boxShadow: "0px 3px 9px #00000029",
      borderRadius: "9px",
      background: "transparent 0% 0% no-repeat padding-box",
      backgroundColor: "#f5f6fa",
      minWidth: "inherit",
      margin: "9px 1.5% -2px 0%",
      minHeight: "38px",
      fontWeight: 500,
      // width:"90%",
      outline: "none",
      cursor: "pointer",
    }),
    option: (provided, state) => ({
      ...provided,
      "&:hover": {
        backgroundColor: "rgb(78 206 199)",
      },
      backgroundColor: state.isSelected ? "rgb(78 206 199)" : "white",
    }),
    clearIndicator: (base) => ({
      ...base,
      display: "none",
    }),

    placeholder: () => ({
      display: "none",
    }),
  };

  const [clientData, setClientData] = useState([]);
  const [additionalSkill, setAdditionalSkill] = useState([]);
  const [skillList, setSkillList] = useState([]);
  const [employeeList, setEmployeeList] = useState([]);
  const [salesPerson, setSalesperson] = useState([]);
  const [notificationData, setNotificationData] = useState([]);
const[informationDate,setInformationDate]=useState("")
  const CustomInput1 = () => {
    return (
      <span>
        <img
          alt="dropdownIcon"
          src={dropdownIcon}
          style={{ position: "absolute", top: "32%", right: "3%" }}
          height="10"
        />
      </span>
    );
  };

  employeeList?.sort((a, b) =>
    a.name?.toUpperCase() > b.name?.toUpperCase() ? 1 : -1
  );
  salesPerson?.sort((a, b) =>
    a.name?.toUpperCase() > b.name?.toUpperCase() ? 1 : -1
  );
  additionalSkill?.sort((a, b) =>
    a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1
  );

  const url = constants.apiBaseUrl;
  const baseUrl = constants.apiBaseUrlResource;
  const clientContract = process.env.REACT_APP_CLIENT_CONTRACT_URL;

  const getEmployeeList = () => {
    axios
      .get(baseUrl + "/delivery-employee-list", {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
          employeeId: employeeId,
        },
      })
      .then((res) => {
        setEmployeeList(res?.data?.responseData);
      })
      .catch((err) => {
        return err;
      });
  };

  const getAdditionalSkills = () => {
    let url = api.getAllSkills;
    get(url)
      .then((res) => {
        setAdditionalSkill(res.data.responseData);
      })
      .catch((err) => {});
  };

  const getSalespersonName = () => {
    axios
      .get(clientContract + "/get-sales-reprsentative-list", {
        headers: {
          "Access-Control-Allow-Origin": "*",
          // Authorization: ` ${cookie.load("token")}`,
          Authorization: null,
          employeeId: employeeId,
        },
      })
      .then((res) => {
        setSalesperson(res.data.responseData);
      })
      .catch((err) => {
        return err;
      });
  };
  const getSkills = () => {
    let url = api.getAllSkills;
    get(url)
      .then((res) => {
        setSkillList(res.data.responseData);
      })
      .catch((err) => {});
  };

  const getProjectSkill = () => {
    let url = api.getFilteredProject;

    get(url)
      .then((res) => {
        setProjectList(res.data.responseData);
      })
      .catch((err) => {});
  };

  const getNotificationReason = () => {
    let url = api.getNotificationReasons;

    get(url)
      .then((res) => {
        setNotificationData(res?.data?.responseData);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    const modifiedClientNotificationDate = removeTimezoneOffset(state.clientNotificationDate);
    setState(prevState => ({
      ...prevState,
      clientNotificationDate: modifiedClientNotificationDate,
    }));

  }, [state.clientNotificationDate]);

  const removeTimezoneOffset=(momentObj)=> {
    if (momentObj) {
      let timestampWithTimezone = Moment(momentObj)?.format("YYYY-MM-DDTHH:mm:ss.SSSZ");
      let dateWithTimezone = new Date(timestampWithTimezone);
  
      if (isNaN(dateWithTimezone?.getTime())) {
        return null;
      }
  
      let dateWithoutTimezone = new Date(dateWithTimezone?.getTime() - (dateWithTimezone?.getTimezoneOffset() * 60000));
  
      if (isNaN(dateWithoutTimezone?.getTime())) {
        return null;
      }
  
      let timestampWithoutTimezone = dateWithoutTimezone.toISOString().slice(0, -5) + '.000';
      return timestampWithoutTimezone;
    }
  
    return "";
  }
  
  useEffect(() => {
    getAdditionalSkills()
    getClientData();
    getProjectSkill();
    getEmployeeList();
    getSkills();
    getSalespersonName();
    getNotificationReason();
  }, []);

  const informData = [
    { label: "Yes", value: true },
    { label: "No", value: false },
  ];

  const onInputChange = (e) => {
    if (e.target.name === "projectSkill") {
      setState({
        ...state,
        skill: e.target.value,
      });
    } else if (e.target.name === "clientName") {
      setState({
        ...state,
        clientId: e.target.value,
      });
    } else if (e.target.name === "startDate") {
      setState({
        ...state,
        allocationDate: e.target.value,
      });
    } else if (e.target.name === "endDate") {
      setState({
        ...state,
        deallocationDate: e.target.value,
      });
    } else if (e.target.name === "additionalSkills") {
      setState({
        ...state,
        additionalSkills: e.target.value,
      });
    }
  };

// Parse the given date string
const givenDate = new Date(endDate);
// Get the current date and time
const currentDate = new Date();
// Set the hours, minutes, and seconds of the given date to match the current date and time
givenDate.setHours(currentDate.getHours());
givenDate.setMinutes(currentDate.getMinutes());
givenDate.setSeconds(currentDate.getSeconds());

// Format the resulting date as a string
const resultDateString = givenDate?.toISOString();

  const handleRedirect = () => {
    setTimeout(() => {
      history.push("/ResourcesAllocationOnsite");
    }, 2000);
  };

  const getClientData = () => {
    let url = api.getClientFilteredData;
    url += `?Status=Active`;
    get(url)
      .then((res) => {
        setClientData(res.data.responseData.clientFilterDataResponse);
      })
      .catch((err) => {});
  };
  const onSubmit = (e) => {
    e.preventDefault();

    if (
      !startDate ||
      !state?.empId ||
      !state.clientId ||
      !state.skill ||
      !state?.salesPersonEmpId
    ) {
      setError(true);
      return false;
    }
    setDisable(true);
    const data = {
      additionalSkills: state?.additionalSkills?.map((i) => i?.value),
      allocationDate: Moment(startDate).format("YYYY-MM-DD hh:mm"),
      deallocationDate: endDate ?Moment(resultDateString).format("YYYY-MM-DDTHH:mm:ss.SSSZ") : " ",
      empId: state?.empId,
      clientId: state.clientId,
      skill: state.skill,
      expectedEndDate: expectedEndDate ? expectedEndDate : "",
      clientInformedDate: state.clientInformedDate,
      clientNotificationDate: state.clientNotificationDate?state.clientNotificationDate:"",
      partnerInformedDate: state?.partnerInformedDate,
      isPartnerInformed: state?.isPartnerInformed,
      isClientInformed: state?.isClientInformed,
      candidateInformedDate: state?.candidateInformedDate,
      salesPersonEmpId: state?.salesPersonEmpId,
      resourceSource: state?.resourceSource,
      clientNotificationReasonId: state?.clientNotificationReasonId,
    };

    let url = api.addResourceOnsite;

    post(url, data)
      .then((res) => {
        setDisable(false);
        if (res.data.responseStatus.statusCode == 1) {
          notify1();
          setDisable(false);
        } else {
          notify();
          handleRedirect();
          setDisable(true);
        }
      })
      .catch((err) => {
        setDisable(false);
      });
  };

  const notify = () =>
    toast("Data Submitted Successfully", {
      position: "top-center",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: false,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
      closeButton: false,
    });

  const notify1 = () =>
    toast("Employee is already allocated", {
      position: "top-center",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: false,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
      closeButton: false,
    });

  const notificationOption = notificationData?.map((item) => ({
    value: item?.id,
    label: item?.name,
  }));

  const getemployeeList = employeeList?.map((i) => ({
    value: i.id,
    label: `${i.name}  (${i.code})`,
  }));

  const getAdditionalSkillsList = additionalSkill?.map((i) => ({
    value: i.id,
    label: i.name,
  }));

  const getSalesManListing = salesPerson?.map((i) => ({
    value: i.id,
    label: i.name,
  }));
  const resourceSource = [
    { label: "Handover", value: true },
    { label: "Self", value: false },
  ];
  return (
    <>
      <Header />
      <div className={style.main_client_body}>
        <Sidebar />

        <form className={style.table_div} onSubmit={onSubmit}>
          <div className={style.table_div_head}>
            <div
              className={style.breadscrumb}
              style={{ padding: "6px 0px" }}
            ></div>
            <div style={{ padding: "10px 0px", fontWeight: "700" }}>
              Resource Allocation Onsite
            </div>
            <div className={style.form_div}>
              <form id="create_task_id" className={style.gridContainer}>
                <div className={style.Cu_row1}>
                  <div className={style.cu_col2}>
                    <p className={style.cu_label}>
                      Client Name<span style={{ color: "red" }}>*</span>
                    </p>
                    <select
                      onChange={onInputChange}
                      name="clientName"
                      className={style.cu_field2}
                    >
                      <option hidden>Select Client</option>
                      {clientData.map((val) => {
                        return (
                          <option value={val.clientid}>{val?.name}</option>
                        );
                      })}
                    </select>
                    {error && !state.clientId ? (
                      <p className={style.error}>Please Select Client Name</p>
                    ) : null}
                  </div>

                  <div className={style.cu_col1}>
                    <p
                      style={{ paddingBottom: "8px" }}
                      className={style.cu_label}
                    >
                      Employee Name<span style={{ color: "red" }}>*</span>
                    </p>
                    <Select
                      styles={customStylesForRMS}
                      classNamePrefix={"create_position"}
                      placeholder="Search By Client Name"
                      components={{
                        DropdownIndicator: () => CustomInput1(),
                        IndicatorSeparator: () => null,
                      }}
                      onChange={(e) => {
                        setState({ ...state, empId: e?.value });
                      }}
                      options={getemployeeList}
                    />
                    {error && !state?.empId ? (
                      <p className={style.error}>Please Select Employee Name</p>
                    ) : null}
                  </div>

                  <div className={style.cu_col3}>
                    <p className={style.cu_label}>
                      Technology<span style={{ color: "red" }}>*</span>
                    </p>
                    <select
                      onChange={onInputChange}
                      name="projectSkill"
                      className={style.cu_field2}
                    >
                      <option hidden>Select Technology</option>
                      {skillList.map((val) => {
                        return <option value={val.id}>{val?.name}</option>;
                      })}
                    </select>
                    {error && !state.skill ? (
                      <p className={style.error}>Please Select Technology</p>
                    ) : null}
                  </div>
                </div>
                <div className={style.Cu_row1} style={{ marginTop: "10px" }}>
                  <div className={style.cu_col1}>
                    <p className={style.cu_label}>
                      Project Start Date<span style={{ color: "red" }}>*</span>
                    </p>
                    <div className={style.cu_field2}>
                      <DatePicker
                        dateFormat="dd MMM yy"
                        // minDate={new Date()}
                        selected={startDate}
                        startDate={null}
                        onChange={(date) => setStartDate(date)}
                        disabledKeyboardNavigation
                        defaultValue={null}
                        customInput={<CustomInput />}
                      />
                    </div>
                    {error && !startDate ? (
                      <p className={style.error}>
                        Please Select Project Start Date{" "}
                      </p>
                    ) : null}
                  </div>
                  <div className={style.cu_col2}>
                    <p className={style.cu_label}>Project End Date</p>
                    <div className={style.cu_field2}>
                      <DatePicker
                        dateFormat="dd MMM yy"
                        selected={endDate}
                        // startDate={}
                        minDate={startDate}
                        onChange={(date) => setEndDate(date)}
                        disabledKeyboardNavigation
                        defaultValue={null}
                        customInput={<CustomInput />}
                      />
                    </div>
                  </div>
                  <div className={style.cu_col3}>
                    <p className={style.cu_label}>Expected End Date</p>
                    <div className={style.cu_field2}>
                      <DatePicker
                        dateFormat="dd MMM yy"
                        selected={expectedEndDate}
                        startDate={null}
                        onChange={(date) => setExpectedEndDate(date)}
                        disabledKeyboardNavigation
                        defaultValue={null}
                        customInput={<CustomInput />}
                      />
                    </div>
                  </div>
                </div>
                <div className={style.Cu_row1} style={{ marginTop: "10px" }}>
                  <div className={style.cu_col1}>
                    <p className={style.cu_label}>Client Notification Date</p>
                    <div className={style.cu_field2}>
                      <DatePicker
                        dateFormat="dd MMM yy"
                        startDate={null}
                        selected={informationDate}
                        onChange={(date) => {
                          handleOnChange("clientNotificationDate", date);
                          setInformationDate(date);
                        }}
                        
                        disabledKeyboardNavigation
                        defaultValue={null}
                        customInput={<CustomInput />}
                      />
                    </div>
                  </div>
                  <div className={style.cu_col2}>
                    <p className={style.cu_label}>Partner Informed</p>

                    <Select
                      styles={customStylesForRMS}
                      classNamePrefix={"create_position"}
                      placeholder="Search By Client Name"
                      components={{
                        DropdownIndicator: () => CustomInput1(),
                        IndicatorSeparator: () => null,
                      }}
                      onChange={(e) => {
                        setState({
                          ...state,
                          isPartnerInformed: e?.value,
                          partnerInformedDate: null,
                        });
                      }}
                      options={informData}
                    />
                  </div>
                  <div className={style.cu_col3}>
                    <p className={style.cu_label}>Partner Informed Date</p>
                    <div
                      className={
                        state?.isPartnerInformed === null
                          ? style.cu_field22
                          : style.cu_field2
                      }
                    >
                      <DatePicker
                        disabled={state?.isPartnerInformed === null}
                        dateFormat="dd MMM yy"
                        selected={state?.partnerInformedDate ?? null}
                        startDate={null}
                        onChange={(date) =>
                          handleOnChange("partnerInformedDate", date)
                        }
                        disabledKeyboardNavigation
                        customInput={<CustomInput />}
                      />
                    </div>
                  </div>
                </div>

                <div className={style.Cu_row1} style={{ marginTop: "10px" }}>
                  <div className={style.cu_col1}>
                    <p className={style.cu_label}>Client Informed</p>

                    <Select
                      styles={customStylesForRMS}
                      classNamePrefix={"create_position"}
                      placeholder="Search By Client Name"
                      components={{
                        DropdownIndicator: () => CustomInput1(),
                        IndicatorSeparator: () => null,
                      }}
                      onChange={(e) => {
                        setState({
                          ...state,
                          isClientInformed: e?.value,
                          clientInformedDate: null,
                          clientInformedReason: null,
                        });
                      }}
                      options={informData.slice(0, 2)}
                    />
                  </div>
                  <div className={style.cu_col2}>
                    <p className={style.cu_label}>
                      {" "}
                      Estimated Client Informed Date
                    </p>
                    <div className={style.cu_field2}>
                      <DatePicker
                        dateFormat="dd MMM yy"
                        selected={state?.clientInformedDate}
                        startDate={null}
                        onChange={(date) =>
                          handleOnChange("clientInformedDate", date)
                        }
                        disabledKeyboardNavigation
                        defaultValue={null}
                        customInput={<CustomInput />}
                      />
                    </div>
                    {/* </>
                    ) : null} */}
                  </div>
                  <div className={style.cu_col3}>
                    <p className={style.cu_label}>Client Notification Reason</p>
                    {/* <textarea
                      name="postContent"
                      rows={2}
                      onChange={(e) =>
                        handleOnChange(`clientInformedReason`, e?.target.value)
                      }
                      style={{
                        outline: "none",
                        width: "98%",
                        paddingLeft: "5px",
                        marginTop: "10px",
                      }}
                      onKeyUp={(e) => {
                        if (
                          e.ctrlKey &&
                          (e.key === "c" ||
                            e.key === "x" ||
                            e.key === "Backspace")
                        ) {
                          setState({
                            ...state,
                            clientInformedReason: e.target.value,
                          });
                        }
                      }}
                    /> */}

                    <Select
                      styles={customStylesForRMS}
                      classNamePrefix={"create_position"}
                      placeholder="Search Reason"
                      components={{
                        DropdownIndicator: () => CustomInput1(),
                        IndicatorSeparator: () => null,
                      }}
                      value={notificationOption?.find(
                        (e) => e.value === state?.clientNotificationReasonId
                      )}
                      onChange={(e) =>
                        setState({
                          ...state,
                          clientNotificationReasonId: e?.value,
                        })
                      }
                      options={notificationOption}
                    />
                  </div>
                </div>
                <div className={style.Cu_row1} style={{ marginTop: "10px" }}>
                  <div className={style.cu_col1}>
                    <p className={style.cu_label}>Candidate Informed Date</p>
                    <div className={style.cu_field2}>
                      <DatePicker
                        dateFormat="dd MMM yy"
                        selected={state?.candidateInformedDate ?? null}
                        startDate={null}
                        onChange={(date) =>
                          handleOnChange("candidateInformedDate", date)
                        }
                        disabledKeyboardNavigation
                        customInput={<CustomInput />}
                      />
                    </div>
                  </div>
                  <div className={style.cu_col2}>
                    <p className={style.cu_label}>
                      Sales Person <span style={{ color: "red" }}>*</span>
                    </p>
                    <Select
                      styles={customStylesForRMS}
                      classNamePrefix={"create_position"}
                      placeholder="Search By Client Name"
                      components={{
                        DropdownIndicator: () => CustomInput1(),
                        IndicatorSeparator: () => null,
                      }}
                      onChange={(e) => {
                        setState({
                          ...state,
                          salesPersonEmpId: e?.value,
                        });
                      }}
                      options={getSalesManListing}
                    />
                    <p
                      className={
                        error && !state?.salesPersonEmpId
                          ? style.errorText
                          : style.errorTextHidden
                      }
                    >
                      Please Select Sales Person
                    </p>
                  </div>
                  <div className={style.cu_col3}>
                    <p className={style.cu_label}>Resource Source</p>
                    <Select
                      styles={customStylesForRMS}
                      classNamePrefix={"create_position"}
                      placeholder="Search By Client Name"
                      components={{
                        DropdownIndicator: () => CustomInput1(),
                        IndicatorSeparator: () => null,
                      }}
                      value={resourceSource?.find(
                        (e) => e.value === state?.resourceSource
                      )}
                      onChange={(e) => {
                        setState({
                          ...state,
                          resourceSource: e?.value,
                        });
                      }}
                      options={resourceSource}
                    />
                  </div>
                </div>
                <div className={style.Cu_row1} style={{ marginTop: "-15px" }}>
                  <div className={style.cu_col1}>
                    <p className={style.cu_label}>Additional Core Skill</p>
                      <CreatableSelect
                        menuPlacement="top"
                        isMulti
                        styles={customStyles}
                        classNamePrefix={"create_position"}
                        components={{
                          DropdownIndicator: () => null,
                          IndicatorSeparator: () => null,
                        }}
                        onChange={(selectedOption) => {handleOnChange(`additionalSkills`, selectedOption)}}
                        isValidNewOption={() => false}
                        options={getAdditionalSkillsList}
                        // formatCreateLabel={(userInput) => `+ Add "${userInput}"`}
                      />
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className={style.btn_div}>
            <button
              onClick={onSubmit}
              className={style.submitbtn}
              disabled={disable}
            >
              Submit
            </button>
          </div>
        </form>

        <ToastContainer />
      </div>
    </>
  );
};

export default AddResourcesAllocationOnsite;
