import React, { useState, useEffect } from "react";
import style from "./Hr.module.css";
import StarRating from "../interview/viewInterview/StartRating";
import { useMutation, useQuery } from "react-query";
import {
  uploadResume,
  deleteResume,
  skillsForHrInterveiw,
  submitInterviewFeedback,
} from "../../../../Utils/axios/api";
import moment from "moment";
import Loader from "../../../../GenericComponent/Loader/Loader";
import { Modal } from "react-responsive-modal";

function HrFeedback({
  fieldName,
  interviewId,
  getInterviewBy,
  setCheckSubmit,
}) {
  const [disabled, setDisabled] = useState(false);
  const [show, setShow] = useState(false);
  const [err, setErr] = useState(false);
  const [validate, setValidate] = useState(false);
  const [uploadImage, setUploadImage] = useState(null);
  const [demo, setDemo] = useState([]);
  console.log(fieldName);

  // const demo = [
  //   {
  //     fieldName: "Communication skills",
  //   },
  //   {
  //     fieldName: "Thought Clarity",
  //   },
  //   {
  //     fieldName: "Analytical skills",
  //   },
  //   {
  //     fieldName: "Learning Capabilities",
  //   },
  //   {
  //     fieldName: "Learning Skills",
  //   },
  //   {
  //     fieldName: "Stress Management",
  //   },
  //   {
  //     fieldName: "Energy Level",
  //   },
  //   {
  //     fieldName: "Cultural Fitment",
  //   },
  //   {
  //     fieldName: "Behaviour",
  //   },
  // ];

  useEffect(() => {
    setDemo(fieldName);
  },[fieldName]);
  const [feedbackRating, setFeedbackRating] = React.useState([]);
  const [formData, setFormData] = useState([]);

  // useEffect(() => {
  //   setFeedbackRating(fieldName);
  // }, [fieldName]);

  const submitInterviewFeedbackMutation = useMutation(submitInterviewFeedback, {
    onSuccess: (data) => {
      setShow(true);
      setErr(false);
      setDisabled(false);
    },
    onError: (error) => {
      setErr(true);
      setShow(true);
      setDisabled(false);
    },
  });

  const onSubmitHandler = () => {
    setDisabled(true);

    if (formData?.overAll === undefined || formData?.status === undefined) {
      setValidate(true);
      setDisabled(false);
      return;
    } else {
      setValidate(false);

      setTimeout(() => {
        let feedbackData = {
          interviewId: interviewId,
          interviewFeedbackStatusId: formData?.status,
          snapshot : uploadImage,
          additionalComments : formData?.overAll
        };
        if (formData?.status !== 6) {
          feedbackData.skillWiseRating = feedbackRating?.filter(item => item.rating !== undefined);
        }
        // sumbitMutation.mutate([
        //   {
        //     feedbackName: "overall feedback",
        //     feedback: formData?.overAll,
        //     interviewId: interviewId,
        //   },
        //   {
        //     feedbackName: "status",
        //     feedback: formData?.status,
        //     interviewId: interviewId,
        //   },
        //   {
        //     feedbackName: "uploadSnapshot",
        //     feedback: uploadImage,
        //     interviewId: interviewId,
        //   },
        //   ...feedbackData,
        // ]);
        submitInterviewFeedbackMutation.mutate(feedbackData);
      }, 1000);
    }
  };
  const uploadResumeMutation = useMutation(uploadResume, {
    onSuccess: (data) => {
      setUploadImage(data.toString());
      setDisabled(false);
    },
  });
  const handleImage = (e) => {
    uploadResumeMutation.mutate(e.target.files[0]);
    setDisabled(true);
  };

  return (
    <div className={style.HrFeedback}>
      {disabled ? <Loader /> : null}

      <p>Interviewer Feedback</p>
      <p>
        {getInterviewBy?.candidateName} |{" "}
        {/* {getInterviewBy?.interviewer} |{" "} */}
        {getInterviewBy?.profileName} |{" "}
        {moment(getInterviewBy?.dateOfInterView).format("DD MMM YY")}
      </p>
      {/*  */}
      <div className={style.card}>
        <p className={style.title}>Feedback</p>
        <div className={style.cardDiv}>
          {formData?.status !== 6 && (
            <>
              <p>
                Skills Rating (1-10): 1-3 Poor, 4-5 Average, 6-7 Good, 8-10
                Excellent
              </p>
              {demo?.map((item, index) => {
                return (
                  <div key={index} className={style.feedback}>
                    <p>{item.skillName}</p>
                    <StarRating
                      feedbackRating={feedbackRating}
                      setFeedbackRating={setFeedbackRating}
                      item={item}
                    />
                  </div>
                );
              })}
            </>
          )}

          <div className={style.overAllfeedback}>
            <p>
              {formData?.status !== 6
                ? "Overall Feedback"
                : "Notes"}{" "}
              <span className={style.mand}>*</span>
            </p>
            <textarea
              className={style.textArea}
              placeholder={
                formData?.status !== 7
                  ? "Enter your feedback"
                  : ""
              }
              onChange={(e) => {
                setFormData({ ...formData, overAll: e.target.value });
              }}
            />
            <p
              className={
                validate && !formData?.overAll
                  ? style.errorText
                  : style.errorTextHidden
              }
            >
              {"Please enter Overall Feedback"}
            </p>
          </div>
          <div className={style.radio}>
            <div>Status: <span style={{color: "red"}}>*</span></div>

            <div className={style.radioitem}>
              <input
                type="radio"
                id="test2"
                name="radio-group"
                onChange={() =>
                  setFormData({ ...formData, status: 1 })
                }
              />
              <label for="test2">Shortlisted</label>
            </div>
            <div className={style.radioitem}>
              <input
                type="radio"
                id="test3"
                name="radio-group"
                onChange={() =>
                  setFormData({ ...formData, status: 2 })
                }
              />
              <label for="test3">Rejected</label>
            </div>
            <div className={style.radioitem}>
              <input
                type="radio"
                id="test4"
                name="radio-group"
                onChange={() => setFormData({ ...formData, status: 3 })}
              />
              <label for="test4">Hold</label>
            </div>
            <div className={style.radioitem}>
              <input
                type="radio"
                id="test5"
                name="radio-group"
                onChange={() =>
                  setFormData({ ...formData, status: 6 })
                }
              />
              <label for="test5">Did not happen</label>
            </div>
          </div>
          <p
            className={
              validate && !formData?.status
                ? style.errorText
                : style.errorTextHidden
            }
          >
            {"Please Select Status"}
          </p>
          <div className={style.Cu_row1}>
            <div style={{ width: "10rem" }}>
              <p>Upload Snapshot</p>
            </div>
            {uploadImage ? (
              <div className={style.imageupload}>
                <label for="file-input">
                  <img
                    id="image"
                    src={uploadImage}
                    alt="resumeName"
                    style={{
                      width: "4rem",
                      height: "3rem",
                      border: "1px solid black",
                      cursor: "pointer",
                    }}
                    // onClick={() => setShowImageProfile(true)}
                  />
                </label>
                <p
                  onClick={() => {
                    // setFormData({ ...formData, resumeUrl: "" });
                    setUploadImage(null);
                    deleteResume(uploadImage);
                  }}
                  className={style.rmpdfcross}
                >
                  x
                </p>
              </div>
            ) : (
              <div style={{ width: "22rem" }}>
                <input
                  id="file-input"
                  className={style.upload_resume}
                  type="file"
                  accept="image/*"
                  onChange={(e) => {
                    handleImage(e);
                  }}
                />
              </div>
            )}
          </div>
          <div className={style.warn}>
            <p>
              <span className={style.mand}>*</span>NS - Candidate does not have
              skill{" "}
            </p>
            <p>
              <span className={style.mand}>*</span>NE - Skill not evaluated by
              interviewer
            </p>
          </div>
        </div>
      </div>
      {/*  */}
      <div className={style.btn}>
        {/* <button className={style.CancelBtn}>Cancel</button> */}
        <button className={style.SumbitBtn} onClick={onSubmitHandler} disabled={disabled}>
          Submit Feedback
        </button>
      </div>
      <Modal
        open={show}
        center
        onClose={() => {setShow(false); setCheckSubmit(true);}}
        showCloseIcon={false}
        classNames={{
          modal: "reactmodal",
        }}
      >
        <div className={style.modal}>
          <p>
            {err
              ? "Internal Server Error."
              : "Feedback Submitted Successfully."}
          </p>
          <button
            onClick={() => {
              setShow(false);
              // window.open("about:blank", "_self");
              // window.close();
              setCheckSubmit(true);
            }}
          >
            OK
          </button>
        </div>
      </Modal>
    </div>
  );
}

export default HrFeedback;
