import React, { useEffect, useState } from "react";
import style from "./AppraisalCycleEmployeeListComponent.module.css";
import { NavLink } from "react-router-dom";
import moment from "moment";
import Select from "react-select";
import searchIcon from "../../../../../../assets/Icon-search.png";
import deleteIcon from "../../../../assets/delete-icon.png";
import { customSearchACE } from "./style";
import { AiOutlineClose } from "react-icons/ai";
import { FaRegComments, FaEye } from "react-icons/fa";
import { FaComments } from "react-icons/fa";
import { ImLink } from "react-icons/im";
import Modal from "react-responsive-modal";
import HrHeadCom from "../../../../commonComponent/EmployeeDetails_apprHistory/CurrentProposedHR/HrHeadComments/HrHeadCom";
import ProjectComments from "../../../../commonComponent/ProjectComments/ProjectComments";
import FilterListIcon from "@mui/icons-material/FilterList";
import { FiDownload } from "react-icons/fi";
import axios from "axios";
import { addCommas } from "../../../../commonComponent/utils/AddCommas";
import { IoChatbubblesSharp } from "react-icons/io5";

import {
  deleteAppraisal,
  getCombinationFilterList,
  getEmployeesForAppraisal,
  holdAppraisal,
  createAppraisal,
  getDepartmentList,
  getEmployeeList,
  appraisalSendBack,
  appraisalApproved,
} from "../../../../../../Utils/axios/AppraisalApi";
import { useMutation, useQuery } from "react-query";
import {
  useLocation,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import Pagination from "../../../../commonComponent/Pagination/Pagination";
import AppraisalFilter from "../AppraisalCycleEmployeListFilter/AppraisalFilter";
import {
  notifyError,
  notifySuccess,
} from "../../../../../atsDashboard/utils/notify";
import AppraisalLoader from "../../../../commonComponent/Loader/AppraisalLoader";
import CustomExcelFileDownload from "../../../../commonComponent/ExcelDownload/CommonExcelDownload";

const AppraisalCycleEmployeeListComponent = () => {
  const url = process.env.REACT_APP_APPRAISAL_SYSTEM_URL;
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [pcModalIsOpen, setPCModalIsOpen] = useState(false);
  // Filter
  const [filterApplied, setFilterApplied] = useState(false);
  const location = useLocation();
  const [currentPage, setCurrentPage] = useState(
    location?.state?.currentPage ? location?.state?.currentPage : 1
  );

  const [pageCount, setPageCount] = useState(1);
  const [render, setRender] = useState(false);
  const [activeDept, setActiveDept] = useState(false);
  const [activeEmp, setActiveEmp] = useState(false);
  const [selectedDept, setSelectedDept] = useState(null);
  const [selectedEmp, setSelectedEmp] = useState(null);
  const [showError, setShowError] = useState({
    awardError: false,
    promotionError: false,
    increasedAmountError: false,
    hrCommentError: false,
    holdCommentError: false,
  });
  const [editAward, setEditAward] = useState("");
  const [editPromotion, setEditPromotion] = useState("");
  const [editIncreasedAmount, setEditIncreasedAmount] = useState("");
  const [editIncreasedPercent, setEditIncreasedPercent] = useState("");
  const [editApplicableDate, setEditApplicableDate] = useState();
  const [editCoreSalaryM, setEditCoreSalaryM] = useState("");
  const [editCoreSalaryA, setEditCoreSalaryA] = useState("");
  const [editFullSalaryM, setEditFullSalaryM] = useState("");
  const [editFullSalaryA, setEditFullSalaryA] = useState("");
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [departmentList, setDepartmentList] = useState(null);

  // Create Appraisal Data
  const [award, setAward] = useState("");
  const [promotion, setPromotion] = useState("");
  const [increasedAmount, setIncreasedAmount] = useState("");
  const [increasedPercent, setIncreasedPercent] = useState("");
  const [applicableDate, setApplicableDate] = useState("");
  const [coreSalaryM, setCoreSalaryM] = useState("");
  const [fullSalaryM, setFullSalaryM] = useState("");
  const [coreSalaryA, setCoreSalaryA] = useState("");
  const [fullSalaryA, setFullSalaryA] = useState("");
  const [aAMModalIsOpen, setaAMModalIsOpen] = useState(false);
  const [appraisalModalData, setAppraisalModalData] = useState({});
  const [addEdit, setAddEdit] = useState({ add: false, edit: false });
  const [empData, setEmpData] = useState(null);
  const [appraisalModalCheckBoxes, setAppraisalModalCheckBoxes] = useState({
    sa1: false,
  });
  const [isIncentivesAdded, setIsIncentivesAdded] = useState(false);
  const [incentivesAdded, setIncentivesAdded] = useState(null);
  const [notInitiallyAdded, setNotInitiallyAdded] = useState(false);

  const [cusDate, setCusDate] = useState();

  // Getting Department List
  const { data: departments } = useQuery(["departments"], () =>
    getDepartmentList()
  );
  useEffect(() => {
    setDepartmentList(
      departments?.map((department) => {
        return { value: department.id, label: department.name };
      })
    );
  }, [departments]);

  // Employee List Filter
  const [employeeListFilterObj, setEmployeeListFilterObj] = useState([]);
  const { data: employeeListFilter } = useQuery(["employeeListFilter"], () =>
    getEmployeeList()
  );
  useEffect(() => {
    setEmployeeListFilterObj(
      employeeListFilter?.map((employee) => {
        return {
          value: employee.id,
          label: `${employee.name} (${employee.employeeCode})`,
        };
      })
    );
  }, [employeeListFilter]);
  const { cycleName, cycleId } = useParams();

  const [filterData, setFilterData] = useState(
    location?.state?.filterData
      ? location?.state?.filterData
      : {
          // status: [],
          appraisalStatus: [],
          bond: null,
          client: [],
          empType: [],
          location: [],
          // promotion: [],
          reportingManager: [],
          reward: [],
          technology: [],
        }
  );
  const [filter, setFilter] = useState({
    appraisalCycleId: cycleId,
    appraisalStatus: [],
    bond: null,
    client: [],
    deptId: null,
    empType: [],
    employeeId: null,
    location: [],
    // promotion: [],
    reportingManager: [],
    reward: [],
    technology: [],
  });
  // Appraisal System All API Integration

  // getEmployeesList of that particular cycle
  const [employeeListObj, setEmployeeListObj] = useState([]);
  const [totalCount, setTotalCount] = useState(0);

  const [modalData, setModalData] = useState({
    cycleId,
    eid: "",
    cycleStatus: "",
  });

  const {
    data: employeeList,
    isLoading: employeeListLoading,
    refetch: refetchEmployeeList,
  } = useQuery(["getEmployeesForAppraisal", filter, currentPage], () =>
    getEmployeesForAppraisal(25, currentPage, filter)
  );
  useEffect(() => {
    setTotalCount(employeeList?.totalCount && employeeList.totalCount);
    setEmployeeListObj(
      employeeList?.employees?.map((emp) => {
        return {
          ...emp,
          created: false,
          edit: false,
          reward: emp.empInfo.status === "Pending" ? "false" : emp.reward,
          promotion: emp.empInfo.status === "Pending" ? "false" : emp.promotion,
          incrementAmount:
            emp.empInfo.status === "Pending" ? "false" : emp.incrementAmount,
          incrementPercentage:
            emp.empInfo.status === "Pending"
              ? "false"
              : emp.incrementPercentage,
          applicableDateIP: "false",
          newMonthlyCoreCTC:
            emp.empInfo.status === "Pending" ? "false" : emp.newMonthlyCoreCTC,
          newMonthlyFullCTC:
            emp.empInfo.status === "Pending" ? "false" : emp.newMonthlyFullCTC,
          newYearlyFullCTC:
            emp.empInfo.status === "Pending" ? "false" : emp.newYearlyFullCTC,
          newYearlyCoreCTC:
            emp.empInfo.status === "Pending" ? "false" : emp.newYearlyCoreCTC,
        };
      })
    );
    setPageCount(employeeList?.totalCount && employeeList.totalCount / 25);
  }, [employeeList, render]);

  // getCombinationFilter
  const { data: combinationFilterList } = useQuery(
    ["getCombinationFilterList"],
    () => getCombinationFilterList()
  );
  const [rewardList, setRewardList] = useState([]);
  const [promotionList, setPromotionList] = useState([]);

  useEffect(() => {
    setRewardList(combinationFilterList?.reward);
    setPromotionList(combinationFilterList?.promotion);
  }, [combinationFilterList]);

  // ADD EMPLOYEES TO APPRAISAL CYCLE

  // createAppraisalMutation

  const createAppraisalMutation = useMutation(createAppraisal, {
    onMutate: () => {},
    onSuccess: (res) => {
      refetchEmployeeList();
      if (res.responseStatus.statusCode === 0) {
        notifySuccess(res.responseData);
        clearAllCheckBoxes();
      } else {
        notifyError(res.responseData.message);
      }
    },
    onError: (err) => {},
  });

  // Delete Employee From Appraisal Cycle
  const handleDelete = (e) => {
    const eid = Number(e.target.id);

    try {
      deleteAppraisalMutation.mutate({ cycleId, eid });
    } catch (mutationError) {}
  };

  const deleteAppraisalMutation = useMutation(deleteAppraisal, {
    onMutate: () => {},
    onSuccess: (res) => {
      // Refetch the cycles data after a successful mutation
      refetchEmployeeList();
      if (res.responseStatus.statusCode === 0) {
        notifySuccess(res.responseData);
      } else {
        notifyError(res.responseData.message);
      }
    },
    onError: (err) => {
      notifyError("Something Went Wrong");
    },
  });
  const handleAppraisal = (tempEid, emp) => {
    resetState();
    let eid = Number(empData?.eid);
    setEmployeeListObj((prev) =>
      prev.map((emp) => {
        if (emp.empInfo.id === eid || emp.empInfo.id === tempEid) {
          emp.created = true;
          emp.reward = "true";
          emp.promotion = "true";
          emp.incrementAmount = "true";
          emp.incrementPercentage = "true";
          emp.applicableDateIP = "true";
          emp.newMonthlyCoreCTC = "true";
          emp.newMonthlyFullCTC = "true";
          emp.newYearlyCoreCTC = "true";
          emp.newYearlyFullCTC = "true";
          return emp;
        }
        closeAll(emp);
        return emp;
      })
    );
    // //
  };
  const getIncentives = () => {
    let data = {};
    if (appraisalModalData.sa1 && appraisalModalCheckBoxes?.sa1)
      data.sa1 = true;
    if (appraisalModalData.sa2 && appraisalModalCheckBoxes?.sa2)
      data.sa2 = true;
    if (appraisalModalData.incentives && appraisalModalCheckBoxes?.incentives)
      data.incentives = true;
    if (
      appraisalModalData.joiningBonus &&
      appraisalModalCheckBoxes?.joiningBonus
    )
      data.joiningBonus = true;
    if (
      appraisalModalData.variableBonus &&
      appraisalModalCheckBoxes?.variableBonus
    )
      data.variableBonus = true;
    if (
      appraisalModalData.retentionBonus &&
      appraisalModalCheckBoxes?.retentionBonus
    )
      data.retentionBonus = true;
    if (appraisalModalData.others && appraisalModalCheckBoxes?.others)
      data.others = true;
    return data;
  };
  const handleSubmit = (e) => {
    let eid = Number(e.target.id);
    // if (parseFloat(increasedAmount) === 0 || !increasedAmount) {
    //   setShowError((prev) => {
    //     return {
    //       ...prev,
    //       increasedAmountError: true,
    //     };
    //   });
    //   notifyError("Please Enter All Required Fields");
    //   return;
    // }
    let data = {
      appraisalCycleId: Number(cycleId),
      employeeId: Number(eid),
      incrementAmount: Number(increasedAmount),
      incrementPercentage: Number(increasedPercent),
      applicableDate: applicableDate,
      newMonthlyCoreCTC: Number(coreSalaryM),
      newMonthlyFullCTC: Number(fullSalaryM),
      newYearlyCoreCTC: Number(coreSalaryA),
      newYearlyFullCTC: Number(fullSalaryA),
      promotionId:
        promotion === "" || promotion === "Choose Promotion"
          ? null
          : Number(promotion),
      rewardId:
        award === "" || award === "Choose Reward" ? null : Number(award),
      ...getIncentives(),
    };
    createAppraisalMutation.mutate(data);
    setRender(!render);
  };
  const closeAll = (emp) => {
    if (emp.reward === "true") emp.reward = "false";

    if (emp.promotion === "true") emp.promotion = "false";
    if (emp.incrementAmount === "true") emp.incrementAmount = "false";
    if (emp.incrementPercentage === "true") emp.incrementPercentage = "false";
    if (emp.applicableDateIP === "true") emp.applicableDateIP = "false";
    if (emp.newMonthlyCoreCTC === "true") emp.newMonthlyCoreCTC = "false";
    if (emp.newMonthlyFullCTC === "true") emp.newMonthlyFullCTC = "false";
    if (emp.newYearlyCoreCTC === "true") emp.newYearlyCoreCTC = "false";
    if (emp.newYearlyFullCTC === "true") emp.newYearlyFullCTC = "false";
    emp.created = false;
  };
  const handleCancel = (e) => {
    clearAllCheckBoxes();
    let eid = Number(e.target.id);
    setEmployeeListObj((prev) =>
      prev.map((emp) => {
        if (emp.empInfo.id === eid) {
          emp.reward = "false";
          emp.promotion = "false";
          emp.incrementPercentage = "false";
          emp.applicableDateIP = "false";
          emp.incrementAmount = "false";
          emp.newMonthlyCoreCTC = "false";
          emp.newMonthlyFullCTC = "false";
          emp.newYearlyCoreCTC = "false";
          emp.newYearlyFullCTC = "false";
          emp.created = false;
          return emp;
        }
        return emp;
      })
    );
    setAward("");
    setPromotion("");
  };
  const resetState = () => {
    setAward("");
    setPromotion("");
    setFullSalaryA("");
    setFullSalaryM("");
    setIncreasedAmount("");
    setApplicableDate("");
    setIncreasedPercent("");
    setCoreSalaryM("");
    setCoreSalaryA("");
    setShowError({
      awardError: false,
      promotionError: false,
      increasedAmountError: false,
      hrCommentError: false,
      holdCommentError: false,
    });
  };

  // Input Handler Functions
  const handleAmount = (e, emp) => {
    let tempAmt = 0;
    //
    if (e.target.value.trim()) {
      setShowError((prev) => {
        return { ...prev, increasedAmountError: false };
      });
    }
    let amt = e.target.value.replace(/[^0-9.-]/g, "");
    tempAmt = handleCalcIncentives();
    setIncentivesAdded(tempAmt);
    setIsIncentivesAdded(true);
    //
    setCoreSalaryM(
      (
        Number(amt) +
        Number(emp.empInfo.currentMonthlyCoreCTC) +
        Number(tempAmt)
      ).toFixed(0)
    );
    setCoreSalaryA(
      (
        (Number(amt) +
          Number(emp.empInfo.currentMonthlyCoreCTC) +
          Number(tempAmt)) *
        12
      ).toFixed(0)
    );
    //;
    // if (!isIncentivesAdded && addEdit?.add) {

    //   // //
    // } else {
    //   // //
    //   setCoreSalaryM(
    //     (
    //       Number(emp.empInfo.currentMonthlyCoreCTC) +
    //       Number(amt) +
    //       Number(incentivesAdded)
    //     ).toFixed(0)
    //   );
    //   setCoreSalaryA(
    //     (
    //       (Number(emp.empInfo.currentMonthlyCoreCTC) +
    //         Number(amt) +
    //         Number(incentivesAdded)) *
    //       12
    //     ).toFixed(0)
    //   );
    //   // //
    // }

    //

    setIncreasedAmount(parseInt(amt, 10));
    setIncreasedPercent(
      (
        (Number(amt) * 100) /
        Number(emp.empInfo.currentMonthlyCoreCTC + (tempAmt || incentivesAdded))
      ).toFixed(1)
    );
    // //
    let fullMonthlySal = (
      Number(emp.empInfo.currentMonthlyFullCTC) + Number(amt)
    ).toFixed(0);
    let fullAnnualSal = (
      Number(emp.empInfo.currentYearlyFullCTC) +
      Number(amt) * 12
    ).toFixed(0);
    setFullSalaryA(fullAnnualSal);
    setFullSalaryM(fullMonthlySal);
    //
  };

  const handleKeyDown = (e) => {
    // Prevent 'e' from being entered
    if (e.key === "e" || e.key === "E") {
      e.preventDefault();
    }
    const arrowKeys = ["ArrowUp", "ArrowDown"];
    if (arrowKeys.includes(e.key)) {
      e.preventDefault();
    }
  };

  const handlePercent = (e, emp) => {
    //
    let tempAmt = 0;
    setIncreasedPercent(e.target.value.replace("e", ""));
    let amt = 0;
    tempAmt = handleCalcIncentives();
    //
    setIncentivesAdded(tempAmt);
    amt =
      (Number(emp.empInfo.currentMonthlyCoreCTC + tempAmt) *
        Number(e.target.value.replace("e", ""))) /
      100;
    amt = Number(amt).toFixed(0);

    setCoreSalaryM(
      (Number(amt) + Number(emp.empInfo.currentMonthlyCoreCTC)).toFixed(0)
    );
    setCoreSalaryA(
      (Number(amt) + Number(emp.empInfo.currentMonthlyCoreCTC)) * 12
    );
    setIncreasedAmount(amt);
    //
    let fullMonthlySal = (
      Number(emp.empInfo.currentMonthlyFullCTC) + Number(amt)
    ).toFixed(0);
    let fullAnnualSal = (
      Number(emp.empInfo.currentYearlyFullCTC) +
      Number(amt) * 12
    ).toFixed(0);
    setFullSalaryA(fullAnnualSal);
    setFullSalaryM(fullMonthlySal);
  };

  const handleInputChange = (e) => {
    setApplicableDate(e.target.value);
  };

  const handleCoreSalaryA = (e, emp) => {
    setCoreSalaryA(e.target.value.replace("e", ""));
    let amt =
      Number(e.target.value.replace("e", "")) / 12 -
      Number(emp.empInfo.currentMonthlyCoreCTC);
    setIncreasedAmount(amt.toFixed(0));
    setIncreasedPercent(
      (Number(amt * 100) / Number(emp.empInfo.currentMonthlyCoreCTC)).toFixed(1)
    );
    setCoreSalaryM((Number(e.target.value) / 12).toFixed(0));
    let fullMonthlySal = (
      Number(emp.empInfo.currentMonthlyFullCTC) + Number(amt)
    ).toFixed(0);
    let fullAnnualSal = (
      Number(emp.empInfo.currentYearlyFullCTC) +
      Number(amt) * 12
    ).toFixed(0);
    setFullSalaryA(fullAnnualSal);
    setFullSalaryM(fullMonthlySal);
  };
  const handleCoreSalaryM = (e, emp) => {
    setCoreSalaryM(e.target.value.replace("e", ""));
    let amt =
      Number(e.target.value.replace("e", "")) -
      Number(emp.empInfo.currentMonthlyCoreCTC);
    setIncreasedAmount(amt);
    setIncreasedPercent(
      (Number(amt * 100) / Number(emp.empInfo.currentMonthlyCoreCTC)).toFixed(1)
    );
    setCoreSalaryA((Number(e.target.value) * 12).toFixed(0));
    let fullMonthlySal = (
      Number(emp.empInfo.currentMonthlyFullCTC) + Number(amt)
    ).toFixed(0);
    let fullAnnualSal = (
      Number(emp.empInfo.currentYearlyFullCTC) +
      Number(amt) * 12
    ).toFixed(0);
    setFullSalaryA(fullAnnualSal);
    setFullSalaryM(fullMonthlySal);
  };
  //create function
  const handleAppraisalOpenModal = (e, emp) => {
    const data = {};
    if (emp.empInfo.sa1) data.sa1 = "SA1";
    if (emp.empInfo.sa2) data.sa2 = "SA2";
    if (emp.empInfo.incentives) data.incentives = "Incentives";
    if (emp.empInfo.joiningBonus) data.joiningBonus = "JoiningBonus";
    if (emp.empInfo.variableBonus) data.variableBonus = "VariableBonus";
    if (emp.empInfo.retentionBonus) data.retentionBonus = "RetentionBonus";
    if (emp.empInfo.others) data.others = "Others";
    setEmpData({ ...emp, eid: Number(e.target.id) });
    //
    if (Object.entries(data)?.length) {
      //
      setAppraisalModalData({ ...data });
      setaAMModalIsOpen(true);
    } else {
      // //
      handleAppraisal(Number(e.target.id), emp);
    }
    setAddEdit((prev) => ({ ...prev, add: true }));
  };

  const setAppraisalModalCheckBoxesFunction = (emp) => {
    const data = { ...appraisalModalCheckBoxes };
    if (emp?.sa1Applicable) data.sa1 = true;
    if (emp?.sa2Applicable) data.sa2 = true;
    if (emp?.incentivesApplicable) data.incentives = true;
    if (emp?.joiningBonusApplicable) data.joiningBonus = true;
    if (emp?.retentionBonusApplicable) data.retentionBonus = true;
    if (emp?.variableBonusApplicable) data.variableBonus = true;
    if (emp?.othersApplicable) data.others = true;

    setAppraisalModalCheckBoxes({ ...data });
  };
  // Edit Functioning
  const handleEditOpenAModal = (e, emp) => {
    const data = {};
    if (emp.empInfo.sa1) data.sa1 = "SA1";
    if (emp.empInfo.sa2) data.sa2 = "SA2";
    if (emp.empInfo.incentives) data.incentives = "Incentives";
    if (emp.empInfo.joiningBonus) data.joiningBonus = "JoiningBonus";
    if (emp.empInfo.variableBonus) data.variableBonus = "VariableBonus";
    if (emp.empInfo.retentionBonus) data.retentionBonus = "RetentionBonus";
    if (emp.empInfo.others) data.others = "Others";
    setAppraisalModalCheckBoxesFunction(emp);
    setEmpData({ ...emp, eid: Number(e.target.id) });
    //
    if (Object.entries(data)?.length) {
      setAppraisalModalData({ ...data });
      setaAMModalIsOpen(true);
      setAddEdit((prev) => ({ ...prev, edit: true }));
      //
    } else {
      //
      handleEdit(e.target.id, emp);
    }
    setNotInitiallyAdded(true);
  };

  const removePrevIncentives = (emp) => {
    let tempAmt = 0;
    if (
      !appraisalModalCheckBoxes?.sa1 &&
      (emp?.sa1Applicable || empData?.sa1Applicable)
    ) {
      tempAmt += Number(empData?.empInfo?.sa1 || emp?.sa1);
    }
    if (
      !appraisalModalCheckBoxes?.sa2 &&
      (emp?.sa2Applicable || empData?.sa2Applicable)
    ) {
      tempAmt += Number(empData?.empInfo?.sa2 || emp?.sa2);
    }
    if (
      appraisalModalCheckBoxes?.incentives &&
      (emp?.incentivesApplicable || empData?.incentivesApplicable)
    ) {
      tempAmt += Number(empData?.empInfo?.incentives || emp?.incentives);
    }
    if (
      appraisalModalCheckBoxes?.joiningBonus &&
      (emp?.joiningBonusApplicable || empData?.joiningBonusApplicable)
    ) {
      tempAmt += Number(empData?.empInfo?.joiningBonus || emp?.joiningBonus);
    }
    if (
      appraisalModalCheckBoxes?.variableBonus &&
      (emp?.variableBonusApplicable || empData?.variableBonusApplicable)
    ) {
      tempAmt += Number(empData?.empInfo?.variableBonus || emp?.variableBonus);
    }
    if (
      appraisalModalCheckBoxes?.retentionBonus &&
      (emp?.retentionBonusApplicable || empData?.retentionBonusApplicable)
    ) {
      tempAmt += Number(
        empData?.empInfo?.retentionBonus || emp?.retentionBonus
      );
    }
    if (
      appraisalModalCheckBoxes?.others &&
      (emp?.othersApplicable || empData?.othersApplicable)
    ) {
      tempAmt += Number(empData?.empInfo?.others || emp?.others);
    }

    return tempAmt;
  };

  const handleCalcIncentives = (emp) => {
    let tempAmt = 0;
    if (appraisalModalCheckBoxes?.sa1) {
      tempAmt += Number(empData?.empInfo?.sa1 || emp?.sa1);
    }
    if (appraisalModalCheckBoxes?.sa2) {
      tempAmt += Number(empData?.empInfo?.sa2 || emp?.sa2);
    }
    if (appraisalModalCheckBoxes?.incentives) {
      tempAmt += Number(empData?.empInfo?.incentives || emp?.incentives);
    }
    if (appraisalModalCheckBoxes?.joiningBonus) {
      tempAmt += Number(empData?.empInfo?.joiningBonus || emp?.joiningBonus);
    }
    if (appraisalModalCheckBoxes?.variableBonus) {
      tempAmt += Number(empData?.empInfo?.variableBonus || emp?.variableBonus);
    }
    if (appraisalModalCheckBoxes?.retentionBonus) {
      tempAmt += Number(
        empData?.empInfo?.retentionBonus || emp?.retentionBonus
      );
    }
    if (appraisalModalCheckBoxes?.others) {
      tempAmt += Number(empData?.empInfo?.others || emp?.others);
    }

    //;
    setNotInitiallyAdded(false);
    tempAmt -= removePrevIncentives(emp);
    return tempAmt;
  };

  const handleSetData = (emp) => {
    let incrementamt = empData?.incrementAmount || emp?.incrementAmount;
    let incPer = empData?.incrementPercentage || emp?.incrementPercentage;
    let newMonCoreCTC = empData?.newMonthlyCoreCTC || emp?.newMonthlyCoreCTC;
    let newYearCoreCTC = empData?.newYearlyCoreCTC || emp?.newYearlyCoreCTC;
    let newYearFullCTC = empData?.newYearlyFullCTC || emp?.newYearlyFullCTC;
    if (incrementamt === "true" || incrementamt === "false") {
      incrementamt = 0;
    }
    if (incPer === "true" || incPer === "false") {
      incPer = 0;
    }
    if (newMonCoreCTC === "true" || newMonCoreCTC === "false") {
      newMonCoreCTC = 0;
    }
    if (newYearCoreCTC === "true" || newYearCoreCTC === "false") {
      newYearCoreCTC = 0;
    }
    if (newYearFullCTC === "true" || newYearFullCTC === "false") {
      newYearFullCTC = 0;
    }
    setEditAward(empData?.rewardId || emp?.rewardId);
    setAward(empData?.rewardId || emp?.rewardId);
    setPromotion(empData?.promotionId || emp?.promotionId);
    setEditPromotion(empData?.promotionId || emp?.promotionId);
    setIncreasedAmount(incrementamt);
    setEditIncreasedAmount(incrementamt);
    setIncreasedPercent(incPer);
    setApplicableDate(
      empData?.applicableDate
        ? moment(empData?.applicableDate).format("YYYY-MM-DD")
        : emp?.applicableDate
        ? moment(emp?.applicableDate).format("YYYY-MM-DD")
        : null
    );
    // setApplicableDate(empData?.applicableDate)
    setEditApplicableDate(empData?.applicableDate || emp?.applicableDate);
    setEditIncreasedPercent(incPer);
    // setCoreSalaryM(empData?.newMonthlyCoreCTC);
    setEditCoreSalaryM(newMonCoreCTC);
    setCoreSalaryA(newYearCoreCTC);
    setEditCoreSalaryA(newYearCoreCTC);
    setFullSalaryA(newYearFullCTC);
    setEditFullSalaryA(newYearFullCTC);
    setFullSalaryM(newYearFullCTC);
    setEditFullSalaryM(newYearFullCTC);
    //calulating monthly core ctc
    let tempAmt = handleCalcIncentives(emp);

    setCoreSalaryM(newMonCoreCTC + tempAmt);
    //
    //
  };

  const handleEdit = (tempId, emp) => {
    handleSetData(emp);
    setEmployeeListObj((prev) =>
      prev.map((emp) => {
        if (
          Number(emp.empInfo.id) === empData?.eid ||
          Number(emp.empInfo.id) === Number(tempId)
        ) {
          emp.edit = true;
          emp.created = true;
          emp.reward = "true";
          emp.promotion = "true";
          emp.incrementAmount = "true";
          emp.incrementPercentage = "true";
          emp.applicableDateIP = "true";
          emp.newMonthlyCoreCTC = "true";
          emp.newMonthlyFullCTC = "true";
          emp.newYearlyCoreCTC = "true";
          emp.newYearlyFullCTC = "true";
          return emp;
        } else closeAll(emp);
        return emp;
      })
    );
    //
  };
  const handleEditCancel = (e) => {
    clearAllCheckBoxes();
    let eid = Number(e.target.id);
    setEmployeeListObj((prev) =>
      prev.map((emp) => {
        if (emp.empInfo.id === eid) {
          emp.reward = editAward > 0 ? rewardList[editAward - 1].name : null;
          emp.promotion =
            editPromotion > 0 ? promotionList[editPromotion - 1].name : null;
          emp.incrementAmount = editIncreasedAmount;
          emp.incrementPercentage = editIncreasedPercent;
          // emp.applicableDate = applicableDate;
          emp.applicableDateIP = "false";
          emp.newYearlyCoreCTC = editCoreSalaryA;
          emp.newMonthlyCoreCTC = editCoreSalaryM;
          emp.newMonthlyFullCTC = editFullSalaryM;
          emp.newYearlyFullCTC = editFullSalaryA;
          emp.created = false;
          emp.edit = false;
          return emp;
        }
        return emp;
      })
    );
  };
  // Filters
  const handleChangeEmp = (selectedOption, type) => {
    setSelectedEmp(selectedOption);
    switch (type) {
      case "search":
        setActiveEmp(true);
        setCurrentPage(1);
        setFilter({ ...filter, employeeId: selectedOption.value });
        break;
      case "close":
        setActiveEmp(false);
        setFilter({ ...filter, employeeId: null });
        break;
      default:
        break;
    }
  };
  const handleChangeDepartment = (selectedOption, type) => {
    setSelectedDept(selectedOption);
    switch (type) {
      case "search":
        setActiveDept(true);
        setCurrentPage(1);
        setFilter({ ...filter, deptId: selectedOption.value });
        break;
      case "close":
        setActiveDept(false);
        setFilter({ ...filter, deptId: null });
        break;
      default:
        break;
    }
  };

  const handlePageChange = (page, status) => {
    if (status === "next") {
      setCurrentPage(page + 1);
    } else if (status === "prev") {
      setCurrentPage(page - 1);
    }
  };
  // appraisalSendBac
  const handleSendBack = (e) => {
    let eid = Number(e.target.id);
    appraisalSendBackMutation.mutate({ eid, cycleId });
  };
  const appraisalSendBackMutation = useMutation(appraisalSendBack, {
    onMutate: () => {},
    onSuccess: (res) => {
      refetchEmployeeList();
      if (res.responseStatus.statusCode === 0) {
        notifySuccess(res.responseData);
      } else {
        notifyError(res.responseData.message);
      }
    },
    onError: (err) => {},
  });

  // Approve Appraisal
  const handleApprove = (e) => {
    let eid = Number(e.target.id);
    appraisalApprovedMutation.mutate({ eid, cycleId });
  };
  const appraisalApprovedMutation = useMutation(appraisalApproved, {
    onMutate: () => {},
    onSuccess: (res) => {
      refetchEmployeeList();
      if (res.responseStatus.statusCode === 0) {
        notifySuccess(res.responseData);
      } else {
        notifyError(res.responseData.message);
      }
    },
    onError: (err) => {},
  });

  // hold Appraisal
  const handleHoldAppraisal = (e) => {
    let eid = Number(e.target.id);
    holdAppraisalMutation.mutate({ eid, cycleId });
  };
  const holdAppraisalMutation = useMutation(holdAppraisal, {
    onMutate: () => {},
    onSuccess: (res) => {
      refetchEmployeeList();
      if (res.responseStatus.statusCode === 0) {
        notifySuccess(res.responseData);
      } else {
        notifyError(res.responseData.message);
      }
    },
    onError: (err) => {},
  });

  // Comment Modal
  const closeModal = () => {
    setModalIsOpen(false);
  };
  const closePCModal = () => {
    setModalIsOpen(false);
  };
  const openModal = () => {
    setModalIsOpen(true);
  };
  const openPCModal = () => {
    setPCModalIsOpen(true);
  };
  const handleProjectComments = () => {
    openPCModal();
  };

  // Filters

  const filterUpdateFuc = () => {
    setCurrentPage(1);
    setFilter((prev) => ({
      ...prev,
      technology: [...(filterData?.technology || [])],
      empType: [...(filterData?.empType || [])],
      reportingManager: [...(filterData?.reportingManager || [])],
      client: [...(filterData?.client || [])],
      // promotion: [...filterData?.promotion],
      reward: [...(filterData?.reward || [])],
      location: filterData?.location,
      bond: filterData?.bond,
      appraisalStatus: [...(filterData?.appraisalStatus || [])],
    }));
    setFilterApplied(true);
    setShowFilterModal(false);
  };

  const filterClearAllFuc = () => {
    setFilter((prev) => ({
      ...prev,
      appraisalStatus: null,
      bond: null,
      client: null,
      deptId: null,
      empType: null,
      location: null,
      // promotion: null,
      reportingManager: null,
      reward: null,
      technology: null,
    }));

    setFilterData((prev) => ({
      ...prev,
      appraisalStatus: [],
      bond: null,
      client: [],
      deptId: [],
      empType: [],
      location: [],
      // promotion: [],
      reportingManager: [],
      reward: [],
      technology: [],
    }));
    setShowFilterModal(false);
    setFilterApplied(false);
  };
  const locationList = [
    {
      value: "Offshore",
      label: "Off-Shore",
    },
    {
      value: "Onsite",
      label: "On-Site",
    },
  ];
  const technologyList = combinationFilterList?.technology?.map((i) => ({
    value: i.id,
    label: i.name,
  }));
  const empTypeList = combinationFilterList?.empType?.map((i) => ({
    value: i.id,
    label: i.name,
  }));
  const clientList = combinationFilterList?.client?.map((i) => ({
    value: i.id,
    label: i.name,
  }));
  const rmList = combinationFilterList?.reportingManager?.map((i) => ({
    value: i.id,
    label: i.name,
  }));
  const rewardListNew = combinationFilterList?.reward?.map((i) => ({
    value: i.id,
    label: i.name,
  }));
  const statusListNew = combinationFilterList?.status?.map((i) => ({
    value: i.id,
    label: i.name,
  }));

  // Project Details
  const [projectModalIsOpen, setProjectModalIsOpen] = useState(false);

  const openProjectModal = () => {
    setProjectModalIsOpen(true);
  };

  const closeProjectModal = () => {
    setProjectModalIsOpen(false);
  };
  const [projectDetailsObj, setProjectDetailsObj] = useState(null);
  const [projectDetailsComments, setProjectDetailsComments] = useState(null);
  const handleView = (projectDetailObj, projectCommentsObj) => {
    setProjectDetailsObj(projectDetailObj);
    setProjectDetailsComments(projectCommentsObj);
    openProjectModal();
  };
  function formatDate(dateString) {
    const date = new Date(dateString);
    const options = { day: "2-digit", month: "short", year: "numeric" };
    return date.toLocaleDateString("en-GB", options);
  }
  //  Format Date for BOND
  const formatDateBond = (dateStr) => {
    // Split the date string into day, month, and year
    const [day, monthStr, year] = dateStr.split(" ");

    // Create a map to convert month abbreviation to its numeric value
    const monthMap = {
      Jan: "01",
      Feb: "02",
      Mar: "03",
      Apr: "04",
      May: "05",
      Jun: "06",
      Jul: "07",
      Aug: "08",
      Sep: "09",
      Oct: "10",
      Nov: "11",
      Dec: "12",
    };

    // Get the numeric value of the month from the map
    const month = monthMap[monthStr];
    const date = new Date();

    // Construct the formatted date string in YYYY-MM-DD format
    const formattedDate = `20${year}-${month}-${day.padStart(2, "0")}`;
    let difference = Math.ceil(
      (new Date(formattedDate) - date) / (1000 * 60 * 60 * 24 * 30)
    );
    return difference > 0 && difference < 3;
  };

  //  For Handling scroll over I/P field
  const handleClickInput = (e) => {
    e.currentTarget.addEventListener("mousewheel", function (event) {
      event.currentTarget.blur(); // Use `event.currentTarget` to reference the element
    });
  };

  // Excel Download

  const sheetHeader = [
    "Code",
    "Name",
    "Designation",
    "DOJ",
    "Exp with IM",
    "Total Exp",
    "Reporting Manager",
    "Bond",
    "Emp Type",
    "Department",
    "Location",
    "Client Name",
    "Technology",
    "Rating",
    "Last Appraisal Cycle",
    "Last Increment %",
    "Last Increment Amount",
    "Last Promotion Cycle",
    "Last Reward",
    "SA 1",
    "SA 2",
    "Incentives",
    "Joining Bonus",
    "Variable Bonus",
    "Retention Bonus",
    "Others",
    "Current Monthly Core CTC",
    "Current Yearly Core CTC",
    "Current Monthly Full CTC",
    "Current Yearly Full CTC",
    "Reward",
    "Promotion",
    "Increment Amount",
    "Increment %",
    "New Monthly Core CTC",
    "New Yearly Core CTC",
    "New Monthly Full CTC",
    "New Yearly Full CTC",
    "Status",
  ];
  const handleExcelData = (data) => {
    return data?.map((e) => {
      return [
        e.empInfo.employeeCode,
        e.empInfo.name,
        e.empInfo.designation,
        e.empInfo.dateOfJoining,
        e.empInfo.totalExpWithIM,
        e.empInfo.totalExperience,
        e.empInfo.reportingManager,
        e.empInfo.bondEndDate,
        e.empInfo.empType,
        e.empInfo.department,
        e.empInfo.location,
        e.empInfo.clientName,
        e.empInfo.technology,
        e.empInfo.rating,
        e.empInfo.lastAppraisalCycle,
        e.empInfo.lastIncrementPercenatage,
        e.empInfo.lastIncrementAmount,
        e.empInfo.lastPromotionCycle,
        e.empInfo.lastReward,
        e.empInfo.sa1,
        e.empInfo.sa2,
        e.empInfo.incentives,
        e.empInfo.joiningBonus,
        e.empInfo.variableBonus,
        e.empInfo.retentionBonus,
        e.empInfo.others,
        e.empInfo.currentMonthlyCoreCTC,
        e.empInfo.currentYearlyCoreCTC,
        e.empInfo.currentMonthlyFullCTC,
        e.empInfo.currentYearlyFullCTC,
        e.reward,
        e.promotion,
        e.incrementAmount,
        e.incrementPercentage,
        e.applicableDate,
        e.newMonthlyCoreCTC,
        e.newYearlyCoreCTC,
        e.newMonthlyFullCTC,
        e.newYearlyFullCTC,
        e.empInfo.status,
      ];
    });
  };
  const downloadExcelFile = async () => {
    // Fetch API
    const getEmployeesForAppraisal = async (limit, currentPage) => {
      const response = await axios.post(
        url + `/getEmployeesForAppraisal?limit=1&page=1`,
        {
          appraisalCycleId: cycleId,
          appraisalStatus: [],
          bond: null,
          client: [],
          deptId: null,
          empType: [],
          employeeId: null,
          location: [],
          // promotion: [],
          reportingManager: [],
          reward: [],
          technology: [],
        }
      );
      return response.data.responseData;
    };
    // // Collect Response
    const employeeList = await getEmployeesForAppraisal();
    CustomExcelFileDownload(
      sheetHeader,
      handleExcelData(employeeList?.employees),
      `${cycleName} Appraisals Employee List`
    );
  };

  const clearAllCheckBoxes = () => {
    setAppraisalModalCheckBoxes({
      sa1: false,
      sa2: false,
      incentives: false,
      joiningBonus: false,
      variableBonus: false,
      retentionBonus: false,
      others: false,
    });
  };

  const handleCancelAM = () => {
    setaAMModalIsOpen(false);
    clearAllCheckBoxes();
  };

  const handleSubmitAM = () => {
    setaAMModalIsOpen(false);
    addEdit?.edit && handleEdit();
    addEdit?.add && handleAppraisal();
    //
  };

  const handleCheckboxChange = (key, checked) => {
    const data = {};
    if (key === "sa1") data.sa1 = checked;
    if (key === "sa2") data.sa2 = checked;
    if (key === "incentives") data.incentives = checked;
    if (key === "joiningBonus") data.joiningBonus = checked;
    if (key === "variableBonus") data.variableBonus = checked;
    if (key === "retentionBonus") data.retentionBonus = checked;
    if (key === "others") data.others = checked;

    setAppraisalModalCheckBoxes({ ...appraisalModalCheckBoxes, ...data });
  };

  return (
    <>
      <div className={style.upper}>
        <div className={style.totalCount}>{totalCount}</div>
        <div className={style.filterBox}>
          <div style={{ width: "25%", marginRight: "10px" }}>
            <FiDownload
              style={{
                verticalAlign: "baseline",
                fontSize: "1.8rem",
                cursor: "pointer",
                marginLeft: "1rem",
                color: "#562679",
              }}
              onClick={downloadExcelFile}
            />
          </div>
          <div style={{ width: "25%" }}>
            <button
              className={style.filter_btn}
              onClick={() => setShowFilterModal(true)}
            >
              <FilterListIcon style={{ marginRight: "10px" }} />
              Filters
            </button>
          </div>
          <div className={style.searchContainer}>
            {!activeEmp ? (
              <span className={style.cp_serchIcon}>
                <img src={searchIcon} alt="searchIcon" />
              </span>
            ) : (
              <span
                className={style.cp_serchIcon}
                onClick={() => {
                  handleChangeEmp(null, "close");
                }}
                tabIndex={0} // Makes the span focusable
                onKeyDown={(e) => {
                  if (e.key === "Enter" || e.key === "Space") {
                    handleChangeEmp(null, "close");
                  }
                }} // Allows the span to be activated by pressing Enter or Space
                aria-label="close"
              >
                <AiOutlineClose />
              </span>
            )}
            <Select
              styles={customSearchACE}
              classNamePrefix={"create_position"}
              placeholder="Search EmpName/Code"
              value={selectedEmp}
              components={{
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null,
              }}
              options={employeeListFilterObj}
              onChange={(selectedOption) =>
                handleChangeEmp(selectedOption, "search")
              }
            />
          </div>

          <div className={style.searchContainer}>
            {!activeDept ? (
              <span className={style.cp_serchIcon}>
                <img src={searchIcon} alt="searchIcon" />
              </span>
            ) : (
              <span
                className={style.cp_serchIcon}
                onClick={() => {
                  handleChangeDepartment(null, "close");
                }}
                tabIndex={0} // Makes the span focusable
                onKeyDown={(e) => {
                  if (e.key === "Enter" || e.key === "Space") {
                    handleChangeDepartment(null, "close");
                  }
                }} // Allows the span to be activated by pressing Enter or Space
                aria-label="Close"
              >
                <AiOutlineClose />
              </span>
            )}
            <Select
              styles={customSearchACE}
              className="custom-select"
              classNamePrefix={"create_position"}
              placeholder="Search Department"
              value={selectedDept}
              components={{
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null,
              }}
              options={departmentList}
              onChange={(selectedOption) =>
                handleChangeDepartment(selectedOption, "search")
              }
            />
          </div>
        </div>
      </div>
      {!employeeListLoading ? (
        <div className={style.table_container}>
          <table className={style.scrollable_table}>
            <thead>
              <tr
                style={{
                  position: "sticky",
                  zIndex: 10,
                  top: "0px",
                }}
              >
                <div className={style.stickyTH} style={{ marginTop: "-1px" }}>
                  <th className={`${style.sticky1h} ${style.th} `}>Code</th>
                  <th className={`${style.sticky2h} ${style.th}`}>Name</th>
                  <th className={`${style.sticky3h} ${style.th} `}>
                    Desigination
                  </th>
                  <th className={`${style.sticky4h} ${style.th}`}>DOJ</th>
                  <th className={`${style.sticky5h} ${style.th}`}>
                    Exp With IM
                  </th>
                </div>
                <th className={`${style.th}`}>Total Exp</th>
                <th className={`${style.th}`}>Reporting Manager</th>
                <th className={`${style.th}`}>Bond</th>
                <th className={`${style.th}`}>Employee Type</th>
                <th className={`${style.th}`}>Department</th>
                <th className={`${style.th}`}>Location</th>
                <th className={`${style.th}`}>Client Name</th>
                <th className={`${style.th}`}>Technology</th>
                <th className={`${style.th}`}>Rating</th>
                <th className={`${style.th}`}>Project Details</th>
                <th className={`${style.th}`}>Performance Details</th>
                <th className={`${style.th} ${style.past}`}>
                  Last Effective From
                </th>
                <th className={`${style.th} ${style.past}`}>
                  Last Appraisal Cycle
                </th>
                <th className={`${style.th} ${style.past}`}>
                  Last Increment Amount
                </th>
                <th className={`${style.th} ${style.past}`}>
                  Last Increment %
                </th>
                <th className={`${style.th} ${style.past}`}>
                  Last Promotion Cycle
                </th>
                <th className={`${style.th} ${style.past}`}>Last Reward</th>
                <th className={`${style.th} ${style.salary}`}>SA1</th>
                <th className={`${style.th} ${style.salary}`}>SA2</th>
                <th className={`${style.th} ${style.salary}`}>Incentives</th>
                <th className={`${style.th} ${style.salary}`}>Joining Bonus</th>
                <th className={`${style.th} ${style.salary}`}>
                  Variable Bonus
                </th>
                <th className={`${style.th} ${style.salary}`}>
                  Retention Bonus
                </th>
                <th className={`${style.th} ${style.salary}`}>Others</th>
                <th className={`${style.th} ${style.salary}`}>
                  Current Monthly Core CTC
                </th>
                <th className={`${style.th} ${style.salary}`}>
                  Current Yearly Core CTC
                </th>
                <th className={`${style.th} ${style.salary}`}>
                  Current Monthly Full CTC
                </th>
                <th className={`${style.th} ${style.salary}`}>
                  Current Yearly Full CTC
                </th>
                <th className={` ${style.th}`}>Reward</th>
                <th className={` ${style.th}`}>Promotion</th>
                <th style={{ textWrap: "nowrap" }} className={`${style.th}`}>
                  Increment %
                </th>
                <th className={`${style.th}`}>Increment Amount</th>
                <th style={{ textWrap: "nowrap" }} className={`${style.th}`}>
                  Effective From
                </th>
                <th className={`${style.th}`}>New Monthly Core CTC</th>
                <th className={`${style.th}`}>New Yearly Core CTC</th>
                <th className={`${style.th}`}>New Monthly Full CTC</th>
                <th className={`${style.th}`}>New Yearly Full CTC</th>
                <th className={` ${style.th}`}>
                  <div className={`${style.holdComment}`}>Comments</div>
                </th>
                <th className={` ${style.th}`}>Status</th>
                <th className={` ${style.th}`}>Action</th>
              </tr>
            </thead>
            {employeeListObj?.length > 0 ? (
              <tbody>
                {employeeListObj?.map((emp) => {
                  return (
                    <tr key={emp.empInfo.id} className={style.inputRow}>
                      <div className={style.stickyTD}>
                        <td
                          className={`${style.sticky1d} ${style.thValue} ${style.td} ${style.padding_top}`}
                        >
                          <NavLink
                            to={`/ASDashboard/${cycleName}/${cycleId}/CycleEmployeeList/EmployeeDetails/${emp.empInfo.id}`}
                          >
                            <p className={`${style.code} ${style.td}`}>
                              {emp.empInfo.employeeCode}
                            </p>
                          </NavLink>
                        </td>
                        <td
                          className={`${style.sticky2d}  ${style.thValue}  ${style.td} ${style.padding_top}`}
                        >
                          <div className={style.name}>
                            {emp.empInfo.name.length > 17 ? (
                              <p title={emp.empInfo.name}>
                                {emp.empInfo.name.slice(0, 16)}...
                              </p>
                            ) : (
                              <p>{emp.empInfo.name}</p>
                            )}
                          </div>
                        </td>
                        <td
                          className={`${style.sticky3d} ${style.thValue}  ${style.td} ${style.padding_top}`}
                        >
                          <div className={style.designation}>
                            {emp.empInfo.designation?.length > 23 ? (
                              <p title={emp.empInfo.designation}>
                                {emp.empInfo.designation.slice(0, 19)}...
                              </p>
                            ) : (
                              <p>{emp.empInfo.designation}</p>
                            )}
                          </div>
                        </td>
                        <td
                          className={`${style.sticky4d}  ${style.thValue}  ${style.td} ${style.padding_top}`}
                        >
                          {moment(emp.empInfo.dateOfJoining).format(
                            "DD MMM YY"
                          )}
                        </td>
                        <td
                          className={`${style.sticky5d}  ${style.thValue}  ${style.td} ${style.padding_top}`}
                        >
                          {emp.empInfo.totalExpWithIM}
                        </td>
                      </div>
                      <td className={` ${style.td}`}>
                        {emp.empInfo.totalExperience}
                      </td>
                      <td className={` ${style.td}`}>
                        {emp.empInfo.reportingManager}
                      </td>
                      <td
                        className={` ${style.td} ${style.makeCenter} ${
                          emp.empInfo.bondEndDate &&
                          formatDateBond(emp.empInfo.bondEndDate) &&
                          style.bondIn2Months
                        }`}
                      >
                        {emp.empInfo.bond === "Y" ? (
                          <p
                            title={
                              emp.empInfo.bondEndDate &&
                              moment(emp.empInfo.bondEndDate).format(
                                "DD MMM YY"
                              )
                            }
                          >
                            Y
                          </p>
                        ) : (
                          "N"
                        )}
                      </td>
                      <td className={` ${style.td}`}>{emp.empInfo.empType}</td>
                      <td className={` ${style.td}`}>
                        {emp.empInfo.department}
                      </td>
                      <td
                        className={` ${style.td} ${
                          emp.empInfo.upcomingDeallocationDate &&
                          style.bondIn2Months
                        }`}
                      >
                        {emp.empInfo.location}
                      </td>
                      <td className={` ${style.td}`}>
                        {emp.empInfo.clientName}
                      </td>
                      <td className={` ${style.td}`}>
                        {emp.empInfo.technology}
                      </td>
                      <td
                        className={`${style.td} ${style.makeCenter}`}
                        style={{
                          color:
                            emp.empInfo.ratingOutOf === "1 to 5"
                              ? "red"
                              : "black",
                        }}
                        title={emp.empInfo.lastPRMonth}
                      >
                        {emp.empInfo.rating}
                      </td>

                      <td className={` ${style.td} ${style.makeCenter}`}>
                        <span
                          className={`${style.link}`}
                          onClick={() =>
                            handleView(
                              emp.empInfo.projectDetailObj,
                              emp.empInfo.projectCommentsObj
                            )
                          }
                          tabIndex={0} // Makes the span focusable
                          onKeyDown={(e) => {
                            if (e.key === "Enter" || e.key === "Space") {
                              handleView(
                                emp.empInfo.projectDetailObj,
                                emp.empInfo.projectCommentsObj
                              );
                            }
                          }} // Handles key presses
                          aria-label="View Details" // Provides an accessible name
                        >
                          <FaEye />
                        </span>
                      </td>
                      <td className={` ${style.td} ${style.makeCenter}`}>
                        <a
                          href="https://portal.innovationm.com/EmployeePerformanceReview"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <span className={`${style.link}`}>
                            <ImLink />
                          </span>
                        </a>
                      </td>
                      <td className={` ${style.td} ${style.makeCenter}`}>
                        {emp.empInfo.lastApplicableFrom &&
                          moment(emp.empInfo.lastApplicableFrom).format(
                            "DD MMM YY"
                          )}
                      </td>
                      <td className={` ${style.td} ${style.makeCenter}`}>
                        {emp.empInfo.lastAppraisalCycle}
                      </td>
                      <td className={` ${style.td} ${style.makeCenter}`}>
                        {emp.empInfo.lastIncrementAmount && (
                          <p>
                            {/* <span className={style.money}>&#8377; </span > */}
                            {addCommas(emp.empInfo.lastIncrementAmount)}
                          </p>
                        )}
                      </td>
                      <td className={` ${style.td} ${style.makeCenter}`}>
                        {emp.empInfo.lastIncrementPercenatage && (
                          <p>{emp.empInfo.lastIncrementPercenatage}%</p>
                        )}
                      </td>
                      <td className={` ${style.td} ${style.makeCenter}`}>
                        {emp.empInfo.lastPromotionCycle}
                      </td>
                      <td className={` ${style.td}`}>
                        {emp.empInfo.lastReward &&
                          `${emp.empInfo.lastReward} (${emp.empInfo.lastRewardCycle})`}
                      </td>
                      <td className={` ${style.td} ${style.makeCenter}`}>
                        {emp.empInfo.sa1 && (
                            <p style={{color: emp?.sa1Applicable && "green"}}>
                              {/* <span className={style.money}>&#8377; </span > */}
                              {addCommas(emp.empInfo.sa1)}
                            </p>
                        )}
                      </td>
                      <td className={` ${style.td} ${style.makeCenter}`}>
                        {emp.empInfo.sa2 && (
                            <p style={{color: emp?.sa2Applicable && "green"}}>
                              {/* <span className={style.money}>&#8377; </span > */}
                              {addCommas(emp.empInfo.sa2)}
                            </p>
                        )}
                      </td>
                      <td className={` ${style.td} ${style.makeCenter}`}>
                        {emp.empInfo.incentives && (
                            <p style={{color: emp?.incentivesApplicable && "green"}}>
                              {/* <span className={style.money}>&#8377; </span > */}
                              {addCommas(emp.empInfo.incentives)}
                            </p>
                        )}
                      </td>
                      <td className={` ${style.td} ${style.makeCenter}`}>
                        {emp.empInfo.joiningBonus && (
                            <p style={{color: emp?.joiningBonusApplicable && "green"}}>
                              {/* <span className={style.money}>&#8377; </span > */}
                              {addCommas(emp.empInfo.joiningBonus)}
                            </p>
                        )}
                      </td>
                      <td className={` ${style.td} ${style.makeCenter}`}>
                        {emp.empInfo.variableBonus && (
                            <p style={{color: emp?.variableBonusApplicable && "green"}}>
                              {/* <span className={style.money}>&#8377; </span > */}
                              {addCommas(emp.empInfo.variableBonus)}
                            </p>
                        )}
                      </td>
                      <td className={` ${style.td} ${style.makeCenter}`}>
                        {emp.empInfo.retentionBonus && (
                            <p styele={{color: emp?.retentionBonusApplicable && "green"}}>
                              {/* <span className={style.money}>&#8377; </span > */}
                              {addCommas(emp.empInfo.retentionBonus)}
                            </p>
                        )}
                      </td>
                      <td className={` ${style.td} ${style.makeCenter}`}>
                        {emp.empInfo.others && (
                            <p style={{color: emp?.othersApplicable && "green"}}>
                              {/* <span className={style.money}>&#8377; </span > */}
                              {addCommas(emp.empInfo.others)}
                            </p>
                        )}
                      </td>
                      <td className={` ${style.td} ${style.makeCenter}`}>
                        {emp.empInfo.currentMonthlyCoreCTC && (
                          <p>
                            {/* <span className={style.money}>&#8377; </span > */}
                            {addCommas(emp.empInfo.currentMonthlyCoreCTC)}
                          </p>
                        )}
                      </td>
                      <td className={` ${style.td} ${style.makeCenter}`}>
                        {emp.empInfo.currentYearlyCoreCTC && (
                          <p>
                            {/* <span className={style.money}>&#8377; </span > */}
                            {addCommas(emp.empInfo.currentYearlyCoreCTC)}
                          </p>
                        )}
                      </td>
                      <td className={` ${style.td} ${style.makeCenter}`}>
                        {emp.empInfo.currentMonthlyFullCTC && (
                          <p>
                            {/* <span className={style.money}>&#8377; </span > */}
                            {addCommas(emp.empInfo.currentMonthlyFullCTC)}
                          </p>
                        )}
                      </td>
                      <td className={` ${style.td} ${style.makeCenter}`}>
                        {emp.empInfo.currentYearlyFullCTC && (
                          <p>
                            {/* <span className={style.money}>&#8377; </span > */}
                            {addCommas(emp.empInfo.currentYearlyFullCTC)}
                          </p>
                        )}
                      </td>
                      <td className={` ${style.td}`}>
                        {emp.reward === "true" ? (
                          <div>
                            <div>
                              <select
                                className={style.selectStyling}
                                name="Choose Reward"
                                value={award}
                                onChange={(e) => setAward(e.target.value)}
                              >
                                <option value={null}>Choose Reward</option>
                                {rewardList?.map((item) => (
                                  <option value={item?.id} key={item?.id}>
                                    {item.name}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                        ) : emp.reward === "false" ? (
                          ""
                        ) : (
                          emp.reward
                        )}
                      </td>
                      <td className={` ${style.td}`}>
                        {emp.promotion === "true" ? (
                          <div>
                            <div>
                              <select
                                className={style.selectStyling}
                                name="Choose Promotion"
                                value={promotion}
                                onChange={(e) => setPromotion(e.target.value)}
                              >
                                <option value={null}>Choose Promotion</option>
                                {promotionList?.map((item) => (
                                  <option value={item?.id} key={item?.id}>
                                    {item.name}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                        ) : emp.promotion === "false" ? (
                          ""
                        ) : (
                          emp.promotion
                        )}
                      </td>
                      <td className={` ${style.td} ${style.makeCenter}`}>
                        {emp.incrementPercentage === "true" ? (
                          <div>
                            <span
                              className={`${style.errorText} ${
                                showError.increasedAmountError
                                  ? style.showError
                                  : style.hideError
                              }`}
                            >
                              *
                            </span>
                            <input
                              className={style.input_field}
                              type="number"
                              placeholder="Enter Percentage"
                              value={increasedPercent}
                              onChange={(e) => handlePercent(e, emp)}
                              onKeyDown={handleKeyDown}
                              onClick={handleClickInput}
                            />
                            <span className={style.money}>%</span>
                          </div>
                        ) : emp.incrementPercentage === "false" ||
                          emp.incrementPercentage === 0 ||
                          emp.incrementPercentage === null ? (
                          ""
                        ) : (
                          <p>
                            {emp.incrementPercentage}
                            <span
                              style={{ fontSize: ".8rem" }}
                              className={style.money}
                            >
                              %
                            </span>
                          </p>
                        )}
                      </td>
                      <td className={` ${style.td} ${style.makeCenter}`}>
                        {emp.incrementAmount === "true" ? (
                          <div>
                            <span
                              className={`${style.errorText} ${
                                showError.increasedAmountError
                                  ? style.showError
                                  : style.hideError
                              }`}
                            >
                              *
                            </span>
                            <input
                              className={style.input_field}
                              type="number"
                              placeholder="Enter Amount"
                              value={increasedAmount}
                              onChange={(e) => handleAmount(e, emp)}
                              onKeyDown={handleKeyDown}
                              onClick={handleClickInput}
                            />
                          </div>
                        ) : emp.incrementAmount === "false" ||
                          emp.incrementAmount === 0 ||
                          emp.incrementAmount === null ? (
                          ""
                        ) : (
                          <p>{addCommas(emp.incrementAmount)}</p>
                        )}
                      </td>

                      <td className={` ${style.td} ${style.makeCenter}`}>
                        {emp.applicableDateIP === "true" ? (
                          <div>
                            <span
                              className={`${style.errorText} ${
                                showError.increasedAmountError
                                  ? style.showError
                                  : style.hideError
                              }`}
                            >
                              *
                            </span>
                            <input
                              type="date" // Use "date" input type for date selection
                              name="startDate"
                              className={style.datePicker}
                              value={applicableDate}
                              onChange={handleInputChange}
                              id="startDate"
                            />
                          </div>
                        ) : emp.applicableDateIP === "false" &&
                          emp.applicableDate === null ? (
                          <p></p>
                        ) : (
                          <p>
                            {moment(emp.applicableDate).format("DD MMM YY")}
                          </p>
                        )}
                      </td>
                      <td className={` ${style.td} ${style.makeCenter}`}>
                        {emp.newMonthlyCoreCTC === "true" ? (
                          <div>
                            {/* <span className={style.money}>&#8377;</span> */}
                            <span
                              className={`${style.errorText} ${
                                showError.increasedAmountError
                                  ? style.showError
                                  : style.hideError
                              }`}
                            >
                              *
                            </span>
                            <input
                             style={{backgroundColor:"#e7e7e7"}}
                            disabled={true}
                              className={style.input_field}
                              type="number"
                              placeholder="Enter Monthly Core CTC"
                              value={coreSalaryM}
                              onChange={(e) => handleCoreSalaryM(e, emp)}
                              onKeyDown={handleKeyDown}
                              onClick={handleClickInput}
                            />
                          </div>
                        ) : emp.newMonthlyCoreCTC === "false" ||
                          emp.newMonthlyCoreCTC === null ||
                          emp.newMonthlyCoreCTC === 0 ? (
                          ""
                        ) : (
                          <p>
                            {/* <span className={style.money}>&#8377;</span>{" "} */}
                            {addCommas(emp.newMonthlyCoreCTC)}
                          </p>
                        )}
                      </td>
                      <td className={` ${style.td} ${style.makeCenter}`}>
                        {emp.newYearlyCoreCTC === "true" ? (
                          <div>
                            {/* <span className={style.money}>&#8377;</span> */}
                            <span
                              className={`${style.errorText} ${
                                showError.increasedAmountError
                                  ? style.showError
                                  : style.hideError
                              }`}
                            >
                              *
                            </span>
                            <input
                             style={{backgroundColor:"#e7e7e7"}}
                            disabled={true}
                              className={style.input_field}
                              type="number"
                              placeholder="Enter Annual Core CTC"
                              value={coreSalaryA}
                              onChange={(e) => handleCoreSalaryA(e, emp)}
                              onKeyDown={handleKeyDown}
                              onClick={handleClickInput}
                            />
                          </div>
                        ) : emp.newYearlyCoreCTC === "false" ||
                          emp.newYearlyCoreCTC === null ||
                          emp.newYearlyCoreCTC === 0 ? (
                          ""
                        ) : (
                          <p>
                            {/* <span className={style.money}>&#8377;</span>{" "} */}
                            {addCommas(emp.newYearlyCoreCTC)}
                          </p>
                        )}
                      </td>
                      <td className={` ${style.td} ${style.makeCenter}`}>
                        {emp.newMonthlyFullCTC === "true" ? (
                          <div>
                            {/* <span className={style.money}>&#8377;</span> */}
                            <span
                              className={`${style.errorText} ${
                                showError.increasedAmountError
                                  ? style.showError
                                  : style.hideError
                              }`}
                            >
                              *
                            </span>
                            <input
                             style={{backgroundColor:"#e7e7e7"}}
                            disabled={true}
                              className={style.input_field}
                              type="number"
                              placeholder="Enter Monthly Full CTC"
                              value={fullSalaryM}
                              // onChange={(e) => handleFullSalaryM(e, emp)}

                              onChange={(e) => setFullSalaryM(e.target.value)}
                              onKeyDown={handleKeyDown}
                              onClick={handleClickInput}
                            />
                            {/* <p
                              className={`${style.errorText} ${
                                showError.increasedAmountError
                                  ? style.showError
                                  : style.hideError
                              }`}
                            >
                              *Please Enter Monthly Full CTC
                            </p> */}
                          </div>
                        ) : emp.newMonthlyFullCTC === "false" ||
                          emp.newMonthlyFullCTC === null ||
                          emp.newMonthlyFullCTC === 0 ? (
                          ""
                        ) : (
                          <p>
                            {/* <span className={style.money}>&#8377;</span>{" "} */}
                            {addCommas(emp.newMonthlyFullCTC)}
                          </p>
                        )}
                      </td>
                      <td className={` ${style.td} ${style.makeCenter}`}>
                        {emp.newYearlyFullCTC === "true" ? (
                          <div>
                            {/* <span className={style.money}>&#8377;</span> */}
                            <span
                              className={`${style.errorText} ${
                                showError.increasedAmountError
                                  ? style.showError
                                  : style.hideError
                              }`}
                            >
                              *
                            </span>
                            <input
                            style={{backgroundColor:"#e7e7e7"}}
                            disabled={true}
                              className={style.input_field}
                              type="number"
                              placeholder="Enter Annual Full CTC"
                              value={fullSalaryA}
                              onChange={(e) => setFullSalaryA(e.target.value)}
                              onKeyDown={handleKeyDown}
                              onClick={handleClickInput}
                            />
                          </div>
                        ) : emp.newYearlyFullCTC === "false" ||
                          emp.newYearlyFullCTC === null ||
                          emp.newYearlyFullCTC === 0 ? (
                          ""
                        ) : (
                          <p>
                            {/* <span className={style.money}>&#8377;</span>{" "} */}
                            {addCommas(emp.newYearlyFullCTC)}
                          </p>
                        )}
                      </td>
                      <td
                        className={` ${style.td} ${style.commentsE} ${style.makeCenter}`}
                      >
                        {emp.empInfo.hrHeadCommentobj?.length > 0 ? (
                          <FaComments
                            className={style.commentsF}
                            onClick={() => {
                              setModalData({
                                cycleId,
                                eid: emp.empInfo.id,
                                cycleStatus: emp.empInfo.appraisalCycleStatus,
                              });
                              openModal();
                            }}
                          />
                        ) : (
                          <FaRegComments
                            onClick={() => {
                              setModalData({
                                cycleId,
                                eid: emp.empInfo.id,
                                cycleStatus: emp.empInfo.appraisalCycleStatus,
                              });
                              openModal();
                            }}
                          />
                        )}
                      </td>
                      <td className={` ${style.td}`}>
                        {emp.empInfo.status === "Approved" ? (
                          <span style={{ color: "green", fontSize: "12px" }}>
                            Approved
                          </span>
                        ) : (
                          <span style={{ color: "inherit", fontSize: "12px" }}>
                            {emp.empInfo.status}
                          </span>
                        )}
                      </td>
                      <td className={` ${style.td} ${style.actionTD}`}>
                        {emp.created === false &&
                        emp.empInfo.status === "Pending" ? (
                          <div className={style.action}>
                            <button
                              className={style.appr_btn}
                              title="Create Appraisal"
                              id={emp.empInfo.id}
                              onClick={(e) => {
                                handleAppraisalOpenModal(e, emp);
                                setApplicableDate(
                                  emp.applicableDate
                                    ? moment(emp?.applicableDate).format(
                                        "YYYY-MM-DD"
                                      )
                                    : null
                                );
                              }}
                            >
                              +
                            </button>
                            <button
                              className={style.appr_btn}
                              title="Hold"
                              id={emp.empInfo.id}
                              onClick={(e) => handleHoldAppraisal(e)}
                            >
                              ||
                            </button>

                            <img
                              id={emp.empInfo.id}
                              onClick={handleDelete}
                              src={deleteIcon}
                              className={style.delete}
                              title="Delete Employee"
                              tabIndex={0} // Makes the image focusable
                              aria-label="Delete Employee" // Provides an accessible name
                              onKeyDown={(e) => {
                                if (e.key === "Enter" || e.key === "Space") {
                                  handleDelete(); // Triggers the delete function on key presses
                                }
                              }}
                            />
                          </div>
                        ) : emp.created === true ? (
                          emp.edit === false ? (
                            <div className={style.action}>
                              <button
                                className={style.appr_btn}
                                onClick={handleSubmit}
                                id={emp.empInfo.id}
                              >
                                Submit
                              </button>
                              <button
                                className={style.appr_btn}
                                onClick={handleCancel}
                                id={emp.empInfo.id}
                              >
                                Cancel
                              </button>
                            </div>
                          ) : (
                            <div className={style.action}>
                              <button
                                className={style.appr_btn}
                                onClick={handleSubmit}
                                id={emp.empInfo.id}
                              >
                                Submit
                              </button>
                              <button
                                className={style.appr_btn}
                                onClick={handleEditCancel}
                                id={emp.empInfo.id}
                              >
                                Cancel
                              </button>
                            </div>
                          )
                        ) : emp.empInfo.status === "Submitted" ? (
                          <div className={style.action}>
                            <button
                              className={style.appr_btn}
                              onClick={handleApprove}
                              id={emp.empInfo.id}
                            >
                              Approve
                            </button>
                            <button
                              className={style.appr_btn}
                              onClick={handleSendBack}
                              id={emp.empInfo.id}
                            >
                              SendBack
                            </button>
                            <button
                              className={style.appr_btn}
                              onClick={(e) => handleEditOpenAModal(e, emp)}
                              id={emp.empInfo.id}
                            >
                              Edit
                            </button>
                            <img
                              id={emp.empInfo.id}
                              onClick={handleDelete}
                              src={deleteIcon}
                              className={style.delete}
                              title="Delete Employee"
                              onKeyDown={(e) => {
                                if (e.key === "Enter" || e.key === "Space") {
                                  handleDelete();
                                }
                              }}
                              tabIndex={0} // Make the image focusable
                              aria-label="Delete Employee" // Provide an accessible name
                            />
                          </div>
                        ) : emp.empInfo.appraisalCycleStatus.trim() !==
                          "Closed" ? (
                          <div className={style.action}>
                            <button
                              className={style.appr_btn}
                              onClick={(e) => handleEditOpenAModal(e, emp)}
                              id={emp.empInfo.id}
                            >
                              Edit
                            </button>
                            <img
                              id={emp.empInfo.id}
                              onClick={handleDelete}
                              src={deleteIcon}
                              className={style.delete}
                              title="Delete Employee"
                              onKeyDown={(e) => {
                                if (e.key === "Enter" || e.key === "Space") {
                                  handleDelete();
                                }
                              }}
                              tabIndex={0} // Make the image focusable
                              aria-label="Delete Employee" // Provide an accessible name
                            />
                          </div>
                        ) : (
                          ""
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            ) : (
              <div className={style.noUser}>No Employees Available</div>
            )}
          </table>
        </div>
      ) : (
        <AppraisalLoader />
      )}

      {!employeeListLoading && (
        <div className={style.pagination}>
          <Pagination
            pagesCount={pageCount}
            pageSize="25"
            onPageChange={handlePageChange}
            currentPage={currentPage}
          />
        </div>
      )}
      {/* Comment Modal */}
      <Modal
        open={modalIsOpen}
        onRequestClose={closeModal}
        center
        onClose={() => setModalIsOpen(false)}
        showCloseIcon={false}
        className={style.modal}
      >
        <div className={style.commentModal}>
          <HrHeadCom
            modalData={modalData}
            setModalIsOpen={setModalIsOpen}
            refetchEmployeeList={refetchEmployeeList}
          />
        </div>
      </Modal>
      <Modal
        open={pcModalIsOpen}
        onRequestClose={closePCModal}
        center
        onClose={() => setPCModalIsOpen(false)}
        showCloseIcon={false}
        className={style.modal}
      >
        <div className={style.commentModal2}>
          {projectDetailsComments?.length > 0 ? (
            <ProjectComments hrHeadComment={projectDetailsComments} />
          ) : (
            <div
              style={{
                position: "fixed",
                left: "45%",
                top: "50%",
                fontWeight: "700",
              }}
            >
              No Comments
            </div>
          )}
        </div>
      </Modal>
      <Modal
        open={projectModalIsOpen}
        onRequestClose={closeProjectModal}
        center
        onClose={() => setProjectModalIsOpen(false)}
        showCloseIcon={false}
      >
        <div className={style.projectModal}>
          <div className={style.projectUpperPortion}>
            <div className={style.projectHeading}>Project Details</div>
            <div
              className={style.projectComments}
              onClick={() => handleProjectComments()}
              tabIndex={0} // Makes the div focusable
              aria-label="View Project Comments" // Provides an accessible name
              onKeyDown={(e) => {
                if (e.key === "Enter" || e.key === "Space") {
                  handleProjectComments(); // Triggers the click function on key presses
                }
              }}
            >
              <FaRegComments />
            </div>
          </div>
          <table className={style.projectTable}>
            <thead className={style.projectThead}>
              <tr className={style.projecttrHead}>
                <th className={style.projectth}>Project Name</th>
                <th className={style.projectth}>Bandwidth</th>
                <th className={style.projectth}>Allocation</th>
                <th className={style.projectth}>DeAllocation</th>
                {/* <th className={style.projectth}>Comment</th> */}
              </tr>
            </thead>
            {projectDetailsObj?.length > 0 ? (
              <tbody className={style.projecttbody}>
                {projectDetailsObj.map((project) => {
                  return (
                    <tr className={style.projecttdRow} key={project.projectId}>
                      <td className={style.projecttd}>{project.projectName}</td>
                      <td className={style.projecttd}>
                        {project.bandwidth === "OneFourth" ? (
                          <p>25%</p>
                        ) : project.bandwidth === "Half" ? (
                          <p>50%</p>
                        ) : project.bandwidth === "Full" ? (
                          <p>100%</p>
                        ) : (
                          <p>75%</p>
                        )}
                      </td>
                      <td className={style.projecttd}>
                        {formatDate(project.allocationDate)}
                      </td>
                      <td className={style.projecttd}>
                        {formatDate(project.deallocationDate)}
                      </td>
                      {/* <td className={style.projecttd}>
                        Successfully Closed
                      </td> */}
                    </tr>
                  );
                })}
              </tbody>
            ) : (
              <div className={style.noProjects}>
                Project Details are not Available
              </div>
            )}
          </table>
        </div>
      </Modal>
      {/* Add Appraisal Modal */}
      <Modal
        open={aAMModalIsOpen}
        onRequestClose={() => setaAMModalIsOpen(false)}
        center
        onClose={() => {clearAllCheckBoxes(); setaAMModalIsOpen(false)}}
        showCloseIcon={false}
        className={style.modal}
      >
        <div className={style.appraisalModal}>
          <div className={style.appraisalModalHeader}>
            Kindly select from the following incentives ?
          </div>
          <div className={style.checkboxesContainer}>
            {Object.entries(appraisalModalData)?.map(([key, value], index) => (
              <div key={index} className={style.appraisal_form_check}>
                <input
                  type="checkbox"
                  className={style.form_check_input}
                  id={`checkbox-${key}`}
                  checked={appraisalModalCheckBoxes[key]}
                  onChange={(e) => handleCheckboxChange(key, e.target.checked)}
                />
                <label
                  className={style.form_check_label}
                  htmlFor={`checkbox-${key}`}
                >
                  {value}
                </label>
              </div>
            ))}
          </div>
          <div className={style.action}>
            <button className={style.appr_btn} onClick={handleSubmitAM}>
              Done
            </button>
          </div>
        </div>
      </Modal>
      <AppraisalFilter
        showFilter={showFilterModal}
        setShowFilter={setShowFilterModal}
        filterUpdateFuc={filterUpdateFuc}
        filterClearAllFuc={filterClearAllFuc}
        setFilterData={setFilterData}
        filterData={filterData}
        secondCard="Technology"
        secondCardlist={technologyList}
        thirdCard="Employee Type"
        thirdCardlist={empTypeList}
        forthCard="Client"
        forthCardlist={clientList}
        fiveCard="Reporting Manager"
        fiveCardlist={rmList}
        SixCard="Reward"
        SixCardlist={rewardListNew}
        Eightcard="Status"
        Eightcardlist={statusListNew}
        NineCard="Bond"
        Ninecardlist={["yes", "No"]}
        TenCard="Location"
        Tencardlist={locationList}
        filterApplied={filterApplied}
      />
    </>
  );
};

export default AppraisalCycleEmployeeListComponent;
