import React, { useState, useEffect } from "react";
import { Table } from "react-bootstrap";
import style from "./CommentHistory.module.css";
import AddComment from "../AddComment/AddComment";
import deleteImg from "../../../../../assets/icons8-delete-24.png";
import editImg from "../../../../../assets/icons8-edit-30.png";
import {
  getAllCandidateComments,
  updateCandidateComment,
} from "../../../../../Utils/axios/api";
import { useMutation, useQuery } from "react-query";
import moment from "moment";
import { notifySuccess } from "../../../utils/notify";
import { employeeId } from "../../../../../Utils/constants";
import { Modal } from "react-responsive-modal";

const getCommentForCandidate = (candidateId) => {
  const existingComments = JSON.parse(localStorage.getItem('unsavedComments1')) || [];
  const commentData = existingComments.find(c => c.candidateId === candidateId);
  return commentData ? commentData.comment : '';
};

function CommentHistory({ candidateId, setShowCommentModal, closeModal, positionId, myCandidateName, myClientName, handleAddComment, getCommentForPosition }) {
  const [obj, setObj] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedComment, setSelectedComment] = useState(null);
  const { data: CommentData, isLoading: commentLoading, refetch: refetchComments } = useQuery(
    ["getAllCandidateComments", candidateId],
    () => getAllCandidateComments(candidateId),
    {
      refetchOnWindowFocus: false,
      retry: false,
      staleTime: 30
    }
  );

  const updateCandidateCommentMutation = useMutation(updateCandidateComment, {
    onMutate: () => { },
    onSuccess: () => {
      notifySuccess("Comment Deleted Successfully");
      refetchComments();
    },
    onError: (err) => { },
  });

  const [showAddComment, setShowAddComment] = useState(false);

  useEffect(() => {
    const unsavedComment = getCommentForCandidate(candidateId);
    if (unsavedComment) {
      setShowAddComment(true);
    }
  }, [candidateId]);

  const handleAddComments = () => {
    setShowAddComment(true);
  };

  const handleEditComment = (comment) => {
    setShowAddComment(true);
    setObj(comment);
  };

  const deleteComment = () => {
    if (selectedComment) {
      updateCandidateCommentMutation.mutate({
        commentId: selectedComment.commentId,
        empId: selectedComment.employeeId,
      });
      setShowDeleteModal(false);
    }
  };

  const cancelDelete = () => {
    setShowDeleteModal(false);
    setSelectedComment(null);
  };

  const handleDeleteComment = (comment) => {
    setShowDeleteModal(true);
    setSelectedComment(comment);
  };

  return (
    <div>
      <DeleteConfirmationModel
        showDeleteModal={showDeleteModal}
        deleteComment={deleteComment}
        cancelDelete={cancelDelete}
      />
      <div className={style.description_box1}>
        <div className={style.span2}>
          {myCandidateName === undefined && myClientName === undefined ? (
            <p></p>
          ) : myCandidateName && myClientName ? (
            <p>{myCandidateName}</p>
          ) : (
            <p>{myCandidateName}</p>
          )}
        </div>
        <div className={style.maindivContent}></div>
        <div
          className={style.original_table}
          style={{
            backgroundColor: "white",
            minHeight: "3rem",
            marginTop: "7px",
          }}
          >
          <>
            {!commentLoading && CommentData?.length > 0 ? (
              <Table borderless size="sm" style={{ fontSize: "14.5px" }}>
                <thead
                  style={{
                    backgroundColor: "#F0F0F0",
                    position: "sticky",
                    top: "0px",
                  }}
                >
                  <tr>
                    <th style={{ padding: "10px", width: "3.5rem" }}>Date</th>
                    <th style={{ padding: "10px", minWidth: "100px" }}>Added By</th>
                    <th style={{ padding: "10px" }}>Comment</th>
                    <th style={{ padding: "10px", minWidth: "75px" }}>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {CommentData.map((comment, index) => (
                    <tr key={index}>
                      <td style={{ paddingLeft: "10px", minWidth: "4rem" }}>
                        {comment?.created
                          ? moment(comment.created).year() === moment().year()
                            ? moment(comment.created).format("DD MMM")
                            : moment(comment.created).format("DD MMM YYYY")
                          : null}
                      </td>
                      <td style={{ paddingLeft: "10px", minWidth: "170px", maxWidth: "170px" }}>
                        {comment?.employeeName}
                      </td>
                      <td style={{ paddingLeft: "10px", whiteSpace: "pre-wrap", overflowWrap: "anywhere" }}>
                        {comment?.comment}
                      </td>
                      {comment.employeeId === employeeId ? (
                        <td style={{ paddingLeft: "10px", minWidth: "75px" }}>
                          <img
                            src={editImg}
                            height="20px"
                            width="20px"
                            alt="edit"
                            style={{ cursor: "pointer" }}
                            title="Edit Comment"
                            onClick={() => handleEditComment(comment)}
                            onKeyDown={() => handleEditComment(comment)}
                            tabIndex={0}
                          />
                          <img
                            src={deleteImg}
                            alt="delete"
                            height="22px"
                            width="35px"
                            style={{ cursor: "pointer", paddingLeft: "10px" }}
                            title="Delete Comment"
                            onClick={() => {
                              handleDeleteComment(comment);
                            }}
                            onKeyDown={() => handleDeleteComment(comment)}
                            tabIndex={0}
                          />
                        </td>
                      ) : (
                        <td style={{ paddingLeft: "10px", minWidth: "75px" }}></td>
                      )}
                    </tr>
                  ))}
                </tbody>
              </Table>
            ) : (
              <>
                <Table borderless size="sm" style={{ fontSize: "14.5px" }}>
                  <thead
                    style={{
                      backgroundColor: "#F0F0F0",
                      position: "sticky",
                      top: "0px",
                    }}
                  >
                    <tr>
                      <th style={{ padding: "10px", width: "3.5rem" }}>Date</th>
                      <th style={{ padding: "10px", minWidth: "100px" }}>Added By</th>
                      <th style={{ padding: "10px" }}>Comment</th>
                      <th style={{ padding: "10px", minWidth: "75px" }}>Action</th>
                    </tr>
                  </thead>
                </Table>
                <p
                  style={{
                    fontWeight: "600",
                    fontSize: "14px",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  No Comments Found
                </p>
              </>
            )}
          </>

        </div>
        <div className={style.addButton}>
          {!showAddComment && (
            <button className={style.add_btn} onClick={handleAddComments}>
              Add Comment
            </button>
          )}
        </div>
      </div>
      {showAddComment && (
        <AddComment
          candidateId={candidateId}
          showAddComment={showAddComment}
          setShowAddComment={setShowAddComment}
          obj={obj}
          setObj={setObj}
          closeModal={closeModal}
          setShowCommentModal={setShowCommentModal}
          positionId={positionId}
          handleAddComment={handleAddComment}
          getCommentForPosition={getCommentForPosition}
        />
      )}
    </div>
  );
}

const DeleteConfirmationModel = ({
  showDeleteModal,
  cancelDelete,
  deleteComment,
}) => {
  return (
    <Modal
      open={showDeleteModal}
      onClose={() => {
        showDeleteModal(false);
      }}
      showCloseIcon={false}
      center
    >
      <div className={style.deleteModal}>
        <div> Do you want to delete this comment ?</div>
        <div className={style.btnRow}>
          <button className={style.yes_btn} onClick={deleteComment}>
            Yes
          </button>
          <button className={style.no_btn} onClick={cancelDelete}>
            No
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default CommentHistory;
