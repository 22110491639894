import SideBar from "../sidebar/sidebar";
import style from "./resourceMargin.module.css";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { getResourceMargins } from "../../../../Utils/axios/pcm_Api";
import Pagination from "../../../Projects_and_Resource_Master/CommonComponent/Pagination";
import { notifyError } from "../../../atsDashboard/utils/notify";
import Loading from "../loading/loading";
import { addCommasPCM } from "../../../Appraisal_System/commonComponent/utils/AddCommas";
import useValidationAccess from "../../../custumHooks/useValidationAccess";
import { projectCostingAndMarginAccessCode } from "../../../../Utils/constants";
import DropSwitchCommPCM from "../../CommonComponent/dropSwitchPCM";

export default function ResourceMargin() {
  const { checkValidateAccess } = useValidationAccess();
  return (
    <div style={{ display: "flex" }}>
      {checkValidateAccess(
        projectCostingAndMarginAccessCode?.resourceMargins
      ) && (
        <>
          <SideBar />
          <Body />
        </>
      )}
    </div>
  );
}

function Body() {
  const limit = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);

  const [isChecked, setIsChecked] = useState(false);
  const [selectedValue, setSelectedValue] = useState("Onsite T&M");

  const { data, isLoading } = useQuery(
    ["getResourceMargins", isChecked, currentPage, selectedValue],
    () => getResourceMargins(limit, currentPage, !isChecked, selectedValue === "Offshore T&M" ? false : true),
    {
      retry: false,
      refetchOnWindowFocus: false,
      onError: (err) => notifyError("Something went wrong!"),
    }
  );

  const handlePageChange = (page, status) => {
    if (status === "next") {
      setCurrentPage(page + 1);
    } else if (status === "prev") {
      setCurrentPage(page - 1);
    }
  };

  const tableBody =
    data?.resources?.length > 0 ? (
      data?.resources?.map((item) => <Row key={item?.invoiceId} {...item} />)
    ) : (
      <tr className={style.tRow}>
        <td colSpan={10} style={{ textAlign: "center" }}>
          No Resource Margin Found
        </td>
      </tr>
    );

  useEffect(() => {
    setPageCount(
      data?.resourcesCount % limit === 0
        ? data?.resourcesCount / limit
        : Math.floor(data?.resourcesCount / limit) + 1
    );
  }, [data]);

  const handleOptionChange = (e)  => {
    setSelectedValue(e.target.value);
  }

  return (
    <div className={style.body}>
      <div className={`${style.header} $${style.gap1rem}`}>
        <div className={`${style.heading} ${style.gap1rem} ${style.w27rem}`}>
          <div>
            <span className={style.breadCrumb}>Resource Margins</span>
          </div>
          <div>
            <p className={style.countPCM}>
              <span style={{ fontSize: "1.235rem", fontWeight: "500" }}>
                {data?.resourcesCount}
              </span>
            </p>
          </div>
          <div className={style.allProjectDropswitch}>
            <DropSwitchCommPCM
              isChecked={isChecked}
              handleChange={() => setIsChecked((prev) => !prev)}
              label="&nbsp; All"
            />
          </div>
        </div>{" "}
        <div className={style.radioBtnContainer}>
          <div className={style.radioButtonsRow}>
          <label>
                <input
                  type="radio"
                  name="location"
                  value="Onsite T&M"
                  checked={selectedValue === "Onsite T&M"}
                  onChange={handleOptionChange}
                  className={style.labelRM}
                />
                Onsite
              </label>
              <label>
                <input
                  type="radio"
                  name="location"
                  value="Offshore T&M"
                  checked={selectedValue === "Offshore T&M"}
                  onChange={handleOptionChange}
                  className={style.labelRM}
                />
                Offshore T&M
              </label>
          </div>
          </div>
      </div>
      <div>
        {isLoading ? (
          <Loading />
        ) : (
          <div className={style.tableContainer}>
            <table>
              <tbody>
                <tr className={style.tHeading}>
                  <th>Code</th>
                  <th>Name</th>
                  <th>Client Name</th>
                  <th className={style.ppc}>
                    Partner's Rate/Salary
                    <br /> (excl tax)
                  </th>
                  <th className={style.ppc}>
                    Client's Rate <br />
                    (excl tax)
                  </th>
                  <th>Margin</th>
                  <th>Margin %</th>
                  <th>Technology</th>
                  <th>
                    Experience
                  </th>
                  <th>Employer</th>
                </tr>
                {tableBody}
              </tbody>
            </table>
          </div>
        )}
      </div>
      {!isLoading && (
        <div className={style.pagination} style={{ marginRight: "29px" }}>
          <Pagination
            pagesCount={pageCount}
            pageSize={20}
            onPageChange={handlePageChange}
            currentPage={currentPage}
            color="#954B00"
            color2="white"
          />
        </div>
      )}
    </div>
  );
}

function Row({
  onsiteResourceId,
  empId,
  empName,
  activeEmp,
  empCode,
  clientId,
  clientName,
  currentClientRate,
  skillId,
  skillName,
  experience,
  employer,
  resourceMargin,
  resourceMarginPercentage,
  salaryOrPartnerRate,
}) {
  function roundToZeroDecimalPlaces(number) {
    const tempVal = addCommasPCM(number.toFixed(0));
    return tempVal === "0" ? "-" : tempVal;
  }

  const resourceMarginPercentageTemp = roundToZeroDecimalPlaces(
    resourceMarginPercentage || 0
  );

  const updatedExp = experience
    ? experience % 12 === 0
      ? `${(experience / 12).toFixed(0)}Y`
      : `${(experience / 12).toFixed(0)}Y - ${experience % 12}M`
    : "-";

  return (
    <tr className={style.tRow}>
      <td>{empCode}</td>
      <td style={{ fontWeight: "500" }}>{empName}</td>
      <td>{clientName}</td>
      <td>
        {salaryOrPartnerRate
          ? roundToZeroDecimalPlaces(salaryOrPartnerRate)
          : "NA"}
      </td>
      <td>
        {currentClientRate ? roundToZeroDecimalPlaces(currentClientRate) : "NA"}
      </td>
      <td>{resourceMargin ? roundToZeroDecimalPlaces(resourceMargin) : "-"}</td>
      <td>{resourceMarginPercentageTemp}</td>
      <td>{skillName}</td>
      <td>{updatedExp}</td>
      <td>{employer}</td>
    </tr>
  );
}
