import React, { useState } from "react";
import style from "./ProjectManagement.module.css";
import Pagination from "../../CommonComponent/pagination/pagination";
import AddIcon from "@mui/icons-material/Add";
import { useQuery } from "react-query";
import Select from "react-select";
import FilterListIcon from "@mui/icons-material/FilterList";
import {
  projectManagementList,
  getProjectTypes,
  getClientStatusList,
  getProjectsForFilter,
  getClientListFilter,
  getBillingModels,
} from "../../../../Utils/axios/PortalManagement";
import moment from "moment";
import Loading from "../../../../GenericComponent/Loading/Loading";
import { Modal } from "react-responsive-modal";
import searchIcon from "../../../../assets/Icon-search.png";
import "react-responsive-modal/styles.css";
import { AiOutlineClose } from "react-icons/ai";

const ProjectManagement = ({ setNextScreen, setPrevData, prevData }) => {
  const [page, setPage] = useState(1);
  const [showModal, setShowModal] = useState(false);
  const [checkboxData, setCheckboxData] = useState({
    catogaryprojectId: [],
    statusTypeId: [],
    billingModelId: [],
  });
  const [filterData, setFilterData] = useState({
    catogaryprojectId: [],
    clientId: [],
    projectId: [],
    searchName: "",
    statusTypeId: [],
    billingModelId: [],
  });

  const handlePageChange = (page, status) => {
    if (status === "next") {
      setPage(page + 1);
    } else {
      setPage(page - 1);
    }
  };

  const { data: projectManagementListData, isLoading } = useQuery(
    ["getProjectManagementList", { page, filterData }],
    () =>
      projectManagementList({ limit: 10, page: page, filterData: filterData })
  );

  const { data: getProjectTypesListData } = useQuery(["getProjectTypes"], () =>
    getProjectTypes()
  );

  const { data: getClientStatusListData } = useQuery(
    ["getClientStatusList"],
    () => getClientStatusList()
  );
  const { data: getBillingModelsData } = useQuery(
    ["getBillingModels"],
    () => getBillingModels()
  );
  const { data: getClientData } = useQuery(
    ["getClientsForFilter"],
    getClientListFilter,
    {
      refetchOnWindowFocus: false,
    }
  );
  const clientOption = getClientData?.map((item) => {
    return {
      value: item?.id,
      label: item?.name,
    };
  });
  const { data: getProjectData } = useQuery(
    ["getProjectsForFilter"],
    getProjectsForFilter,
    {
      refetchOnWindowFocus: false,
    }
  );
  const projectOption = getProjectData?.map((item) => {
    return {
      value: item?.id,
      label: item?.name,
    };
  });

  const handleCheckBoxChange = (type, id) => {  
    if (type === "projectTypeId") {
      const isChecked = checkboxData["catogaryprojectId"]?.includes(id);

      if (isChecked) {
        setCheckboxData((prevData) => ({
          ...prevData,
          catogaryprojectId: prevData["catogaryprojectId"]?.filter(
            (itemId) => itemId !== id
          ),
        }));
      } else {
        setCheckboxData((prevData) => ({
          ...prevData,
          catogaryprojectId: [...prevData["catogaryprojectId"], id],
        }));
      }
    }

    if (type === "billingTypeId") {
      const isChecked = checkboxData["billingModelId"]?.includes(id);

      if (isChecked) {
        setCheckboxData((prevData) => ({
          ...prevData,
          billingModelId: prevData["billingModelId"]?.filter(
            (itemId) => itemId !== id
          ),
        }));
      } else {
        setCheckboxData((prevData) => ({
          ...prevData,
          billingModelId: [...prevData["billingModelId"], id],
        }));
      }
    }

    if (type === "statusId") {
      const isChecked = checkboxData["statusTypeId"]?.includes(id);

      if (isChecked) {
        setCheckboxData((prevData) => ({
          ...prevData,
          statusTypeId: prevData["statusTypeId"]?.filter(
            (itemId) => itemId !== id
          ),
        }));
      } else {
        setCheckboxData((prevData) => ({
          ...prevData,
          statusTypeId: [...prevData["statusTypeId"], id],
        }));
      }
    }
  };

  const handleApply = () => {
    setPage(1);
    setFilterData({
      ...filterData,
      ...checkboxData,
    });
    setShowModal(false);
  };

  return (
    <>
      {isLoading && <Loading />}
      <div className={style.project_main_div}>
        <div className={style.header}>
          <div className={style.left_div}>
            <span>Project Management</span>
            <AddIcon
              onClick={() => {
                setNextScreen("createProject");
                setPrevData({
                  ...prevData,
                  sidebar: "projectManagement",
                  page: "createProject",
                  page1: "createProject",
                  page2: null,
                  page3: null,
                  page4: null,
                  page5: null,
                  page6: null,
                  active: "createProject",
                });
              }}
              className={style.create_btn}
            />
            <div className={style.count_div}>
              {projectManagementListData?.totalproject || 0}
            </div>
          </div>
          <div className={style.middleDiv}>
            <div
              onClick={() => setShowModal(!showModal)}
              className={style.filter_div}
            >
              <FilterListIcon style={{ marginRight: "4%" }} />
              Filter
            </div>
          </div>
          <div className={style.right_div}>
            <div className={style.dropdown_div}>
              <div className={style.s1}>
                {filterData?.projectId.length === 0 ? (
                  <span className={style.cp_serchIcon}>
                    <img src={searchIcon} alt="searchIcon" />
                  </span>
                ) : (
                  <span
                    className={style.cp_serchIcon}
                    onClick={() => {
                      setFilterData({ ...filterData, projectId: [] })
                    }}
                  >
                    <AiOutlineClose />
                  </span>
                )}
                <Select
                  styles={style.coreSkillInput}
                  placeholder="Project Name"
                  components={{
                    DropdownIndicator: () => null,
                    IndicatorSeparator: () => null,
                  }}
                  value={
                    filterData?.projectId.length
                      ? projectOption?.find(
                        (e) => e?.value === filterData?.projectId
                      )
                      : ""
                  }
                  onChange={(e) => setFilterData({ ...filterData, projectId: [e?.value] })}
                  options={projectOption}
                />
              </div>
              <div className={style.s1}>
                {filterData?.clientId.length === 0 ? (
                  <span className={style.cp_serchIcon}>
                    <img src={searchIcon} alt="searchIcon" />
                  </span>
                ) : (
                  <span
                    className={style.cp_serchIcon}
                    onClick={() => {
                      setFilterData({ ...filterData, clientId: [] })
                    }}
                  >
                    <AiOutlineClose />
                  </span>
                )}
                <Select
                  styles={style.coreSkillInput}
                  placeholder="Client Name"
                  components={{
                    DropdownIndicator: () => null,
                    IndicatorSeparator: () => null,
                  }}
                  value={
                    filterData?.clientId.length
                      ? clientOption?.find(
                        (e) => e?.value === filterData?.clientId
                      )
                      : ""
                  }
                  onChange={(e) => setFilterData({ ...filterData, clientId: [e?.value] })}
                  options={clientOption}
                />
              </div>
            </div>
          </div>
        </div>

        <div className={style.table_div}>
          <table className={style.table}>
            <thead className={style.table_heading}>
              <tr>
                <th className={`${style.table_head}`}>Client Name</th>
                <th className={`${style.table_head}`}>Project Name</th>
                <th className={`${style.table_head}`}>PM/PC</th>
                <th className={`${style.table_head}`}>Start Date</th>
                <th className={`${style.table_head}`}>Type of Project</th>
                <th className={`${style.table_head}`}>Billing Model</th>
                <th className={`${style.table_head}`}>Status</th>
              </tr>
            </thead>
            <tbody>
              {projectManagementListData?.projects?.map((item, idx) => (
                <tr key={idx}>
                  <td className={`${style.table_column}`}>
                    <p className={style.para}>
                      <span
                        onClick={() => {
                          setNextScreen("ViewProject");
                          setPrevData({
                            ...prevData,
                            clientId: item.clientid,
                            sidebar: "projectManagement",
                            page: "ViewProject",
                            page1: "ViewProject",
                            page2: null,
                            page3: null,
                            page4: null,
                            page5: null,
                            page6: null,
                            active: "ViewProject",
                            projectId: item?.projectid,
                          });
                        }}
                        className={style.cursor}
                      >
                        {item?.clientName || "NA"}
                      </span>
                    </p>
                  </td>
                  <td className={`${style.table_column}`}>
                    {item?.projectName || "NA"}
                  </td>
                  <td className={`${style.table_column}`}>
                    {item?.empName || "NA"}
                  </td>
                  <td className={`${style.table_column}`}>
                    {item?.startdate
                      ? moment(item?.startdate)?.format("DD MMM YY")
                      : "NA"}
                  </td>
                  <td className={`${style.table_column}`}>
                    {item?.catogaryprojectName || "NA"}
                  </td>
                  <td className={`${style.table_column}`}>
                    {item?.billingModelName || "-"}
                  </td>
                  <td className={`${style.table_column}`}>
                    {item?.statusTypeName || "NA"}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className={style.pagination_div}>
          <Pagination
            onPageChange={handlePageChange}
            currentPage={page}
            color="#c3b14b"
            color2="white"
            pagesCount={projectManagementListData?.totalproject / 10}
          />
        </div>
      </div>

      <Modal
        open={showModal}
        center
        onClose={() => setShowModal(false)}
        showCloseIcon={false}
        classNames={{
          modal: "reactmodal",
        }}
      >
        <div className={style.modal_div}>
          <div className={style.left_modal_div}>
            <div className={style.modal_heading}>Type of Project</div>
            {getProjectTypesListData?.map((item) => (
              <div className={style.input_div}>
                <input
                  onClick={() => {
                    handleCheckBoxChange("projectTypeId", item?.id);
                  }}
                  className={style.checkbox}
                  type="checkbox"
                  checked={checkboxData?.catogaryprojectId?.includes(item?.id)}
                />
                <span className={style.checkbox_name}>{item?.name}</span>
              </div>
            ))}
          </div>
          <div className={style.right_modal_div}>
            <div className={style.modal_heading}>Status</div>
            {getClientStatusListData
              ?.filter((item) => item?.name !== "Active")
              ?.map((item) => (
                <div className={style.input_div}>
                  <input
                    onClick={() => {
                      handleCheckBoxChange("statusId", item?.id);
                    }}
                    className={style.checkbox}
                    type="checkbox"
                    checked={checkboxData?.statusTypeId?.includes(item?.id)}
                  />
                  <span className={style.checkbox_name}>
                    {item?.name === "Inactive"
                      ? "Hold"
                      : item?.name === "Resigned"
                        ? "Closed"
                        : null}
                  </span>
                </div>
              ))}
            <div className={style.input_div}>
              <input
                onClick={() => {
                  handleCheckBoxChange("statusId", "");
                }}
                className={style.checkbox}
                type="checkbox"
                checked={checkboxData?.statusTypeId?.includes("")}
              />
              <span className={style.checkbox_name}>All</span>
            </div>
          </div>
          <div className={style.left_modal_div}>
            <div className={style.modal_heading}>Billing Type</div>
            {getBillingModelsData?.map((item) => (
              <div className={style.input_div}>
                {console.log(item)}
                <input
                  onClick={() => {
                    handleCheckBoxChange("billingTypeId", item?.id);
                  }}
                  className={style.checkbox}
                  type="checkbox"
                  checked={checkboxData?.billingModelId?.includes(item?.id)}
                />
                <span className={style.checkbox_name}>{item?.name}</span>
              </div>
            ))}
          </div>
        </div>
        <div className={style.btn_div}>
          <button
            onClick={() => {
              setShowModal(false);
              setFilterData({
                ...filterData,
                catogaryprojectId: [],
                // clientId: [],
                // projectId: [],
                searchName: "",
                statusTypeId: [],
                billingModelId: [],
              });
              setCheckboxData({
                catogaryprojectId: [],
                statusTypeId: [],
                billingModelId: [],
              });
              setPage(1);
            }}
            className={style.btn1}
          >
            Clear All
          </button>
          <button onClick={() => handleApply()} className={style.btn2}>
            Apply
          </button>
        </div>
      </Modal>
    </>
  );
};

export default ProjectManagement;
