import React, { useState, useEffect } from "react";
import style from "./EditTaskComponent.module.css";
import calendar from "../../../../assets/calendar.png";
import { useHistory } from "react-router-dom";
import axios from "axios";
import cookie from "react-cookies";
import Moment from "moment";
import Select from "react-select";
import cancel from "../../../../assets/cancell.png";
import { customStylesForTaskTracker, customStylesForTaskTracker2, customStylesForTaskTracker3, customStylesForTaskTracker4, customStylesForTaskTrackerSpaces } from "../../../Projects_and_Resource_Master/Clients/component/style";
import constants, { employeeId } from "../../../../Utils/constants";
import DatePicker from "react-datepicker";
import { useLocation } from "react-router";
import { useQuery } from "react-query";
import { getActiveMembersBySpace } from "../../taskTrackerAPIs/taskTrackerAPI";

const EditTaskComponent = (props) => {
  const [employeeList, setEmployeeList] = useState([]);
  const [creationDate, setCreationDate] = useState(props.taskData.created);
  const [taskId, settaskId] = useState(props.taskData.taskId);
  const [taskTitle, settaskTitle] = useState(props.taskData.title);
  const [myTaskDetail, setMyTaskDetailByID] = useState();
  const [FunctionalAreaName, setFuncname] = useState("");
  const [taskDesc, settaskDesc] = useState(props.taskData.description);
  const [validate, setValidate] = useState(false);
  const location = useLocation()
  const isClosedTask = location?.state?.closedTask


  const [taskPEDate, settaskPEDate] = useState(
    Moment(props.taskData.plannedEndDate)
  );
  const [taskAssignedTo, settaskAssignedTo] = useState(
    props.taskData.assignedTo ? props.taskData.assignedTo : employeeId
  );
  const [taskAssignedToName, settaskAssignedToName] = useState(
    props.taskData.name ? props.taskData.name : props.taskData.assignedToName
  );
  const [taskPriority, settaskPriority] = useState(props.taskData.priority);
  const [project, setPRoject] = useState();
  const [groupList, setGroupsList] = useState([]);
  const [functionalAreaa, setFunctionalAreaa] = useState(null);
  const [taskStatus, settaskStatus] = useState(props.taskData.status);
  const [showCal, setShowCal] = useState(false);
  const [arrayObj, setarrayObj] = useState([]);
  const [functionalArea, setFunctionalArea] = useState([]);
  const [peojectList, setProjectList] = useState([]);
  const [relatedTo, setRelataedTo] = useState();
  const [groupId, setGroupid] = useState(0)
  const [showErr, setShowErr] = useState(false);
  const [dateChange, setDateChange] = useState(false);
  const [RelatedToID, setRelatedToID] = useState();
  const [disable, setDisable] = useState(true);
  const [disablee, setDisablee] = useState();
  const [projectShow, setProjectShow] = useState(false);

  const [showDate, setShowDate] = useState(
    Moment(props.taskData.plannedEndDate).format("D MMM YY")
  );
  employeeList.sort((a, b) =>
    a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1
  );

  const getemployee = employeeList?.map((i) => ({
    value: i.id,
    label: i.name,
  }));

  const getGroups = groupList?.map((i) => ({
    value: i.id,
    label: i.name,
  }));
  function handleChange(id) {
    settaskAssignedTo(id);
  }
  function handleChange1(id) {
    setRelatedToID(id);
  }
  functionalArea?.sort((a, b) =>
    a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1
  );
  const FuncTionalAreaClear = () => {
    // setFunctionalOption(null)
    setFunctionalArea([]);
    setFunctionalAreaa(null);
    getFunctionalArea();
    setFuncname("");
  };

  const CustomInput = React.forwardRef((props, ref) => {
    return (
      <div onClick={props.onClick}>
        <label ref={ref}>{props.value || props.placeholder}</label>
        <img
          type="image/svg+xml"
          width="20px"
          // style={ }}
          style={{ position: "absolute", right: "5%", top: "28%", zIndex: "0" }}
          src={calendar}
          onClick={props.onClick}
          alt=""
        />
      </div>
    );
  });

  const changeDate = (e) => {
    settaskPEDate(e);
    setShowDate(Moment(e).format("D MMM YY"));
    setShowCal(false);
    setDateChange(true);
  };

  const found = () => {
    // if()
    // return element.id === 2
    var e = document.getElementById("selectElement");
    var value = e.options[e.selectedIndex].value; // get selected option value
    var text = e.options[e.selectedIndex].text;
    settaskAssignedToName(text);
    settaskAssignedTo(value);
  };

  const functionalA = (e) => {
    if (e.target.value == 1) {
      setDisable(false);
      setProjectShow(true);
      getProject();
      setDisablee(e.target.value);
      // myTaskDetail?.functionalFieldArea=="Delivery"?project:null
    } else {
      setDisable(true);
      setPRoject(null);
      setProjectShow(false);

      setProjectList([]);
    }
    setFunctionalAreaa(e.target.value);
  };

  const url = constants.apiBaseUrl;
  const url2 = constants.apiBaseUrl2;
  const baseUrl = constants.apiBaseUrlResource;

  const updateData = () => {
    if (!taskAssignedTo || !taskPEDate || !taskPriority || !taskTitle) {
      setValidate(true);
      return;
    }
    const data = {
      taskId: taskId,
      title: taskTitle,
      description: taskDesc,
      planedEndDate: Moment(taskPEDate).format("YYYY-MM-DD hh:mm"),
      assignedTo: taskAssignedTo,
      priority: taskPriority,
      status: taskStatus,
      created: Moment(creationDate).format("YYYY-MM-DD hh:mm"),
      employeeRelatedToTask: RelatedToID,
      functionalFieldId: functionalAreaa,
      projectName: project,
      localDateTime: Moment(taskPEDate).format("YYYY-MM-DD hh:mm"),
      groupId: groupId
    };

    const oldData = {
      taskId: taskId,
      title: taskTitle,
      description: taskDesc,
      employeeRelatedToTask: RelatedToID,
      functionalFieldId: functionalAreaa,
      projectName: project,
      planedEndDate: Moment(taskPEDate).format("YYYY-MM-DD hh:mm"),
      assignedTo: taskAssignedTo,
      priority: taskPriority,
      status: taskStatus,
      localDateTime: Moment(taskPEDate).format("YYYY-MM-DD hh:mm"),
      name: taskAssignedToName,
      created: Moment(creationDate).format("YYYY-MM-DD hh:mm"),
      groupId: groupId
    };

    axios
      .post(url2 + "/editTask", data, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
          employeeId: employeeId,
        },
      })
      .then((res) => {
        if (res.data.responseStatus) {
          setShowErr(true);
          history.goBack();
        } else {
          history.goBack();
        }
      })
      .catch((err) => {
        return err;
      });
  };
  const statusOptions = ["Assigned", "Done", "Onhold"];
  const getFunctionalArea = () => {
    axios
      .get(url2 + "/getAllFunctionalArea", {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
          employeeId: employeeId,
        },
      })
      .then((res) => {
        setFunctionalArea(res.data.responseData);
      })
      .catch(() => { });
  };

  const getProject = () => {
    axios
      .get(baseUrl + "/getAllActiveProject", {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
          employeeId: employeeId,
        },
      })
      .then((res) => {
        setProjectList(res.data.responseData);
      })
      .catch(() => { });
  };
  const {data:getActiveMember} = useQuery(
    ["getActiveMembersBySpace", groupId],
    ()=>{if(groupId) return getActiveMembersBySpace(groupId)},
    {
      refetchOnWindowFocus: false,
    }
  );
  let getActiveMembers;
  if(Array.isArray(getActiveMember))
  getActiveMembers = getActiveMember?.map((i)=>({
    value: i.memberId,
    label: i.memberName,
  }));

  const getSingleDetailsById = (id) => {
    axios
      .get(url2 + `/getTaskDetailsByTaskId?empId=${employeeId}&taskId=${id}`, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
        },
      })
      .then((res) => {
        if (res.data.responseData?.functionalFieldArea == "Delivery") {
          setDisable(false);
        } else {
          setDisable(true);
        }
        setMyTaskDetailByID(res.data.responseData);
        setFuncname(res.data.responseData?.functionalFieldArea);
        setPRoject(res.data.responseData?.projectName);
        setFunctionalAreaa(res.data.responseData?.functionalFieldAreaId);
        setRelatedToID(res.data.responseData?.taskRelatedToEmployeeId);
        setRelataedTo(res.data.responseData?.taskRelatedToEmployee);
        setGroupid(res.data.responseData?.groupId)
        if (res.data.responseData?.groupId !== null) {
          setDisable(false)
        }


      })
      .catch(() => { });
  };
  useEffect(() => {
    async function getGroupData() {
      await axios
        .get(url2 + `/groupListing`, {
          headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization: ` ${cookie.load("token")}`,
            employeeId: employeeId,
          },
        })
        .then((res) => {
          if(Array.isArray(res.data.responseData)) setGroupsList(res.data.responseData);

        })
        .catch((err) => {
          return err;
        });
    }
    getGroupData();
  }, []);
  const employeedata = () => {
    const historyData = axios
      .get(url + "/getEmployeeList", {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
          employeeId: employeeId,
        },
      })
      .then((res) => {
        setEmployeeList(res.data.responseData.employeeList);
        setarrayObj(
          employeeList.map((item) => {
            return {
              label: item.name,
              id: item.id,
            };
          })
        );
      })
      .catch((err) => {
        return err;
      });
  };

  const history = useHistory();
  useEffect(() => {
    getSingleDetailsById(props.taskData?.taskId);
    employeedata();
    getFunctionalArea();
    getProject();
  }, []);

  const CustomInputCross = ({groupId, setGroupid}) => {
    if (!groupId) return null; 
    return (
      <span style={{position: "absolute", height: "38px", width: "100%", zIndex: '1', display: "flex", alignItems: "center", paddingBottom: "2px" }}>
        <img
          src={cancel}
          alt="dropdownIcon"
          style={{ position: "absolute", zIndex: "1", marginLeft: "83%", cursor: "pointer"}}
          height="15"
          onClick={() => {setGroupid(null)}}
        />
      </span>
    );
  };

  return (
    <div className={style.rightSection}>
      <div className={style.container}>
        <span>Edit Task</span>
        <div className={style.createTask}>
          <div id="create_task_id" className={style.gridContainer}>
            <div style={{ width: '95.5%' }}>
              <label className={style.titleLabel}>
                Task Title<span style={{ color: "red" }}>*</span>
                <input
                  className={style.input}
                  autocomplete="off"
                  name="task_title"
                  type="input"
                  id="tast_title"
                  value={taskTitle}
                  onChange={(e) => settaskTitle(e.target.value)}
                />
                <p
                  className={
                    validate && !taskTitle
                      ? style.errorText
                      : style.errorTextHidden
                  }
                >
                  Please Enter Task Title
                </p>
              </label>
            </div>
            <div>
              <label className={style.descLabel}>
                Task Description
                <textarea
                  name="task_description"
                  type="number"
                  id="task_description"
                  rows="7"
                  cols="78"
                  value={taskDesc}
                  onChange={(e) => settaskDesc(e.target.value)}
                  className={style.text}
                  style={{ marginTop: "0.7em" }}
                />
              </label>
            </div>
            <div style={{ display: 'flex' }}>
              <div style={{ width: '33%' }}>
                <label className={style.assignLabel}>
                  Assigned To<span style={{ color: "red" }}>*</span>
                  <Select
                    styles={customStylesForTaskTracker3}
                    classNamePrefix={"create_position"}
                    placeholder="Search By Client Name"
                    components={{
                      DropdownIndicator: () => null,
                      IndicatorSeparator: () => null,
                    }}
                    value={getemployee?.find((i) => i.label === taskAssignedToName)}
                    onChange={(e) => {
                      settaskAssignedToName(e.label);
                      handleChange(e.value);
                    }}
                    options={getemployee}
                  />
                  <p
                    className={
                      validate && !taskAssignedTo
                        ? style.errorText
                        : style.errorTextHidden
                    }
                  >
                    Please Select Employee Name
                  </p>
                </label>
              </div>
              <div style={{ width: '33%' }}>
                <label className={style.priorityLabel12}>
                  Priority<span style={{ color: "red" }}>*</span> <br />
                  <select
                    className={style.input}
                    onChange={(e) => settaskPriority(e.target.value)}
                    value={taskPriority}
                  >
                    <option value="High">High</option>
                    <option value="Medium">Medium</option>
                    <option value="Low">Low</option>
                  </select>
                </label>
              </div>
              <div style={{ width: '33%' }}>
                <label className={style.dateLabel}>
                  Planned End Date<span style={{ color: "red" }}>*</span>
                </label>
                <div className={style.inputt}>
                  <DatePicker
                    dateFormat="D MMM yy"
                    minDate={new Date()}
                    value={showDate}
                    // startDate={taskPEDate}
                    tileDisabled={({ date }) => date.getDay() === -1}
                    onChange={changeDate}
                    disabledKeyboardNavigation
                    defaultValue={null}
                    customInput={<CustomInput />}
                  />
                </div>
              </div>
            </div>

            <div style={{ display: 'flex' }}>
              <div style={{ width: '33%' }}>
                <label className={style.statusLabel}>
                  Space <br />
                  <div style={{position: "relative"}}>
                    {groupId && <CustomInputCross groupId={groupId} setGroupid={setGroupid} />}
                    <Select
                      styles={customStylesForTaskTrackerSpaces}
                      classNamePrefix={"create_position"}
                      placeholder="Search By Client Name"
                      components={{
                        DropdownIndicator: () => null,
                        IndicatorSeparator: () => null,
                      }}
                      maxMenuHeight={140}
                      onChange={(e) => {
                        setGroupid(e.value);
                        setDisable(false)
                      }}
                      value={groupId ? getGroups?.find(
                        (i) => i.value === groupId
                      ) : null }
                      options={getGroups}
                    />
                  </div>
                </label>
              </div>
              {/* <div style={{ width: '33%' }}>
                <label className={style.priorityLabel12}>
                  Project <br />
                  <select
                    className={style.input}
                    onChange={(e) => setPRoject(e.target.value)}
                    value={project}
                    disabled={disable}
                  >
                    <option hidden>
                      {myTaskDetail?.functionalFieldArea == "Delivery" ||
                        (functionalAreaa == 1 && disablee == 1)
                        ? project
                        : null}
                    </option>
                    {peojectList?.map((val) => {
                      return <option value={val.name}>{val?.name}</option>;
                    })}
                  </select>
                </label>
              </div> */}
              {/* <div style={{ width: '33%' }}>
                <label className={style.statusLabel}>
                  Task Related To <br />
                  <div>
                    <Select
                      styles={customStylesForTaskTracker2}
                      classNamePrefix={"create_position"}
                      placeholder="Search By Client Name"
                      components={{
                        DropdownIndicator: () => null,
                        IndicatorSeparator: () => null,
                      }}
                      maxMenuHeight={140}
                      onChange={(e) => {
                        setRelataedTo(e.label);
                        handleChange1(e.value);
                      }}
                      value={getemployee?.find((i) => i.label === relatedTo)}
                      options={getemployee}
                    />
                  </div>
                </label>
              </div> */}
              <div style={{ width: '33%' }}>
              <label className={style.statusLabel}>
                Status <br />
                <select
                  onChange={(e) => settaskStatus(e.target.value)}
                  value={taskStatus}
                >
                  {statusOptions?.map((option, index) => (
                    <option key={index} value={option}>
                      {option}
                    </option>
                  ))}
                  {employeeId == props.taskData.assignedBy ? (
                    <option value="Closed">Closed</option>
                  ) : null}
                </select>
              </label>
            </div>
            </div>
            {/* <label
                className={style.assignLabel}
                style={{ marginTop: "29px", marginLeft: "4px" }}
                >
                Functional Area <br />
              </label>
              <select
                onChange={(e) => functionalA(e)}
                style={{ width: "100%" }}
              >
                <option hidden>{FunctionalAreaName}</option>
                {functionalArea?.map((val) => {
                  return <option value={val.id}>{val?.name}</option>;
                })}
              </select>
              {functionalAreaa ? (
                <img
                  src={cancel}
                  style={{
                    cursor: "pointer",
                    height: "12px",
                    position: "relative",
                    left: "88%",
                    marginTop: "-17%",
                  }}
                  alt="cancel"
                  onClick={FuncTionalAreaClear}
                />
              ) : null} */}



            {/* </div> */}
            
          </div>
        </div>
        <div style={{display: "flex", justifyContent: "flex-end", gap: "10px"}}>
          <button
            className={style.cancelButton}
            // onClick={() => history.push("/taskTracker/TaskAssignedByMeComponent")}
            onClick={() =>
              history.goBack()
            }
          >
            Cancel
          </button>
          <button className={style.submitButton} onClick={() => updateData()}>
            Update
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditTaskComponent;
