import React, { useState, useEffect } from "react";
import style from "./AddResourcesOffshore.module.css";
import Sidebar from "../../Clients/component/SideBar";
import cookie from "react-cookies";
import CreatableSelect from "react-select/creatable";
import axios from "axios";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useHistory } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import constants from "../../../../Utils/constants";
import DatePicker from "react-datepicker";
import dropdownIcon from "../../../../assets/downIcon.png";
import calendar from "../../../../assets/Grievance portal/dateldpi.png";
import Moment from "moment";
import { Toastify, ToastifyError } from "../../../../App";
import Select from "react-select";
import { customStylesForRMS } from "../../Clients/component/style";
import { api } from "../../CommonApi/api";
import { get } from "../../CommonApi/axiosCall";
import { employeeId } from "../../../../Utils/constants";

const AddResourcesOffshore = () => {
  const CustomInput = React.forwardRef((props, ref) => {
    return (
      <div onClick={props.onClick}>
        <label
          ref={ref}
          style={{ paddingTop: "8px", fontSize: "15px", fontWeight: 500 }}
        >
          {props.value || props.placeholder}
        </label>
        <img
          type="image/svg+xml"
          width="20px"
          style={{ position: "absolute", right: "5%", top: "35%", zIndex: "0" }}
          src={calendar}
          onClick={props.onClick}
          alt=""
        />
      </div>
    );
  });

  const customStyles = {
    control: () => ({
      boxShadow: "0px 3px 9px #00000029",
      borderRadius: "9px",
      background: "transparent 0% 0% no-repeat padding-box",
      backgroundColor: "#f5f6fa",
      minWidth: "inherit",
      margin: "8px 1.5% 0px 0%",
      minHeight: "38px",
      fontWeight: 500,
      // width:"90%",
      outline: "none",
      cursor: "pointer",
    }),
    option: (provided, state) => ({
      ...provided,
      "&:hover": {
        backgroundColor: "rgb(78 206 199)",
      },
      backgroundColor: state.isSelected ? "rgb(78 206 199)" : "white",
    }),
    clearIndicator: (base) => ({
      ...base,
      display: "none",
    }),

    placeholder: () => ({
      display: "none",
    }),
  };
  

  const CustomInput1 = () => {
    return (
      <span>
        <img
          alt="dropdownIcon"
          src={dropdownIcon}
          style={{ position: "absolute", top: "32%", right: "3%" }}
          height="10"
        />
      </span>
    );
  };

  const [projectList, setProjectList] = useState([]);
  const history = useHistory();
  const [disable, setDisable] = useState(false);
  const [state, setState] = useState({
    additionalSkills: [],
    allocationDate: "",
    bandwidth: "",
    deallocationDate: "",
    empId: 0,
    projectId: 0,
    skill: "",
    allocationType: "",
    startDate: 0,
    endDate: 0,
    applicationData: null,
  });
  
  const [additionalSkill, setAdditionalSkill] = useState([]);
  const [allocationId, setAllocationId] = useState("");
  const [skillList, setSkillList] = useState([]);
  const [employeeList, setEmployeeList] = useState([]);
  const [employeeName, setEmployeeName] = useState("");

  const [projectApplication, setProjectApplication] = useState([]);
  const [validate, setValidate] = useState(false);

  employeeList?.sort((a, b) =>
    a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1
  );

  skillList?.sort((a, b) =>
    a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1
  );

  projectList?.sort((a, b) =>
    a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1
  );

  additionalSkill?.sort((a, b) =>
    a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1
  );

  const baseUrl = constants.apiBaseUrlResource;

  const url = constants.apiBaseUrl;

  const getResourceAloocation = (id, end) => {
    axios
      .get(
        baseUrl +
          `/getBandwidthAllocationDetails?id=${id}&startDate=${Moment(
            state?.startDate
          ).format("YYYY-MM-DDTHH:mm:ss")}&endDate=${Moment(
            state?.endDate
          ).format("YYYY-MM-DDTHH:mm:ss")}`,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization: ` ${cookie.load("token")}`,
            employeeId: employeeId,
          },
        }
      )
      .then((res) => {
        setAllocationId(res.data.responseData);
        if (res.data.responseData === "Full") {
          setDisable(true);
        } else {
          setDisable(false);
        }
      })
      .catch((err) => {
        return err;
      });
  };

  const getEmployeeList = () => {
    axios
      .get(baseUrl + "/delivery-employee-list", {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
          employeeId: employeeId,
        },
      })
      .then((res) => {
        setEmployeeList(res?.data?.responseData);
      })
      .catch((err) => {
        return err;
      });
  };

  const ProjectApplication = (id) => {
    let baseURL = api.getApplicationNameByProjectId;
    if (id) {
      baseURL += `?projectId=${id}`;
    }

    get(baseURL)
      .then((res) => {
        setProjectApplication(res.data.responseData);
      })
      .catch(() => {});
  };

  const getProjectSkill = () => {
    axios
      .get(baseUrl + "/getProjectforFilter", {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
          employeeId: employeeId,
        },
      })
      .then((res) => {
        setProjectList(res.data.responseData);
      })
      .catch((err) => {});
  };

  const getSkills = () => {
    axios
      .get(baseUrl + "/getAllSkills", {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
          employeeId: employeeId,
        },
      })
      .then((res) => {
        setSkillList(res.data.responseData);
      })
      .catch((err) => {});
  };

  const getAdditionalSkills = () => {
    axios
      .get(baseUrl + "/getAllSkills", {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
          employeeId: employeeId,
        },
      })
      .then((res) => {
        setAdditionalSkill(res.data.responseData);
      })
      .catch((err) => {});
  };
  console.log("state", state);  

  useEffect(() => {
    getProjectSkill();
    getEmployeeList();
    getSkills();
    getAdditionalSkills()
  }, []);

  useEffect(() => {
    const resourceAllocation = state?.endDate
      ? getResourceAloocation(state?.empId)
      : null;
  }, [state?.endDate]);

  const handleRedirect = () => {
    setTimeout(() => {
      history.push("/resourceManagement");
    }, 2000);
  };

  const onSubmit = (e) => {
    e.preventDefault();

    if (
      !state?.startDate ||
      !state.bandwidth ||
      !state?.endDate ||
      !state?.empId ||
      !state.isSoftAllocated ||
      !state.projectId ||
      !state.skill
    ) {
      setValidate(true);
      return;
    }
    setDisable(true);
    var data = {
      additionalSkills: state.additionalSkills.map((skill) => skill.value),
      allocationDate: Moment(state?.startDate).format("YYYY-MM-DD hh:mm"),
      bandwidth: state.bandwidth,
      deallocationDate:Moment(state?.endDate).format("YYYY-MM-DD hh:mm"),
      empId: state?.empId,
      applicationId: state?.applicationData,
      isSoftAllocated:
        state.isSoftAllocated == 1
          ? false
          : state.isSoftAllocated == 2
          ? true
          : state.isSoftAllocated,
      projectId: state.projectId,
      skill: state.skill,
    };
    axios
      .post(baseUrl + "/addResourceOffshore", data, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
          employeeId: employeeId,
        },
      })
      .then((res) => {
        setDisable(false);
        if (res.data.responseStatus.statusCode === 1) {
          ToastifyError(res.data.responseData.message);
          setDisable(false);
        } else {
          Toastify("Data Submitted Successfully");
          setDisable(true);
          handleRedirect();
        }
      })
      .catch(() => {
        setDisable(false);
      });
  };

  // handle react Select
  const handleChange = (field, value) => {
  console.log("field:", field, "value:", value);
  setState((prevState) => ({
    ...prevState,
    [field]: value, 
  }));
};

  const allocation = [
    { name: "25%", id: "OneFourth" },
    { name: "50%", id: "Half" },
    { name: "75%", id: "ThreeFourth" },
    { name: "100%", id: "Full" },
  ];

  const getskillList = skillList?.map((i) => ({
    value: i.id,
    label: i.name,
  }));

  const getAdditionalSkillsList = additionalSkill?.map((i) => ({
    value: i.id,
    label: i.name,
  }));

  const getemployeeList = employeeList?.map((i) => ({
    value: i.id,
    // label: i.name,
    label: `${i.name} (${i.code})`,
  }));

  const getprojectApplication = projectApplication?.map((i) => ({
    value: i.applicationId,
    label: i.applicationName,
  }));

  const getprojectList = projectList?.map((i) => ({
    value: i.id,
    label: i.name,
  }));

  const getallocation = allocation?.map((i) => ({
    value: i.id,
    label: i.name,
  }));

  const [allocationType] = useState([
    {
      value: 1,
      label: "Allocated",
    },
    { value: 2, label: "Allocated (Soft)" },
  ]);
  return (
    <>
      <div className={style.main_client_body}>
        <Sidebar />

        <form className={style.table_div} onSubmit={onSubmit}>
          <div className={style.table_div_head}>
            <div className={style.breadscrumb}></div>
            <div style={{ marginTop: "22px", fontWeight: "700" }}>
              {console.log(employeeList)}
              Resource Allocation Offshore
            </div>
            <div className={style.form_div}>
              {allocationId && allocationId !== "Full" && employeeName ? (
                <p
                  style={{
                    color: "red",
                    paddingBottom: "10px",
                    paddingLeft: "13px",
                  }}
                >
                  You can allocate {employeeName}{" "}
                  {allocationId === "OneFourth"
                    ? `${100 - 25}% only`
                    : allocationId === "Half"
                    ? `${100 - 50}% only`
                    : allocationId === "ThreeFourth"
                    ? `${100 - 75}% only`
                    : allocationId === "Zero"
                    ? `${100}%`
                    : null}
                </p>
              ) : allocationId && allocationId === "Full" && employeeName ? (
                <p
                  style={{
                    color: "red",
                    paddingLeft: "13px",
                    paddingBottom: "10px",
                  }}
                >
                  {" "}
                  You can't allocate {employeeName} because bandwidth is not
                  available{" "}
                </p>
              ) : null}

              <div className={style.Cu_row1}>
                <div className={style.cu_col1}>
                  <p className={style.cu_label}>
                    Employee Name <span style={{ color: "red" }}>*</span>
                  </p>

                  <Select
                    styles={customStylesForRMS}
                    classNamePrefix={"create_position"}
                    placeholder="Search By Client Name"
                    components={{
                      DropdownIndicator: () => CustomInput1(),
                      IndicatorSeparator: () => null,
                    }}
                    onChange={(e) => {
                      handleChange(`empId`, e?.value);
                      setEmployeeName(e.label);
                    }}
                    options={getemployeeList}
                  />
                  <p
                    className={
                      validate && !state?.empId
                        ? style.errorText
                        : style.errorTextHidden
                    }
                  >
                    Please Select Employee Name
                  </p>
                </div>
                <div className={style.cu_col2}>
                  <p className={style.cu_label}>
                    Project<span style={{ color: "red" }}>*</span>
                  </p>
                  <Select
                    styles={customStylesForRMS}
                    classNamePrefix={"create_position"}
                    placeholder="Search By Client Name"
                    components={{
                      DropdownIndicator: () => CustomInput1(),
                      IndicatorSeparator: () => null,
                    }}
                    onChange={(e) => {
                      handleChange(`projectId`, e?.value);
                      ProjectApplication(e.value);
                    }}
                    options={getprojectList}
                  />
                  <p
                    className={
                      validate && !state?.projectId
                        ? style.errorText
                        : style.errorTextHidden
                    }
                  >
                    Please Select Project
                  </p>
                </div>
                <div className={style.cu_col3}>
                  <p className={style.cu_label} style={{ paddingLeft: "5px" }}>
                    Application
                  </p>

                  <Select
                    styles={customStylesForRMS}
                    classNamePrefix={"create_position"}
                    placeholder="Search By Client Name"
                    components={{
                      DropdownIndicator: () => CustomInput1(),
                      IndicatorSeparator: () => null,
                    }}
                    onChange={(e) => handleChange(`applicationData`, e?.value)}
                    options={getprojectApplication}
                  />
                </div>
              </div>
              <div className={style.Cu_row1}>
                <div className={style.cu_col1}>
                  <p className={style.cu_label}>
                    Technology<span style={{ color: "red" }}>*</span>
                  </p>

                  <Select
                    styles={customStylesForRMS}
                    classNamePrefix={"create_position"}
                    placeholder="Search By Client Name"
                    components={{
                      DropdownIndicator: () => CustomInput1(),
                      IndicatorSeparator: () => null,
                    }}
                    onChange={(e) => handleChange(`skill`, e?.value)}
                    options={getskillList}
                  />
                  <p
                    className={
                      validate && !state?.skill
                        ? style.errorText
                        : style.errorTextHidden
                    }
                  >
                    Please Select Technology
                  </p>
                </div>
                <div className={style.cu_col2}>
                  <p className={style.cu_label}>
                    % Allocation<span style={{ color: "red" }}>*</span>
                  </p>

                  <Select
                    styles={customStylesForRMS}
                    classNamePrefix={"create_position"}
                    placeholder="Search By Client Name"
                    components={{
                      DropdownIndicator: () => CustomInput1(),
                      IndicatorSeparator: () => null,
                    }}
                    onChange={(e) => handleChange(`bandwidth`, e?.value)}
                    options={getallocation}
                  />
                  <p
                    className={
                      validate && !state?.bandwidth
                        ? style.errorText
                        : style.errorTextHidden
                    }
                  >
                    Please Select % Allocation
                  </p>
                </div>
                <div className={style.cu_col3}>
                  <p className={style.cu_label} style={{ paddingLeft: "5px" }}>
                    Type of Allocation<span style={{ color: "red" }}>*</span>
                  </p>

                  <Select
                    styles={customStylesForRMS}
                    classNamePrefix={"create_position"}
                    placeholder="Search By Client Name"
                    components={{
                      DropdownIndicator: () => CustomInput1(),
                      IndicatorSeparator: () => null,
                    }}
                    onChange={(e) => handleChange(`isSoftAllocated`, e?.value)}
                    options={allocationType}
                  />
                  <p
                    className={
                      validate && !state?.isSoftAllocated
                        ? style.errorText
                        : style.errorTextHidden
                    }
                  >
                    Please Select Type of Allocation
                  </p>
                </div>
              </div>

              <div className={style.Cu_row1}>
                <div className={style.cu_col1}>
                  <p className={style.cu_label}>
                    Project Start Date<span style={{ color: "red" }}>*</span>
                  </p>
                  <div className={style.cu_field2}>
                    <DatePicker
                      dateFormat="dd MMM yy"
                      selected={state?.startDate}
                      startDate={null}
                      onChange={(date) => handleChange(`startDate`, date)}
                      disabledKeyboardNavigation
                      defaultValue={null}
                      customInput={<CustomInput />}
                    />
                  </div>
                  <p
                    className={
                      validate && !state?.startDate
                        ? style.errorText
                        : style.errorTextHidden
                    }
                  >
                    Please Select Start Date
                  </p>
                </div>
                <div className={style.cu_col2}>
                  <p className={style.cu_label}>
                    Project End Date<span style={{ color: "red" }}>*</span>
                  </p>
                  <div className={style.cu_field2}>
                    <DatePicker
                      dateFormat="dd MMM yy"
                      selected={state?.endDate}
                      startDate={null}
                      minDate={state?.startDate}
                      onChange={(date) => handleChange(`endDate`, date)}
                      disabledKeyboardNavigation
                      defaultValue={null}
                      customInput={<CustomInput />}
                    />
                  </div>
                  <p
                    className={
                      validate && !state?.endDate
                        ? style.errorText
                        : style.errorTextHidden
                    }
                  >
                    Please Select End Date
                  </p>
                </div>
                <div className={style.cu_col3}>
                  <p className={style.cu_label}>Additional Core Skill</p>
                  <CreatableSelect
                    menuPlacement="top"
                    isMulti
                    styles={customStyles}
                    classNamePrefix={"create_position"}
                    components={{
                      DropdownIndicator: () => null,
                      IndicatorSeparator: () => null,
                    }}
                    onChange={(selectedOption) => {handleChange(`additionalSkills`, selectedOption)}}
                    isValidNewOption={() => false} 
                    options={getAdditionalSkillsList}
                    // formatCreateLabel={(userInput) => `+ Add "${userInput}"`}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className={style.btn_div}>
            <button
              onClick={onSubmit}
              className={style.submitbtn}
              disabled={disable}
            >
              Submit
            </button>
          </div>
        </form>
        <ToastContainer />
      </div>
    </>
  );
};

export default AddResourcesOffshore;
