import React from "react";
import Modal from "react-responsive-modal";
import style from "./Filter.module.css";
import searchIcon from "../../../assets/Icon-search.png";
import { AiOutlineClose } from "react-icons/ai";
import { customSearchEF } from "../utils/style";
import { useQuery } from "react-query";
import Select from "react-select";
import "./style.css";
import { department } from "../../../Utils/axios/InductionApi";
import { getStatus } from "../../../Utils/axios/efAPI";
import MonthYearPicker from "./MonthYearPicker";
function Filter({
  setShowFilter,
  showFilter,
  firstCard,
  secondCard,
  thirdCard,
  fourthCard,
  filterData,
  setFilterData,
  filterUpdateFuc,
  filterClearAllFuc,
  filterApplied,
}) {
  const { data: dept } = useQuery(["department"], department);
  const DeptOption = dept?.map((e) => ({
    value: e.id,
    label: e.name,
  }));
  const { data: Status } = useQuery(["getStatus"], getStatus);
  const StatusOption = Status?.map((e) => ({
    value: e.statusId,
    label: e.value,
  }));
  const fnfStatusOption = [
    { value: 4, label: "Done" },
    { value: 2, label: "In-Progress" },
    { value: 3, label: "Pending" },
    { value: 1, label: "Not Applicable" },
  ]

  function handleEFStatusChange(e) {
    setFilterData((preData) => ({
      ...preData,
      statusId: Number(e.target.value),
    }));
  }
  function handleFnFStatusChange(e) {
    setFilterData((preData) => ({
      ...preData,
      fnfStatusId: Number(e.target.value),
    }));
  }
  const handleDepartment = (e) => {
    setFilterData((preData) => ({
      ...preData,
      deptId: e.value,
      deptName: e.label,
    }));
  };
  const handleMonthChange = (selectedDate) => {
    if (selectedDate) {
      setFilterData((prevFilterData) => ({
        ...prevFilterData,
        month: selectedDate?.getMonth() + 1,
        year: selectedDate?.getFullYear(),
      }));
    } else {
      setFilterData((prevFilterData) => ({
        ...prevFilterData,
        month: "",
        year: "",
      }));
    }
  };

  return (
    <Modal
      open={showFilter}
      center
      onClose={() => {setShowFilter(false)
      if(!filterApplied){
        filterClearAllFuc()
      }      
    }
      }
      showCloseIcon={false}
      classNames={{
        modal: "reactmodal",
      }}
    >
      <div className={style.filterModal}>
        <div className={style.filterCards}>
          {firstCard && (
            <div className={style.firstCard}>
              <div className={style.filte_heading}>Department</div>
              <div
                style={{
                  width: "93.5%",
                  margin: ".5rem 0.5rem",
                  position: "relative",
                }}
              >
                {filterData?.deptId === "" ? (
                  <span className={style.cp_serchIcon}>
                    <img src={searchIcon} style={{ marginLeft: "8px" }} />
                  </span>
                ) : (
                  <span
                    className={style.cp_serchIcon}
                    style={{
                      marginTop: "13px",
                      position: "absolute",
                      top: "-10px",
                      right: "4px",
                      zIndex: "1",
                    }}
                    onClick={() => {
                      setFilterData({
                        ...filterData,
                        deptId: "",
                      });
                      setFilterData({ ...filterData, deptId: "" });
                    }}
                    onKeyDown={() => {
                      setFilterData({
                        ...filterData,
                        deptId: "",
                      });
                      setFilterData({ ...filterData, deptId: "" });
                    }}
                  >
                    <AiOutlineClose />
                  </span>
                )}
                <Select
                  // menuPosition="fixed"
                  styles={customSearchEF}
                  classNamePrefix={"create_position"}
                  value={
                    filterData?.deptId
                      ? {
                          value: filterData?.deptId,
                          label: filterData?.deptName,
                        }
                      : null
                  }
                  components={{
                    DropdownIndicator: () => null,
                    IndicatorSeparator: () => null,
                  }}
                  menuPlacement={"auto"}
                  onChange={(e) => handleDepartment(e)}
                  options={DeptOption}
                />
              </div>
            </div>
          )}
          {secondCard && (
            <div className={style.firstCard}>
              <div className={style.filte_heading}>Exit Status</div>

              <div
                style={{
                  width: "93.5%",
                  margin: ".5rem 0.5rem",
                  position: "relative",
                }}
              >
                {StatusOption?.map((status) => {
                  if (status.value === 3) return;
                  return (
                    <div key={status.value}>
                      <input
                        type="radio"
                        id={status.label}
                        name="status"
                        value={status.value}
                        checked={filterData.statusId === status.value}
                        onChange={handleEFStatusChange}
                      />
                      <label htmlFor={status.label}>{status.label}</label>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
          {fourthCard && (
            <div className={style.firstCard}>
              <div className={style.filte_heading}>FnF Status</div>

              <div
                style={{
                  width: "93.5%",
                  margin: ".5rem 0.5rem",
                  position: "relative",
                }}
              >
                {fnfStatusOption?.map((status) => {
                  return (
                    <div key={status.value}>
                      <input
                        type="radio"
                        id={status.label}
                        name="fnfStatus"
                        value={status.value}
                        checked={filterData.fnfStatusId === status.value}
                        onChange={handleFnFStatusChange}
                      />
                      <label htmlFor={status.label}>{status.label}</label>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
          {thirdCard && (
            <div className={style.firstCard} style={{ paddingLeft: "10px" }}>
              <div className={style.filte_heading}>{thirdCard}</div>
              <MonthYearPicker
                monthSelected={filterData?.month}
                yearSelected={filterData?.year}
                onChange={(date) => handleMonthChange(date)}
              />
            </div>
          )}
        </div>
      </div>
      <div className={style.filterBtn}>
        <button
          className={style.cancelBtn}
          onClick={() => {
            filterClearAllFuc();
          }}
        >
          Clear All
        </button>
        <button className={style.createBtn} onClick={() => filterUpdateFuc()}>
          Apply
        </button>
      </div>
    </Modal>
  );
}

export default Filter;
