import React, { useEffect, useState } from "react";
import style from "./UsersList.module.css";
import SideBar from "../../sidebar/SideBar";
import { Link, useHistory, useLocation } from "react-router-dom";
import FilterListIcon from "@mui/icons-material/FilterList";
import ExcelFileDownload from "../ExcelFileDownload/ExcelFileDownload";
import {
  getAllUsersWithFilter,
  getAllTechnologyforFilter,
  getAllType,
  getSalaryRange,
  getAllFunctionalArea,
  getAllLeads,
  // getClientList,
  getPartnerListForFilter,
  getClientListForFilter,
  getEmployeeHistoryDetails,
  getAllUsersName,
} from "../../../../Utils/axios/umsApi";
import moment from "moment";
import searchIcon from "../../../../assets/Icon-search.png";
import { MdHistory } from "react-icons/md";
import Table from "react-bootstrap/Table";
import Popup from "reactjs-popup";
import { AiOutlineClose } from "react-icons/ai";
import loaderImg from "../../../../assets/umsLoader.gif";
// import Pagination from "../commonComponent/Pagination";
import Pagination from "../../../Projects_and_Resource_Master/CommonComponent/Pagination";
import FilterModal from "../../filter/FilterModal";
import { useQuery } from "react-query";
import UmsCustomTooltip from "../../umsCustomTooltips/UmsCustomTooltip";
import ShowStatus from "../../showStatus/ShowStatus";
import { path } from "../../../../routes/PathNames";
import useValidationAccess from "../../../custumHooks/useValidationAccess";
import { umsAccessCode } from "../../../../Utils/constants";
import UMSCheckBox from "../commonComponent/UMSCheckBox";
import FilterSearchSelect from "./../../../atsDashboard/commonComponent/filterSearch/FilterSearchSelect";
// import { api } from "../../../Projects_and_Resource_Master/CommonApi/api";
// import { get } from "../../../Projects_and_Resource_Master/CommonApi/axiosCall";
function UsersList() {
  const location = useLocation();
  const { checkValidateAccess } = useValidationAccess();

  const [currentPage, setCurrentPage] = useState(
    location?.state?.currentPage ? location?.state?.currentPage : 1
  );
  const [pageCount, setpageCount] = useState(0);
  const [userList, setUserList] = useState([]);
  const [totalCount, settotaluserCount] = useState();
  const [isLoaded, setIsLoaded] = useState(false);
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [allCount, setAllCount] = useState(0);
  const [clientListing, setClientListing] = useState([]);
  const [showEmpHistoryModal, setShowEmpHistoryModal] = useState(false);
  const [historyModalData, sethistoryModalData] = useState("");
  const [date, setDate] = useState();

  const [filterData, setFilterData] = useState(
    location?.state?.filterData
      ? location?.state?.filterData
      : {
        status: [],
        allocated: [],
        employeeType: [],
        salaryRange: [],
        functionalArea: [],
        leadId: [],
        clientId: [],
        experienceRange: [],
        location: [],
        pfApplicable: "",
        month: null,
        year: null,
      }
  );
  const [filter, setFilter] = useState(
    location?.state?.filter
      ? location?.state?.filter
      : {
        employeeCode: "",
        nameEmail: "",
        status: [],
        technology: [],
        employeeType: [],
        salaryRange: [],
        functionalArea: [],
        leadId: [],
        clientId: [],
        experienceRange: [],
        location: [],
        pfApplicable: "",
        month: null,
        year: null,
        activeStatus: true,
      }
  );
  const [isDisabled, setIsDisabled] = useState(false);
  const limit = 20;

  const checkForAllFilter = () => {
    return (
      filter?.status?.includes(2) &&
      filter?.status?.includes(3) &&
      filter?.status?.includes(4) &&
      filter?.status?.includes(5) &&
      filter?.status?.includes(6) &&
      filter?.status?.includes(7)
    );
  };

  const modifyFilter = () => {
    const newFilter = {};
    newFilter.employeeCode = filter?.employeeCode;
    newFilter.nameEmail = filter?.nameEmail;
    newFilter.isStatus =
      filter?.status?.length > 0 && !filter?.status?.includes(1);
    newFilter.resignStatus = filter?.status?.includes(2)
      ? true
      : checkForAllFilter()
        ? true
        : null;
    newFilter.exitPlan = filter?.status?.includes(3)
      ? true
      : checkForAllFilter()
        ? true
        : null;
    newFilter.retainedOnGoing = filter?.status?.includes(4)
      ? true
      : checkForAllFilter()
        ? true
        : null;
    newFilter.retainedPeriodOver = filter?.status?.includes(5)
      ? true
      : checkForAllFilter()
        ? true
        : null;
    newFilter.bondOngoing = filter?.status?.includes(6)
      ? true
      : checkForAllFilter()
        ? true
        : null;
    newFilter.bondPeriodOver = filter?.status?.includes(7)
      ? true
      : checkForAllFilter()
        ? true
        : null;
    newFilter.technology = [...filter?.technology];
    newFilter.employeeType = [...filter?.employeeType];
    newFilter.salaryRange = [...filter?.salaryRange];
    newFilter.functionalArea = [...filter?.functionalArea];
    newFilter.leadId = [...filter?.leadId];
    newFilter.clientId = [...filter?.clientId];
    newFilter.experienceRange = [...filter?.experienceRange];
    newFilter.location = [...filter?.location];
    newFilter.pfApplicable = filter?.pfApplicable;
    newFilter.month = filter?.month;
    newFilter.year = filter?.year;
    newFilter.activeStatus = filter?.activeStatus;
    return newFilter;
  };

  const { data, isLoading } = useQuery(
    ["getAllUsersWithFilter", { filter, currentPage }],
    () => {
      const newfilter = modifyFilter();
      return getAllUsersWithFilter(limit, currentPage, newfilter);
    },
    {
      refetchOnWindowFocus: false,
    }
  );
  useEffect(() => {
    setUserList(data?.employeeResponseModels);
    settotaluserCount(data?.allCount);
    setIsLoaded(false);
    setpageCount();
    setpageCount(~~(data?.allCount / 20 + 1));
    setAllCount(data?.allCount);
  }, [data]);

  const { data: getCoreSkillData } = useQuery(
    ["getAllTechnologyforFilter", isDisabled],
    () => getAllTechnologyforFilter(isDisabled),
    {
      refetchOnWindowFocus: false,
    }
  );
  const getTechnologyList = getCoreSkillData?.map((e) => ({
    value: e.id,
    label: e.name,
  }));
  getTechnologyList?.unshift({ value: 0, label: "All" });

  const { data: getAllTypeData } = useQuery(["getAllType"], getAllType, {
    refetchOnWindowFocus: false,
  });
  const getAllTypeOption = getAllTypeData?.map((e) => ({
    value: e.id,
    label: e.name,
  }));
  getAllTypeOption?.unshift({ value: 0, label: "All" });

  const { data: getAllLeadsData } = useQuery(["getAllLeads"], getAllLeads, {
    refetchOnWindowFocus: false,
  });
  const getAllLeadsOption = getAllLeadsData?.map((e) => ({
    value: e.id,
    label: e.label,
  }));
  getAllLeadsOption?.unshift({ value: 0, label: "All" });

  const { data: getAllPartnerData } = useQuery(
    ["getPartnerListForFilter"],
    getPartnerListForFilter,
    {
      refetchOnWindowFocus: false,
    }
  );
  const getAllPartnerOption = getAllPartnerData?.map((e) => ({
    value: e.id,
    label: e.name,
  }));

  const { data: getSalaryRangeData } = useQuery(
    ["getSalaryRange"],
    getSalaryRange,
    {
      refetchOnWindowFocus: false,
    }
  );
  const getSalaryRangeOption = getSalaryRangeData?.map((e) => ({
    value: e.id,
    label: e.name,
  }));
  getSalaryRangeOption?.unshift({ value: 0, label: "All" });

  const { data: getAllFunctionalData } = useQuery(
    ["getAllFunctionalArea"],
    getAllFunctionalArea,
    {
      refetchOnWindowFocus: false,
    }
  );
  const getFunctionalOption = getAllFunctionalData?.map((e) => ({
    value: e.id,
    label: e.name,
  }));
  getFunctionalOption?.unshift({ value: 0, label: "All" });

  const { data: getClientData } = useQuery(
    ["getClientListForFilter", isDisabled],
    () => getClientListForFilter(isDisabled),
    {
      refetchOnWindowFocus: false,
    }
  );
  // const getClientListing = () => {
  //   let url = api.getOnsiteClientList;

  //   get(url)
  //     .then((res) => {
  //       setClientListing(res.data.responseData);
  //     })
  //     .catch(() => {});
  // };
  // useEffect(()=>{
  //   getClientListing()
  // },[])
  const clientOption = getClientData?.map((item) => {
    return {
      value: item?.id,
      label: item?.name,
    };
  });
  clientOption?.unshift({ value: 0, label: "All" });

  const fetchEmpHistory = async (idData) => {
    try {
      // Assuming you have a function InductionNotes that fetches data
      const response = await getEmployeeHistoryDetails(idData);
      sethistoryModalData(response);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handlePageChange = (page, status) => {
    if (status === "next") {
      setCurrentPage(page + 1);
    } else if (status === "prev") {
      setCurrentPage(page - 1);
    }
  };

  const onSearchHandler = (e, option) => {
    if (e || e === null) {
      switch (option) {
        case "emailName":
          setCurrentPage(1);
          setFilter({ ...filter, nameEmail: e.target.value });
          break;
        case "codeTech":
          setCurrentPage(1);
          setFilter({ ...filter, employeeCode: e.target.value });
          break;
        default:
      }
    } else {
    }
  };
  const filterUpdateFuc = () => {
    setCurrentPage(1);
    setFilter((prev) => ({
      ...prev,
      status: [
        ...(filter?.status?.includes(1) ? filter?.status : filterData?.status),
      ],
      technology: [...filterData?.allocated],
      employeeType: [...filterData?.employeeType],
      salaryRange: [...filterData?.salaryRange],
      functionalArea: [...filterData?.functionalArea],
      leadId: [...filterData?.leadId],
      clientId: [...filterData?.clientId],
      experienceRange: [...filterData?.experienceRange],
      location: [...(filterData?.location?.length ? filterData?.location : [])],
      pfApplicable: filterData?.pfApplicable,
      month: filterData?.month,
      year: filterData?.year,
    }));
    setShowFilterModal(false);
  };
  const filterClearAllFuc = () => {
    if (isDisabled) {
      setFilter((prev) => ({
        ...prev,
        status: [1],
        technology: [],
        employeeType: [],
        salaryRange: [],
        functionalArea: [],
        leadId: [],
        clientId: [],
        experienceRange: [],
        location: [],
        pfApplicable: "",
        month: null,
        year: null,
      }));
    } else {
      setFilter((prev) => ({
        ...prev,
        status: [],
        technology: [],
        employeeType: [],
        salaryRange: [],
        functionalArea: [],
        leadId: [],
        clientId: [],
        experienceRange: [],
        location: [],
        pfApplicable: "",
        month: null,
        year: null,
      }));
    }

    setFilterData((prev) => ({
      ...prev,
      status: [],
      allocated: [],
      employeeType: [],
      functionalArea: [],
      salaryRange: [],
      leadId: [],
      clientId: [],
      experienceRange: [],
      location: [],
      pfApplicable: "",
      month: null,
      year: null,
    }));
    setShowFilterModal(false);
    setDate(null);
  };

  const filterClearAllFucOnExited = (val) => {
    if (val) {
      setFilter((prev) => ({
        ...prev,
        status: [1],
        technology: [],
        employeeType: [],
        salaryRange: [],
        functionalArea: [],
        leadId: [],
        clientId: [],
        experienceRange: [],
        location: [],
        pfApplicable: "",
        month: null,
        year: null,
        activeStatus: false,
      }));
    } else {
      setFilter((prev) => ({
        ...prev,
        status: [],
        technology: [],
        employeeType: [],
        salaryRange: [],
        functionalArea: [],
        leadId: [],
        clientId: [],
        experienceRange: [],
        location: [],
        pfApplicable: "",
        month: null,
        year: null,
        activeStatus: true,
      }));
    }

    setFilterData((prev) => ({
      ...prev,
      status: [],
      allocated: [],
      employeeType: [],
      functionalArea: [],
      salaryRange: [],
      leadId: [],
      clientId: [],
      experienceRange: [],
      location: [],
      pfApplicable: "",
      month: null,
      year: null,
    }));
    setShowFilterModal(false);
    setDate(null);
  };

  const history = useHistory();
  const handleRedirect = (event, id) => {
    const newUrl = `/UserDescription/${id}`;
    if (event.ctrlKey || event.metaKey) {
      // Open in a new tab if Ctrl key or Meta key (Cmd on macOS) is pressed
      window.open(newUrl, "_blank");
    } else {
      // Normal behavior using history.push() for SPAs
      setTimeout(() => {
        history.push(path.ums_userDescription, {
          id,
          filterData,
          currentPage,
          filter,
        });
      }, 0);
    }
  };
  const ReginationData = (value) => {
    return (
      <>
        <div>Resignation Date</div>
        <div>{moment(value).format("DD MMM YY")}</div>
      </>
    );
  };
  const ExitDate = (value) => {
    return (
      <>
        <div>Exit Date</div>
        <div>{moment(value).format("DD MMM YY")}</div>
      </>
    );
  };
  const BondDate = (value) => {
    return (
      <>
        <div>Bond Date</div>
        <div>{moment(value).format("DD MMM YY")}</div>
      </>
    );
  };
  const RetainDate = (value) => {
    return (
      <>
        <div>Retain Date</div>
        <div>{moment(value).format("DD MMM YY")}</div>
      </>
    );
  };
  const ExitDicisionPlanDate = (decision, planned, note) => {
    return (
      <>
        <div>Exit Decision Date</div>
        <div>{moment(decision).format("DD MMM YY")}</div>
        <div>Exit Planned Date</div>
        <div>{moment(planned).format("DD MMM YY")}</div>
        <div>Exit Note</div>
        <div>{note}</div>
      </>
    );
  };

  return (
    <>
      {/* <Header />*/}

      <div className={style.main_client_body}>
        <SideBar />
        <div className={style.main_create}>
          <div className={style.table_div_head}>
            <div
              className={style.breadscrumb}
              style={{ padding: "6px 0px" }}
            ></div>
            <div
              className={style.second_child}
              style={{
                display: "flex",
                justifyContent: "inherit",
                width: "100%",
              }}
            >
              <div
                style={{
                  paddingLeft: "28px",
                  width: "7%",
                  marginTop: "10px",
                  fontSize: "16px",
                  fontWeight: 700,
                }}
              >
                Users
              </div>
              <div>
                {checkValidateAccess(umsAccessCode?.create_user) && (
                  <Link
                    to={path.ums_createUser}
                    style={{ textDecoration: "none" }}
                  >
                    <span className={style.create_btn} title="Create User">
                      <span className={style.plus}>+</span>
                    </span>
                  </Link>
                )}
              </div>
              <div>
                <p className={style.count}>
                  <span
                    className={style.backColor1}
                    style={{ fontSize: "17px", fontWeight: "500" }}
                  >
                    {allCount}
                  </span>
                </p>
              </div>

              <div>
                <p className={style.download}>
                  {checkValidateAccess(umsAccessCode?.download) && (
                    <span
                      className={style.backColor1}
                      style={{
                        fontSize: "2rem",
                        fontWeight: "500",
                        cursor: "pointer",
                      }}
                    >
                      <ExcelFileDownload filter={filter} />
                    </span>
                  )}
                </p>
              </div>

              <div style={{ width: "12%" }}>
                <button
                  className={style.filter_btn}
                  onClick={() => setShowFilterModal(true)}
                  style={
                    filterData?.status?.length > 0 ||
                      filterData?.allocated?.length > 0 ||
                      filterData?.employeeType?.length > 0 ||
                      filterData?.salaryRange?.length > 0 ||
                      filterData?.functionalArea?.length > 0 ||
                      filterData?.leadId?.length > 0 ||
                      filterData?.clientId?.length > 0 ||
                      filterData?.experienceRange?.length > 0
                      ? { background: "#289e98", color: "#fff" }
                      : null
                  }
                >
                  <FilterListIcon style={{ marginRight: "10px" }} />
                  Filter
                </button>
              </div>
              <UMSCheckBox
                item="Exited"
                checked={isDisabled}
                onChange={() => {
                  if (!isDisabled) {
                    setIsDisabled((prev) => !prev);
                    filterClearAllFucOnExited(true);
                    setFilterData((prev) => ({ ...prev, status: [] }));
                  } else {
                    setIsDisabled((prev) => !prev);
                    filterClearAllFucOnExited(false);
                  }
                }}
              />
              <div className={style.s1}>
                {filter?.nameEmail === null || filter?.nameEmail === "" ? (
                  <span className={style.cp_serchIcon}>
                    <img src={searchIcon} alt="searchIcon" />
                  </span>
                ) : (
                  <span
                    className={style.cp_serchIcon}
                    onClick={() => {
                      setFilter((prev) => ({
                        ...prev,
                        nameEmail: "",
                      }));
                      setCurrentPage(1);
                    }}
                  >
                    <AiOutlineClose />
                  </span>
                )}

                <input
                  placeholder=" Name/Email"
                  value={filter?.nameEmail}
                  onChange={(e) => onSearchHandler(e, "emailName")}
                  className={style.coreSkillInput}
                />
              </div>
              <div className={style.s2}>
                {filter?.employeeCode === null ||
                  filter?.employeeCode === "" ? (
                  <span className={style.cp_serchIconCode}>
                    <img src={searchIcon} alt="searchIcon" />
                  </span>
                ) : (
                  <span
                    className={style.cp_serchIconCode}
                    onClick={() => {
                      setFilter((prev) => ({ ...prev, employeeCode: "" }));
                      setCurrentPage(1);
                    }}
                  >
                    <AiOutlineClose />
                  </span>
                )}

                <input
                  placeholder="Code"
                  value={filter?.employeeCode}
                  onChange={(e) => onSearchHandler(e, "codeTech")}
                  className={style.coreSkillInput}
                />
              </div>
              <div style={{ marginRight: "26px" }}>
                <FilterSearchSelect
                  placeholder="Select Partner"
                  value={filter?.partnerId}
                  options={getAllPartnerOption}
                  onChange={(e) => setFilter({ ...filter, partnerId: e.value })}
                  onClose={() => setFilter({ ...filter, partnerId: null })}
                />
              </div>
            </div>
          </div>
          {isLoading ? (
            <div>
              <img
                src={loaderImg}
                alt="loader"
                style={{ marginLeft: "46%", marginTop: "8%", width: "12%" }}
              />
              <p
                style={{
                  fontSize: "24px",
                  fontWeight: "bold",
                  paddingLeft: "38%",
                  position: "absolute",
                  color: "#289e98",
                  marginTop: "-4px",
                  fontStyle: "italic",
                  marginLeft: "33.5%",
                }}
              >
                Loading...
              </p>
            </div>
          ) : (
            <div className={style.UsersList}>
              <div
                className={style.td}
                style={{
                  backgroundColor: "#F0F0F0",
                  position: "sticky",
                  top: 0,
                  zIndex: 8,
                }}
              >
                <p
                  style={{
                    fontWeight: "700",
                    height: "inherit",
                    position: "sticky",
                    top: 0,
                    left: "0px",
                    zInndex: "500",
                    backgroundColor: "#F0F0F0",
                    display: "flex",
                    alignItems: "center",
                    paddingLeft: "5px",
                  }}
                >
                  Type
                </p>
                <p
                  style={{
                    fontWeight: "700",
                    position: "sticky",
                    top: 0,
                    left: "7rem",
                    zInndex: "1000",
                    backgroundColor: "#F0F0F0",
                    height: "inherit",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {/* EmpHistory */}
                </p>
                <p
                  style={{
                    fontWeight: "700",
                    position: "sticky",
                    top: 0,
                    left: "10rem",
                    zInndex: "1000",
                    backgroundColor: "#F0F0F0",
                    height: "inherit",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  Code
                </p>
                <p
                  style={{
                    fontWeight: "700",
                    position: "sticky",
                    top: 0,
                    left: "16rem",
                    zInndex: "800",
                    backgroundColor: "#F0F0F0",
                    height: "inherit",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  Name
                </p>
                <p style={{ fontWeight: "700" }}>
                  Date of <br />
                  Joining
                </p>

                <p style={{ fontWeight: "700" }}>Location</p>

                <p style={{ fontWeight: "700" }}>Experience</p>
                <p style={{ fontWeight: "700" }}>
                  Relevant <br /> Experience
                </p>
                <p style={{ fontWeight: "700" }}>
                  Career Start
                  <br /> Date
                </p>
                <p style={{ fontWeight: "700" }}>Technology</p>
                <p style={{ fontWeight: "700" }}>Additional Skill</p>
                <p style={{ fontWeight: "700" }}>Salary Range</p>
                <p style={{ fontWeight: "700" }}>Designation</p>
                <p style={{ fontWeight: "700" }}>Functional Area</p>
                {/* <p style={{ fontWeight: "700" }}>Salary</p> */}
                <p style={{ fontWeight: "700" }}> Assigned Lead</p>
                <p style={{ fontWeight: "700" }}> Assigned Client</p>
                <p style={{ fontWeight: "700", color: "#666666" }}>Status</p>
              </div>
              {userList?.length > 0 ? (
                userList?.map((val, key) => {
                  return (
                    <div
                      className={style.td}
                      style={{ position: "relative" }}
                      key={key}
                    >
                      <UmsCustomTooltip
                        value={val.type}
                        limit={15}
                        index={`type${key}`}
                        styles={{
                          position: "sticky",
                          top: 0,
                          left: "0px",
                          zInndex: "1000",
                          backgroundColor: "#fff",
                          paddingLeft: "5px",
                        }}
                      />
                      <p
                        style={{
                          position: "sticky",
                          top: 0,
                          left: "7rem",
                          zInndex: "900",
                          backgroundColor: "#fff",
                          height: "100%",
                        }}
                      >
                        {val?.historyApplicable ? (
                          <MdHistory
                            style={{
                              fontSize: "17px",
                              color: "#289e98",
                              cursor: "pointer",
                            }}
                            title="Employee History"
                            onClick={() => {
                              setShowEmpHistoryModal(true);
                              fetchEmpHistory(val.id);
                            }}
                          />
                        ) : (
                          ""
                        )}
                      </p>
                      <p
                        onClick={(e) => handleRedirect(e, val.id)}
                        className={style.backColor}
                        style={{
                          position: "sticky",
                          top: 0,
                          left: "10rem",
                          zInndex: "900",
                          backgroundColor: "#fff",
                        }}
                      >
                        {val.code}
                      </p>
                      <UmsCustomTooltip
                        value={val.name}
                        limit={24}
                        index={`name${key}`}
                        styles={{
                          position: "sticky",
                          top: 0,
                          left: "16rem",
                          zInndex: "800",
                          backgroundColor: "#fff",
                        }}
                      />
                      <p>{moment(val.dateOfJoining).format("DD MMM YY")}</p>
                      <p>{val?.location}</p>
                      <p>
                        {~~(val.totalExperience / 12)
                          ? ~~(val.totalExperience / 12)
                          : 0}{" "}
                        Y -{" "}
                        {val.totalExperience % 12
                          ? val.totalExperience % 12
                          : 0}{" "}
                        M
                      </p>
                      <p>
                        {~~(val.relevantExperience / 12)
                          ? ~~(val.relevantExperience / 12)
                          : 0}{" "}
                        Y -{" "}
                        {val.relevantExperience % 12
                          ? val.relevantExperience % 12
                          : 0}{" "}
                        M
                      </p>
                      <p>{moment(val.careerStartDate).format("DD MMM YY")}</p>
                      <UmsCustomTooltip
                        value={val?.technology}
                        limit={15}
                        index={`tecnology${key}`}
                      // stylestootip={{ right: "2%" }}
                      />
                      <UmsCustomTooltip
                        value={val?.additionalSkill
                          ?.map((e) => e?.additionalSkillName)
                          .join(", ")}
                        limit={20}
                        index={`tadd${key}`}
                      // stylestootip={{ left: "10%" }}
                      />
                      <p>{val?.salaryRangeName}</p>
                      <UmsCustomTooltip
                        value={val?.employeeDesignation}
                        limit={24}
                        index={`desi${key}`}
                      // stylestootip={{ right: "2%" }}
                      />
                      <UmsCustomTooltip
                        value={val?.functionalAreaName}
                        limit={30}
                        index={`dFsi${key}`}
                      // stylestootip={{ right: "2%" }}
                      />
                      {/* <p>{val?.salary}</p> */}
                      <UmsCustomTooltip
                        value={val?.assignedLead}
                        limit={20}
                        index={`aFsi${key}`}
                      // stylestootip={{ right: "2%" }}
                      />
                      <UmsCustomTooltip
                        value={val?.clientName}
                        limit={23}
                        index={`clientName${key}`}
                      // stylestootip={{ right: "2%" }}
                      />
                      <p>
                        {val.hasOwnProperty("exitDecisionDate") &&
                          val.hasOwnProperty("exitPlanDate") &&
                          val?.exitPlanStatus && (
                            <ShowStatus
                              index={`EP${key}`}
                              component={ExitDicisionPlanDate(
                                val?.exitDecisionDate,
                                val?.exitPlanDate,
                                val?.exitComment
                              )}
                              circleColor={{ border: "2px solid #289e98" }}
                            >
                              EP
                            </ShowStatus>
                          )}
                        {val.hasOwnProperty("resignationDate") &&
                          val?.resignStatus && (
                            <ShowStatus
                              index={`R${key}`}
                              component={ReginationData(val?.resignationDate)}
                              circleColor={{ border: "2px solid #fb6c65" }}
                            >
                              R
                            </ShowStatus>
                          )}
                        {!val?.active && (
                          <ShowStatus
                            index={`E${key}`}
                            component={ExitDate(val?.lastWorkingDayDate)}
                            circleColor={{ border: "2px solid #c10614" }}
                          >
                            E
                          </ShowStatus>
                        )}
                        {val.bond && (
                          <ShowStatus
                            index={`B${key}`}
                            component={BondDate(val?.bondDate)}
                            circleColor={{ border: "2px solid orange" }}
                          >
                            B
                          </ShowStatus>
                        )}
                        {val.retain && (
                          <ShowStatus
                            index={`RT${key}`}
                            component={RetainDate(val?.retainDate)}
                            circleColor={{ border: "2px solid #ADD8E6" }}
                          >
                            RT
                          </ShowStatus>
                        )}
                      </p>
                    </div>
                  );
                })
              ) : (
                <div>
                  <p
                    style={{
                      fontSize: "1rem",
                      paddingLeft: "45%",
                      color: "#289e98",
                      paddingTop: "1rem",
                    }}
                  >
                    No Users
                  </p>
                </div>
              )}
            </div>
          )}
          {!isLoading && totalCount > limit && pageCount > 0 && !isLoaded && (
            <div
              className={style.paginationmobileContainer}
              style={{ marginRight: "29px" }}
            >
              <Pagination
                pagesCount={pageCount}
                pageSize={20}
                onPageChange={handlePageChange}
                currentPage={currentPage}
                color="#289e98"
                color2="white"
              />
            </div>
          )}
          <div>
            <Popup
              open={showEmpHistoryModal}
              closeOnDocumentClick={false}
            // onClose={props.closeModal}
            >
              <div className={style.modal_emp_history}>
                <div className={style.heading}>Employee History</div>
                <div className={style.modal_table_container}>
                  {historyModalData?.empHistoryCount === 0 ? (
                    <p className={style.no_notes}>No Notes</p>
                  ) : (
                    <Table
                      className={style.modal_table}
                      borderless
                      hover
                      responsive
                    >
                      <thead>
                        <tr>
                          <th style={{ width: "8rem" }}>Date Modified</th>
                          <th style={{ width: "10rem" }}>Change By</th>
                          <th style={{ width: "10rem" }}>Fields</th>
                          <th style={{ width: "10rem" }}>Previous Info</th>
                          <th style={{ width: "10rem" }}>Current Info</th>
                        </tr>
                      </thead>
                      <tbody>
                        {false ? (
                          <tr>
                            <td colSpan={3} style={{ textAlign: "center" }}>
                              No Data Found
                            </td>
                          </tr>
                        ) : (
                          historyModalData?.empHistoryData?.map((item) => (
                            <tr>
                              <td style={{ width: "8rem" }}>
                                {moment(item.modifiedOn).format("DD MMM YY")}
                              </td>
                              <td style={{ width: "10rem" }}>
                                {item.changeByEmpName}
                              </td>
                              <td>{item.fields}</td>
                              <td style={{ width: "10rem" }}>
                                {item.prevInfo}
                              </td>
                              <td>{item.currentInfo}</td>
                            </tr>
                          ))
                        )}
                      </tbody>
                    </Table>
                  )}
                </div>
                <div className={style.modal_btn_container}>
                  <button
                    className={style.modal_btn}
                    onClick={() => {
                      setShowEmpHistoryModal(false);
                      // setNotesModalData({})
                    }}
                  >
                    Done
                  </button>
                </div>
              </div>
            </Popup>
          </div>
          <FilterModal
            showFilter={showFilterModal}
            setShowFilter={setShowFilterModal}
            firstCard="Status"
            firstCardlist={[
              "All",
              "Resigned",
              "Exit Planned",
              "Retained Ongoing",
              "Retained Period-Over",
              "Bond Ongoing",
              "Bond Period-Over",
            ]}
            filterUpdateFuc={filterUpdateFuc}
            filterClearAllFuc={filterClearAllFuc}
            setFilterData={setFilterData}
            filterData={filterData}
            secondCard="Technology"
            secondCardlist={getTechnologyList}
            thirdCard="Employee Type"
            thirdCardlist={getAllTypeOption}
            forthCard="Salary Range"
            forthCardlist={getSalaryRangeOption}
            fiveCard="Functional Area"
            fiveCardlist={getFunctionalOption}
            SixCard="Lead"
            SixCardlist={getAllLeadsOption}
            sevenCard="Client"
            sevenCardlist={clientOption}
            Eightcard="Experience Range"
            Eightcardlist={["0-1", "1-2", "2-5", "5+"]}
            Ninecard="PF Applicable"
            Ninecardlist={["yes", "No"]}
            TenthCard="Month & Year"
            EleventhCard="Location"
            EleventhCardList={["Onsite", "Offshore"]}
            disabled={isDisabled}
            date={date}
            setDate={setDate}
          />
        </div>
      </div>
    </>
  );
}

export default UsersList;
